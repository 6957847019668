import moment from 'moment';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const isSmallOrExtraSmallScreen = (width) => {
  if (width === "xs" || width === "sm") {
    return true;
  }
  return false;
}

export const isSmallScreen = (width) => {
  if (width === "xs") {
    return true;
  }
  return false;
}

export function parseDate(dateString, dateFormat) {
  if (!dateFormat) {
    dateFormat = "DD/MM/YYYY";
  }
  // TODO: use date-fns
  return moment(dateString).format(dateFormat);
}

export const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"
];

export const getLast12Months = () => {
  function padMonth(month) {
    if (month < 10) {
      const stringMonth = "0" + String(month);
      return stringMonth;
    } else {
      return month;
    }
  }

  let dates = [];
  var date = moment();
  for (var i = 0; i < 12; i++) {
    let paddedMonth = padMonth(date.month() + 1);
    let label = monthNames[date.month()] + "/" + date.year();
    let value = date.year() + "-" + paddedMonth + "-01";
    dates.unshift({ label: label, value: value });
    date = date.subtract(1, 'months');
  }
  return dates.reverse();
}

export const obviousPasswords = [
  "password", "qwerty", "abc12345", "123456789", "11111111", "iloveyou", "adobe123",
  "123123123", "sunshine", "1234567890", "photoshop", "sunshine", "password1", "princess",
  "trustno1", "00000000", "welcome1", "abc123456", "1234567891", "Password123", "12345678", "69696969",
  "111111111", "F*******", "Iwantyou", "Babygirl", "87654321", "A*******", "66666666", "12121212",
  "ZZZZZZZZ", "S*******", "H*******", "B*******", "Bigdaddy", "P*******", "23232323", "4444",
  "11223344", "changeme"];


export const formatPrice = (num, minFractionDigits = 2) => {
  let formatter = new Intl.NumberFormat('it-IT', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: 2
  });
  return `$ ${formatter.format(num)}`;
};

export const formatPriceConMoneda = (num, moneda, minFractionDigits = 2,) => {
  let formatter = new Intl.NumberFormat('it-IT', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: 2
  });


  if(moneda==="2222" || moneda ===2222){
    return `U$S ${formatter.format(num)}`;
  }

  return `$ ${formatter.format(num)}`;
};
