import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  movements: null,
  loading: false,
  error: null,
};

const fetchMovementsStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchMovementsSuccess = (state, action) => {
  return updateObject(state, {
    movements: action.movements,
    loading: false,
  });
};

const fetchMovementsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MOVEMENTS_START:
      return fetchMovementsStart(state, action);
    case actionTypes.FETCH_MOVEMENTS_SUCCESS:
      return fetchMovementsSuccess(state, action);
    case actionTypes.FETCH_MOVEMENTS_FAIL:
      return fetchMovementsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
