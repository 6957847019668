import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
// components
import DebitProvidersList from '../../components/Debits/DebitProvidersList';
import Card from '../../components/UI/Card/Card';
import CardTitle from '../../components/UI/Card/CardTitle/CardTitle';
import Spinner from '../../components/UI/Spinner/Spinner';
import CustomDialog from '../../components/UI/Dialog/Dialog';
import AddDebit from './AddDebit/AddDebit';
// styles
import classes from './Debits.module.css';

const Debits = (props) => {

  const [selectedDebit, setDebit] = useState(null);

  useEffect(() => {
    props.onFetchDebitProviders(props.token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDebitProviderClick = (provider) => {
    setDebit(provider);
  }

  const handleAddDebitDialogClose = (success) => {
    setDebit(null);
    props.onResetRequestDebit();
    if (success) {
      props.onFetchDebitProviders(props.token);
    }
  }

  let hayDebitos = true;
  let debits = null;
  if (props.loading) {
    debits =  <Spinner />;
  } else if (props.debitProviders) {
    if(props.debitProviders.length === 0){
      hayDebitos = false;
    }
    debits = <DebitProvidersList
    providers={props.debitProviders}
    onClick={handleDebitProviderClick} />
  }

  let errorMessage = null;
  if (props.error) {
    errorMessage = (
      <p className={classes.ErrorMessage}>
        Se produjo un error al intentar obtener los débitos
      </p>
    );
  }

  if(!hayDebitos){
    errorMessage = (
      <h3>
        No se encontraron débitos disponibles.
      </h3>
    );
  }

  return (
    <Fragment>
      <Card className={classes.Card}>
        <CardTitle title='Débitos' />
        {debits}
        {errorMessage}
      </Card>

      {selectedDebit ?
        <CustomDialog
          open={selectedDebit !== null}
          onClose={() => handleAddDebitDialogClose(false)}>
          <AddDebit
            debitId={selectedDebit.id}
            helpImage={selectedDebit.helpImageUrl}
            fields={selectedDebit.fields}
            onSuccess={() => handleAddDebitDialogClose(true)}
          />
        </CustomDialog>
        : null}
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    debitProviders: state.debits.providers,
    error: state.debits.error,
    loading: state.debits.loading,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDebitProviders: (token) => dispatch(actions.fetchDebitProviders(token)),
    onRequestDebit: (token, debitProviderId, fields) => dispatch(actions.requestDebit(token, debitProviderId, fields)),
    onResetRequestDebit: () => dispatch(actions.resetRequestDebit())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Debits);