import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useClearCache } from "../shared/useClearCache";
// HOC
import withTracker from '../hoc/withTracker/withTracker';
import FullScreenLayout from '../hoc/Layout/FullScreenLayout/FullScreenLayout';
import PrivateSectionLayout from '../hoc/Layout/PrivateSectionLayout/PrivateSectionLayout';

// styles
import './App.module.css';

const App = () => {
  
  useClearCache({ auto: true, basePath: process.env.REACT_APP_BASE_REF });

  return (
    <div>
      <div>
        <Switch>
          <Route exact path="/" component={FullScreenLayout} />
          <Route path="/" component={withTracker(PrivateSectionLayout)} />
        </Switch>
      </div>
    </div>
  );
}

export default App;