import React, { Fragment } from 'react';
import moment from 'moment';
// components
import CreditHistoryListItem from './CreditHistoryListItem/CreditHistoryListItem';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './CreditHistoryList.module.css';

const CreditHistoryList = (props) => {

  const sortedDates = Object.keys(props.credits).sort(function (a, b) {
    return moment(b, "MMMM YYYY") - moment(a, "MMMM YYYY");
  });
  return (
    <Grid container spacing={16}>
      {sortedDates.length > 0 ?
        sortedDates.map((date) => {
          let groupDate = date.charAt(0).toUpperCase() + date.slice(1);
          return (
            <Fragment key={date}>
              <Grid item xs={12}>
                <p className={classes.MonthYearTitle}>{groupDate}</p>
              </Grid>
              {props.credits[date].map((credit, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    key={index}
                  >
                    <CreditHistoryListItem
                      credit={credit}
                      onClick={props.onClick} />
                  </Grid>
                )
              })}
            </Fragment>
          )
        })
        :
        <p className={classes.EmptyState}>No tiene préstamos en su historial</p>
      }
    </Grid>
  )
}

export default CreditHistoryList;