import React from 'react';
import { formatPriceConMoneda,parseDate } from '../../../shared/utility'; 
// material ui core components
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// styles
import classes from './CashListItem.module.css';

const CardListItem = (props) => {
  return (
    <Card className={classes.MyPaymentsListItem}>
      <CardContent>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={4} style={{ textAlign: 'center', fontSize : '23px' }}>
            {
              props.payment.currency === 2222 || props.payment.currency ==="2222" ? "Deuda U$S" : "Deuda $"
            }
          </Grid>
          <Grid item xs={8} style={{ paddingLeft: '10px', position: 'relative'}}>
            <div className={classes.Divider}></div>
            <div style={{ marginLeft: '10px' }}>
              <p className={classes.AmountText}>Total: {formatPriceConMoneda(props.payment.amount, props.payment.currency)}</p>
              <p className={classes.AmountText}>Mínimo: {formatPriceConMoneda(props.payment.minAmount, props.payment.currency)}</p>
              <p className={classes.Vto}>Vencimiento: {parseDate(props.payment.paymentDate)}</p>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CardListItem;