import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
// material ui core components
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
// material ui icons
import DropdownIcon from '@material-ui/icons/LabelImportant';

import classes from './Select.module.css';

const ControlComponent = (props) => (
  <components.Control className={classes.CustomSelect} {...props} />
);

class CustomOption extends React.Component {
  handleClick = event => {
    this.props.selectOption(this.props.data, event);
  };

  render() {
    const { children, isFocused, onFocus } = this.props;
    return (
      <MenuItem
        onFocus={onFocus}
        selected={isFocused}
        onClick={this.handleClick}
        component="div"
        className={classes.CustomOption}
      >
        <Typography variant="inherit">
          {children}
        </Typography>
      </MenuItem>
    );
  }
}


const DropdownIndicator = (props) => {
  return components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <DropdownIcon className={classes.DropdownIcon} />
    </components.DropdownIndicator>
  );
};

const Menu = (props) => {
  return (
    <components.Menu {...props} className={classes.CustomMenu}>
      {props.children}
    </components.Menu>
  );
};

/*const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * 40;
  const childrenOptions = React.Children.toArray(children);
  return (
    <List
      height={maxHeight}
      itemCount={childrenOptions.length}
      itemSize={40}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{childrenOptions[index]}</div>}
    </List>
  );
}*/

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      No hay opciones
    </components.NoOptionsMessage>
  );
};

class CustomSelect extends React.Component {
  render() {
    return (
      <div className={classes.SelectContainer}>
        <Select
          components={{ Control: ControlComponent, Option: CustomOption, DropdownIndicator: DropdownIndicator, Menu, NoOptionsMessage: NoOptionsMessage }}
          options={this.props.options}
          placeholder=""
          value={{ value: this.props.value, label: this.props.label }}
          isSearchable={true}
          isLoading={this.props.loading}
          loadingMessage={() => "Cargando..."}
          //filterOption={null}
          onChange={this.props.onChange} />
      </div>
    );
  }
}

CustomSelect.propTypes = {
  value: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
};

export default CustomSelect;