import React, { useEffect, Fragment } from 'react';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
// components
import Card from '../../components/UI/Card/Card';
import CardTitle from '../../components/UI/Card/CardTitle/CardTitle';
import Spinner from '../../components/UI/Spinner/Spinner';
// material ui components
import { Grid, Paper } from '@material-ui/core';
// styles
import classes from './CreditelShops.module.css';

const CreditelShop = props => {
  useEffect(() => {
    props.onFetchShops(props.token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectedShop = (shopId) => {
    props.history.push(`/tienda-creditel/${shopId}`);
  }

  let hayTiendas = true;
  let shops = null;
  if (props.loading) {
    shops = <Spinner />;
  } else if (props.shops) {
    if(props.shops.length === 0){
      hayTiendas = false;
    }
    shops = props.shops.map(shop => (
      <Grid
        item
        xs={6}
        sm={4}
        md={3}
        key={shop.type}
        onClick={() => onSelectedShop(shop.type)}
        style={{ padding:"5px"}}
      >
        <Paper className={classes.Paper}>
          <img src={shop.img} alt='Tienda Creditel' />
        </Paper>
      </Grid>
    ));
  }

  let errorMessage = null;
  if (props.error) {
    errorMessage = (
      <p className={classes.Error}>
        Se produjo un error al intentar obtener las tiendas de Creditel
      </p>
    );
  }

  if(!hayTiendas){
    errorMessage = (
      <h3>
        No se encontraron tiendas disponibles por el momento.
      </h3>
    );
  }

  return (
    <Fragment>
      <Card className={classes.Card}>
        <CardTitle title='Tienda Creditel' />
        <Grid container spacing={24} className={classes.GridContainer}>
          {shops}
        </Grid>
        {errorMessage}
      </Card>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    shops: state.shop.shops,
    loading: state.shop.loading,
    error: state.shop.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchShops: token => dispatch(actions.fetchShops(token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditelShop);
