import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  shops: null,
	loading: false,
	shop: null,
	loadingShop: false,
  error: null
}

const fetchShopsStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchShopsSuccess = (state, action) => {
  return updateObject(state, {
    shops: action.shops,
    loading: false
  })
}

const fetchShopsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const fetchShopStart = (state, action) => {
	return updateObject(state, { loadingShop: true })
}

const fetchShopSuccess = (state, action) => {
	return updateObject(state, {
		shop: action.shop,
		loadingShop: false
	})
}

const fetchShopFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loadingShop: false
	})
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SHOPS_START:
      return fetchShopsStart(state, action);
    case actionTypes.FETCH_SHOPS_SUCCESS:
      return fetchShopsSuccess(state, action);
    case actionTypes.FETCH_SHOPS_FAIL:
      return fetchShopsFail(state, action);
    case actionTypes.FETCH_SHOP_START:
			return fetchShopStart(state, action);
		case actionTypes.FETCH_SHOP_SUCCESS:
			return fetchShopSuccess(state, action);
		case actionTypes.FETCH_SHOP_FAIL:
			return fetchShopFail(state, action);
		default:
      return state;
  }
};

export default reducer;
