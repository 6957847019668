import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// material ui core components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// styles
import classes from './OutlinedSelect.module.css';

class OutlinedSelect extends Component {

  state = {
    labelWidth: 0,
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  render() {

    let optionMenuItems = this.props.options.map((option) => {
      return (
        <MenuItem
          key={option.value}
          value={option.value
          }>
          {option.label}
        </MenuItem>
      );
    });

    return (
      <FormControl
        variant="outlined"
        className={
          classNames(
            classes.FormControl,
            this.props.elementConfig.noDense ? null : classes.DenseMargin
          )}>

        <InputLabel
          style={{ paddingRight: '40px' }}
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor={this.props.id}
        >
          {this.props.label}
        </InputLabel>
        <Select
          name={this.props.id}
          value={this.props.value}
          onChange={this.props.onChange}
          input={
            <OutlinedInput
              labelWidth={this.state.labelWidth}
              name={this.props.id}
              id={this.props.id}
            />
          }
        >
          {optionMenuItems}
        </Select>
      </FormControl>
    );
  }
}

OutlinedSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.node
  })),
  onChange: PropTypes.func
};

export default OutlinedSelect;