import React, { useEffect, Fragment } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { formatPrice,formatPriceConMoneda } from '../../../shared/utility';
// component
import Card from '../../../components/UI/Card/Card';
import CardTitle from '../../../components/UI/Card/CardTitle/CardTitle';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Button from '../../../components/UI/CustomButtons/Button';
// material ui components
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core';
// material ui icons
import Search from '@material-ui/icons/Search';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { withStyles } from '@material-ui/core/styles';
// styles
import myClasses from './AccountStatement.module.css';
import tableStyle from '../../../assets/jss/components/tableStyle';

const tableHeaders = ['Cierre', 'Monto $','Monto U$S', 'Vencimiento', ''];

const tableRows = [
  {
    title: 'closingDate',
    alignment: 'left'
  },
  {
    title: 'amount',
    alignment: 'left'
  },
  {
    title: 'amountDollars',
    alignment: 'left'
  },
  {
    title: 'expirationDate',
    alignment: 'left'
  }, 
  {
    title: 'link',
    alignment: 'left'
  }
];

const AccountStatement = ({
  accountStatement,
  classes,
  error,
  loading,
  onFetchAccountStatement,
  token,
}) => {
  useEffect(() => {
    onFetchAccountStatement(token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let accountStatementContent = null;

  if (loading) {
    accountStatementContent = (
      <div className={myClasses.SpinnerContainer}>
        <Spinner />
      </div>
    );
  } else if (!accountStatement) {
    accountStatementContent = (
      <p className={myClasses.EmptyState}>
        No se encontraron estados de cuenta
      </p>
    );
  } else if (error) {
    accountStatementContent = (
      <p className={myClasses.EmptyState}>
        Error al intentar obtener el estado de cuenta
      </p>
    );
  } else {
    const rows = accountStatement.map((statement, statementIndex) => {
      const viewLink = `${statement.link}?cycle=${statement.cycle}&token=${token}`;
      const downloadLink = `${statement.link}?cycle=${statement.cycle}&token=${token}&download=1`;

      const getRowData = (row, index) => {
        switch (index) {
          case tableRows.length - 1:
            return (
              <Fragment>
                <Button className={myClasses.Button} href={viewLink} target="_blank" rel="noopener noreferrer" color="primary">
                  <Search style={{ marginRight: '5px', marginBottom: '3px' }} />Ver
                </Button>
                <Button
                  className={myClasses.Button}
                  href={downloadLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                >
                  <CloudDownload style={{ marginRight: '5px', marginBottom: '3px' }} />
                  Descargar
                </Button>
              </Fragment>
            );
          case tableRows.length - 3:
            return formatPriceConMoneda(statement[row],"2222");
          case tableRows.length - 4:
            return formatPrice(statement[row]);
          default:
            return statement[row];
        }
      };

      return (
        <TableRow key={statementIndex}>
          {tableRows.map((row, rowIndex) => (
            <TableCell
              key={row.title}
              className={classNames(classes.tableCell, classes.tableHeadCell, myClasses.TableCell)}
              scope="row"
              component="td"
              style={{ textAlign: row.alignment, paddingRight: '30px' }}
            >
              {getRowData(row.title, rowIndex)}
            </TableCell>
          ))}
        </TableRow>
      );
    });

    accountStatementContent = (
      <Table className={classNames(classes.table, myClasses.Table)}>
        <TableHead>
          <TableRow>
            {tableHeaders.map(header => (
              <TableCell
                key={header}
                className={classNames(classes.tableCell, classes.tableHeadCell)}
                align="right"
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  }

  return (
    <Card className={classNames(myClasses.Card, classes.tableResponsive)}>
      <CardTitle title={'Últimos Estados de Cuenta'} />
      {accountStatementContent}
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    accountStatement: state.accountStatement.accountStatement,
    loading: state.accountStatement.loading,
    error: state.accountStatement.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchAccountStatement: token =>
      dispatch(actions.fetchAccountStatement(token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(tableStyle)(AccountStatement));
