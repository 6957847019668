import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { routeIsEnabledForAccount } from '../../../shared/check-account-routes';
// HOC
import withDocumentTitle from '../../../hoc/withDocumentTitle/withDocumentTitle';
// components
import FixedSideDrawer from '../../../components/Navigation/SideDrawer/FixedSideDrawer';
import Header from '../../../components/Header/Header';
import Logout from '../../../containers/Auth/Logout/Logout';
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
// styles
import layoutStyle from '../../../assets/jss/layout.jsx';
// routes
import routes from '../../../routes/private-section';
import logoCreditel from '../../../assets/images/creditel_logo.svg'

class PrivateSectionLayout extends Component {

  componentDidMount() {
    this.props.onTryAutoSignup();
    //this.props.onSideDrawerClose();
    this.props.onSetNewsCount(localStorage.getItem('notifications'));
  }

  handleLogout = () => {
    this.props.history.push('/logout');
  };

  render() {
    const { classes, ...rest } = this.props;
/*
    const updatedRoutes = routes.map(route => {
      if (route.path === '/novedades') {
        route.pulsing = this.props.unreadNews > 0;
        route.badge = this.props.unreadNews;
        return route;
      }

      return route;
    });*/

    const mergedRoutes = [];
    routes.forEach(route => {
      if (route.routes) {
        route.routes.forEach(childRoute => mergedRoutes.push(childRoute));
      } else {
        mergedRoutes.push(route);
      }
    });

    return (
      <div className={classes.wrapper}>
        {!this.props.forceUpdatePassword && this.props.isAuthenticated ? (
          <Fragment>
            <FixedSideDrawer
              logo={logoCreditel}
              routes={routes}
            />
            <div className={classes.mainPanel} ref="mainPanel">
              <Header onLogout={this.handleLogout} {...rest} />
              <div className={classes.content}>
                <Switch>
                  <Route path="/logout" component={Logout} />
                  {mergedRoutes.map((prop, key) => {
                    if (!routeIsEnabledForAccount(prop)) return null;
                    return (
                      <Route
                        key={key}
                        path={prop.path}
                        component={withDocumentTitle(
                          prop.component,
                          `Mi Creditel - ${prop.sidebarName || prop.documentTitle}`
                        )}
                      />
                    );
                  })}
                </Switch>
              </div>
            </div>
          </Fragment>
        ) : (
          <Redirect to="/" />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    forceUpdatePassword: state.auth.forceUpdatePassword
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onLogout: () => dispatch(actions.logout(true)),
    //onSideDrawerClose: () => dispatch(actions.closeSideDrawer()),
    onSetNewsCount: count => dispatch(actions.setNewsCount(count))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(layoutStyle)(PrivateSectionLayout));
