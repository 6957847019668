import React from 'react';
// material ui core components
import Grid from '@material-ui/core/Grid';
// components
import CashListItem from './PaymentItem/CashListItem';
// styles
import classes from './Payment.module.css';

const PaymentCash= (props) => {
  return (
    <div className={classes.MyPaymentsList}>
      {props.payments.length > 0 ?
        <Grid container spacing={16}>
          {props.payments.map(payment => {
            return (
              <Grid item xs={12} md={4} key={payment.authNumber}>
                  <CashListItem
                    payment={payment}
                    onClick={props.onPaymentClick}
                    />
              </Grid>
            )
          })}
        </Grid>
        :
        <div className={classes.EmptyStateContainer}>
          <img className={classes.EmptyStateImg}  alt="" src={require("../../assets/images/myPayments/historial_pagos.png")} />
          <p className={classes.EmptyStateText}>No tienes pagos de préstamos pendientes</p>
        </div>
      }
    </div>
  )
};

export default PaymentCash;