import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

export const fetchDigitalReceiptsSuccess = (receipts, page) => {
  return {
    type: actionTypes.FETCH_DIGITAL_RECEIPTS_SUCCESS,
    receipts,
    page
  }
};

export const fetchDigitalReceiptsFail = (error) => {
  return {
    type: actionTypes.FETCH_DIGITAL_RECEIPTS_FAIL,
    error: error
  }
};

export const fetchDigitalReceiptsStart = () => {
  return {
    type: actionTypes.FETCH_DIGITAL_RECEIPTS_START
  }
};

export const fetchDigitalReceipts = (token, page) => {
  let body = {
    operation: "getCuotasDigitales",
    paginado: page,
    sessionToken: token,
    channel: "WEB"
  };
  return (dispatch) => {
    dispatch(fetchDigitalReceiptsStart());
    axios.post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchDigitalReceiptsFail(response.data.responseMessage));
        } else {
          dispatch(fetchDigitalReceiptsSuccess(response.data.cuotas, page));
        }
      })
      .catch(error => {
        dispatch(fetchDigitalReceiptsFail(error));
      });
  };
};