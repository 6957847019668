// menu
export const CLOSE_SIDE_DRAWER = 'CLOSE_SIDE_DRAWER';
export const TOGGLE_SIDE_DRAWER = 'TOGGLE_SIDE_DRAWER';
export const TOGGLE_SUB_MENU = 'TOGGLE_SUB_MENU'

// auth
export const AUTH_START = 'AUTH_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const TERMS_CONDITIONS_REQUIRED = 'TERMS_CONDITIONS_REQUIRED';
export const POST_TERMS_CONDITIONS_ACCEPTED_START = 'TERMS_CONDITIONS_ACCEPTED';
export const POST_TERMS_CONDITIONS_ACCEPTED_SUCCESS = 'TERMS_CONDITIONS_ACCEPTED';
export const POST_TERMS_CONDITIONS_ACCEPTED_FAIL = 'TERMS_CONDITIONS_FAIL';
export const CHECK_REGISTRATION_START = 'CHECK_REGISTRATION_START';
export const CHECK_REGISTRATION_SUCCESS = 'CHECK_REGISTRATION_SUCCESS';
export const CHECK_REGISTRATION_FAIL = 'CHECK_REGISTRATION_FAIL';
export const RESET_AUTH_PROPS = 'RESET_AUTH_PROPS';
export const FORCE_UPDATE_PASSWORD = 'FORCE_UPDATE_PASSWORD';
export const UPDATE_PASSWORD_AUTH_START = 'UPDATE_PASSWORD_AUTH_START';
export const UPDATE_PASSWORD_AUTH_SUCCESS = 'UPDATE_PASSWORD_AUTH_SUCCESS';
export const UPDATE_PASSWORD_AUTH_FAIL = 'UPDATE_PASSWORD_AUTH_FAIL';

// account
export const UPDATE_DATA_START = 'UPDATE_DATA_START';
export const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
export const UPDATE_DATA_FAIL = 'UPDATE_DATA_FAIL';
export const UPDATE_PASSWORD_ACCOUNT_START = 'UPDATE_PASSWORD_ACCOUNT_START';
export const UPDATE_PASSWORD_ACCOUNT_SUCCESS = 'UPDATE_PASSWORD_ACCOUNT_SUCCESS';
export const UPDATE_PASSWORD_ACCOUNT_FAIL = 'UPDATE_PASSWORD_ACCOUNT_FAIL';
export const RESET_ACCOUNT_FORMS = 'RESET_ACCOUNT_FORMS';

// movements
export const FETCH_MOVEMENTS_START = 'FETCH_MOVEMENTS_START';
export const FETCH_MOVEMENTS_SUCCESS = 'FETCH_MOVEMENTS_SUCCESS';
export const FETCH_MOVEMENTS_FAIL = 'FETCH_MOVEMENTS_FAIL';

// etickets
export const FETCH_ETICKETS_START = 'FETCH_ETICKETS_START';
export const FETCH_ETICKETS_SUCCESS = 'FETCH_ETICKETS_SUCCESS';
export const FETCH_ETICKETS_FAIL = 'FETCH_ETICKETS_FAIL';

// recharges
export const FETCH_RECHARGES_START = 'FETCH_RECHARGES_START';
export const FETCH_RECHARGES_SUCCESS = 'FETCH_RECHARGES_SUCCESS';
export const FETCH_RECHARGES_FAIL = 'FETCH_RECHARGES_FAIL';

// stm trips
export const FETCH_STM_TRIPS_START = 'FETCH_STM_TRIPS_START';
export const FETCH_STM_TRIPS_SUCCESS = 'FETCH_STM_TRIPS_SUCCESS';
export const FETCH_STM_TRIPS_FAIL = 'FETCH_STM_TRIPS_FAIL';

// prepaid historic
export const FETCH_PREPAID_HISTORIC_START = 'FETCH_PREPAID_HISTORIC_START';
export const FETCH_PREPAID_HISTORIC_SUCCESS = 'FETCH_PREPAID_HISTORIC_SUCCESS';
export const FETCH_PREPAID_HISTORIC_FAIL = 'FETCH_PREPAID_HISTORIC_FAIL';

// digital recepits
export const FETCH_DIGITAL_RECEIPTS_START = 'FETCH_DIGITAL_RECEIPTS_START';
export const FETCH_DIGITAL_RECEIPTS_SUCCESS = 'FETCH_DIGITAL_RECEIPTS_SUCCESS';
export const FETCH_DIGITAL_RECEIPTS_FAIL = 'FETCH_DIGITAL_RECEIPTS_FAIL';

// shop
export const FETCH_SHOPS_START = 'FETCH_SHOPS_START';
export const FETCH_SHOPS_SUCCESS = 'FETCH_SHOPS_SUCCESS';
export const FETCH_SHOPS_FAIL = 'FETCH_SHOPS_FAIL';
export const FETCH_SHOP_START = 'FETCH_SHOP_START';
export const FETCH_SHOP_SUCCESS = 'FETCH_SHOP_SUCCESS';
export const FETCH_SHOP_FAIL = 'FETCH_SHOP_FAIL';

// my payments
export const FETCH_MY_CARD_PAYMENTS_START = 'FETCH_MY_CARD_PAYMENTS_START';
export const FETCH_MY_CARD_PAYMENTS_SUCCESS = 'FETCH_MY_CARD_PAYMENTS_SUCCESS';
export const FETCH_MY_CARD_PAYMENTS_FAIL = 'FETCH_MY_CARD_PAYMENTS_FAIL';
export const FETCH_MY_CASH_PAYMENTS_START = 'FETCH_MY_CASH_PAYMENTS_START';
export const FETCH_MY_CASH_PAYMENTS_SUCCESS = 'FETCH_MY_CASH_PAYMENTS_SUCCESS';
export const FETCH_MY_CASH_PAYMENTS_FAIL = 'FETCH_MY_CASH_PAYMENTS_FAIL';

// additional card
export const UPLOAD_ADDITIONAL_CARD_START = 'UPLOAD_ADDITIONAL_CARD_START';
export const UPLOAD_ADDITIONAL_CARD_SUCCESS = 'UPLOAD_ADDITIONAL_CARD_SUCCESS';
export const UPLOAD_ADDITIONAL_CARD_FAIL = 'UPLOAD_ADDITIONAL_CARD_FAIL';
export const RESET_ADDITIONAL_CARD_FORM = 'RESET_ADDITIONAL_CARD_FORM';

// debits
export const FETCH_DEBIT_PROVIDERS_START = 'FETCH_DEBIT_PROVIDERS_START';
export const FETCH_DEBIT_PROVIDERS_SUCCESS = 'FETCH_DEBIT_PROVIDERS_SUCCESS';
export const FETCH_DEBIT_PROVIDERS_FAIL = 'FETCH_DEBIT_PROVIDERS_FAIL';

export const REQUEST_DEBIT_START = 'REQUEST_DEBIT_START';
export const REQUEST_DEBIT_SUCCESS = 'REQUEST_DEBIT_SUCCESS';
export const REQUEST_DEBIT_FAIL = 'REQUEST_DEBIT_FAIL';

export const RESET_REQUEST_DEBIT = 'RESET_REQUEST_DEBIT';

// credit history
export const FETCH_CREDIT_HISTORY_START = 'FETCH_CREDIT_HISTORY_START';
export const FETCH_CREDIT_HISTORY_SUCCESS = 'FETCH_CREDIT_HISTORY_SUCCESS';
export const FETCH_CREDIT_HISTORY_FAIL = 'FETCH_CREDIT_HISTORY_FAIL';

// news
export const FETCH_NEWS_START = 'FETCH_NEWS_START';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAIL = 'FETCH_NEWS_FAIL';
export const SET_NEWS_COUNT = 'SET_NEWS_COUNT';

// account statement
export const FETCH_ACCOUNT_STATEMENT_START = 'FETCH_ACCOUNT_STATEMENT_START';
export const FETCH_ACCOUNT_STATEMENT_SUCCESS = 'FETCH_ACCOUNT_STATEMENT_SUCCESS';
export const FETCH_ACCOUNT_STATEMENT_FAIL = 'FETCH_ACCOUNT_STATEMENT_FAIL';

// insurances
export const FETCH_INSURANCES_START = 'FETCH_INSURANCES_START';
export const FETCH_INSURANCES_SUCCESS = 'FETCH_INSURANCES_SUCCESS';
export const FETCH_INSURANCES_FAIL = 'FETCH_INSURANCES_FAIL';

// insurance details
export const FETCH_INSURANCE_START = 'FETCH_INSURANCE_START';
export const FETCH_INSURANCE_SUCCESS = 'FETCH_INSURANCE_SUCCESS';
export const FETCH_INSURANCE_FAIL = 'FETCH_INSURANCE_FAIL';

// request insurance
export const REQUEST_INSURANCE_START = 'REQUEST_INSURANCE_START';
export const REQUEST_INSURANCE_SUCCESS = 'REQUEST_INSURANCE_SUCCESS';
export const REQUEST_INSURANCE_FAIL = 'REQUEST_INSURANCE_FAIL';
export const RESET_INSURANCE_REQUESTED = 'RESET_INSURANCE_REQUESTED';

// bank accounts
export const FETCH_BANK_ACCOUNTS_START = 'FETCH_BANK_ACCOUNTS_START';
export const FETCH_BANK_ACCOUNTS_SUCCESS = 'FETCH_BANK_ACCOUNTS_SUCCESS';
export const FETCH_BANK_ACCOUNTS_FAIL = 'FETCH_BANK_ACCOUNTS_FAIL';

// banks
export const FETCH_BANKS_START = 'FETCH_BANKS_START';
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_BANKS_FAIL = 'FETCH_BANKS_FAIL';

// bank requirements
export const FETCH_BANK_REQUIREMENTS_START = 'FETCH_BANK_REQUIREMENTS_START';
export const FETCH_BANK_REQUIREMENTS_SUCCESS = 'FETCH_BANK_REQUIREMENTS_SUCCESS';
export const FETCH_BANK_REQUIREMENTS_FAIL = 'FETCH_BANK_REQUIREMENTS_FAIL';

// request bank account
export const REQUEST_BANK_ACCOUNT_START = 'REQUEST_BANK_ACCOUNT_START';
export const REQUEST_BANK_ACCOUNT_SUCCESS = 'REQUEST_BANK_ACCOUNT_SUCCESS';
export const REQUEST_BANK_ACCOUNT_FAIL = 'REQUEST_BANK_ACCOUNT_FAIL';

export const RESET_BANK_ACCOUNT_REQUESTED = 'RESET_BANK_ACCOUNT_REQUESTED';

// delete bank account
export const DELETE_BANK_ACCOUNT_START = 'DELETE_BANK_ACCOUNT_START';
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export const DELETE_BANK_ACCOUNT_FAIL = 'DELETE_BANK_ACCOUNT_FAIL';

export const RESET_BANK_ACCOUNT_DELETED = 'RESET_BANK_ACCOUNT_DELETED';


// payment
export const FETCH_MY_CARD_PAYMENT_START = 'FETCH_MY_CARD_PAYMENT_START';
export const FETCH_MY_CARD_PAYMENT_SUCCESS = 'FETCH_MY_CARD_PAYMENT_SUCCESS';
export const FETCH_MY_CARD_PAYMENT_FAIL = 'FETCH_MY_CARD_PAYMENT_FAIL';
export const FETCH_MY_CASH_PAYMENT_START = 'FETCH_MY_CASH_PAYMENT_START';
export const FETCH_MY_CASH_PAYMENT_SUCCESS = 'FETCH_MY_CASH_PAYMENT_SUCCESS';
export const FETCH_MY_CASH_PAYMENT_FAIL = 'FETCH_MY_CASH_PAYMENT_FAIL';