import React, { Component } from 'react';
// components
import Input from '../../../components/UI/Input/Input';
// material ui core components
import Button from '@material-ui/core/Button';
// styles
import classes from '../Auth.module.css';

class Signup extends Component {

  componentDidMount() {
    document.title = `Mi Creditel - ${this.props.forgotPassword ? "Recuperar contraseña" : "Registro"}`;
  }

  render() {
    const formElementsArray = [];
    for (let key in this.props.controls) {
      formElementsArray.push({
        id: key,
        config: this.props.controls[key]
      });
    }

    let elements = formElementsArray.map(formElement => (
      <Input
        key={formElement.id}
        id={formElement.id}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        validation={formElement.config.validation}
        valid={formElement.config.valid}
        touched={formElement.config.touched}
        value={formElement.config.value}
        error={formElement.config.error}
        showPassword={formElement.config.showPassword}
        onChange={(event) => {
          this.props.onInputChanged(event.target.value, formElement.id, "signupControls")
        }} />
    ));

    return (
      <form onSubmit={this.props.onSubmit} noValidate autoComplete="off" className={classes.Form}>
        {this.props.forgotPassword ?
          <p>Se enviará un sms con un código para confirmar.</p>
          : null}
        {elements}
        <Button
          className={classes.SubmitButton}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!this.props.formIsValid}
        >
          {this.props.forgotPassword ? "Recuperar Contraseña" : "Registrarse"}
        </Button>
      </form>
    );
  }
}

export default Signup;