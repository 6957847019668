import React, { useEffect, useState } from 'react';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
// components
import Card from '../../../components/UI/Card/Card';
import CardTitle from '../../../components/UI/Card/CardTitle/CardTitle';
import InsuranceRequirements from '../../../components/Insurances/InsuranceRequirements/InsuranceRequirements';
import Spinner from '../../../components/UI/Spinner/Spinner';
import ConfirmInsuranceRequestDialog from '../../../components/UI/Dialog/ConfirmRequestDialog/ConfirmRequestDialog';
import TermsAndConditionsDialog from '../../../components/UI/Dialog/IframeDialog/IframeDialog';
import Button from '../../../components/UI/CustomButtons/Button';
// material ui core components
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import withWidth from '@material-ui/core/withWidth';
// styles
import classes from './RequestInsurance.module.css';

import iconTick from '../../../assets/images/insurances/tick.svg'
import iconMoney from '../../../assets/images/insurances/money.svg'
import iconHistory from '../../../assets/images/insurances/history.svg'


const RequestInsurance = props => {
  const [showTermsDialog, setShowTermsDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const { requirements, box } = props;
  const { insurance, coverage, idGroup } = props.location.state;

  useEffect(() => {
    // Only fetch insurance if can be hireable
    if (!insurance.hasInsurance && insurance.canHire) {
      props.onFetchInsurance(
        props.token,
        props.match.params.insuranceId,
        idGroup
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showMoreInfo = () => {
    window.open(insurance.info);
  };

  const requestInsurance = () => {
    const reqValues = Object.keys(formValues).map(req => {
      return { value: formValues[req], name: req };
    });
    props.onRequestInsurance(
      props.token,
      props.match.params.insuranceId,
      parseInt(idGroup, 10),
      reqValues
    );
  };

  const onConfirmationDialogClosed = () => {
    props.resetInsuranceRequested();
    props.history.goBack();
  };

  let requirementsEl = (
    <p
      className={classes.EmptyState}
      style={!insurance.img ? { marginTop: '30%' } : {}}
    >
      No se pudieron obtener los datos para este seguro.
    </p>
  );
  if (props.loading) {
    requirementsEl = <Spinner />;
  } else if (requirements) {
    requirementsEl = (
      <div style={{ marginTop: '1.5rem' }}>
        <InsuranceRequirements
          requirements={requirements}
          box={box}
          onClick={values => {
            setFormValues(values);
            setShowConfirmationDialog(true);
          }}
          onShowMoreInfo={showMoreInfo}
          canHire={insurance.canHire}
          termsLink={insurance.terms}
        />
      </div>
    );
  }

  if (insurance.hasInsurance && !insurance.canHire) {
    requirementsEl = (
      <div style={{ marginTop: '1.5rem' }}>
        <div className={classes.InsuranceDetailsItem}>
          <img
            src={iconTick}
            style={{ boxSizing: 'border-box', padding: '2px' }}
            alt=""
          />
          <p className={classes.InsuranceDetailsText}>{coverage.name}</p>
        </div>
        <div className={classes.InsuranceDetailsItem}>
          <img
            src={iconMoney}
            alt=""
          />
          <p className={classes.InsuranceDetailsText}>{coverage.capital}</p>
        </div>
        <div className={classes.InsuranceDetailsItem}>
          <img
            src={iconMoney}
            alt=""
          />
          <p className={classes.InsuranceDetailsText}>{coverage.cost}</p>
        </div>
        {coverage.hasCoverage && (
          <div className={classes.InsuranceDetailsItem}>
            <img
              src={iconHistory}
              alt=""
            />
            <p className={classes.InsuranceDetailsText}>
              Fecha de contratado: {coverage.date}
            </p>
          </div>
        )}
        <div className={classes.TermsAndConditionsButton}>
          <span onClick={() => setShowTermsDialog(true)}>
            Términos y condiciones
          </span>
        </div>
        <div className={classes.ButtonsContainer}>
          <Button className={classes.InfoButton} onClick={showMoreInfo}>
            Más Info
          </Button>
        </div>
      </div>
    );
  }

  let dialogTitle = 'Seguro ingresado correctamente';
  if (props.responseCode !== 0) {
    dialogTitle = 'Ocurrió un error';
  }

  return (
    <Card className={classes.SelectedInsuranceCard}>
      <div className={classes.InsuranceImageContainer}>
        <img className={classes.InsuranceImage} alt="" src={insurance.img} />
      </div>
      <CardTitle
        style={{ margin: '0.5rem 0', width: '100%', textAlign: 'center' }}
        title={coverage.name} 
      />
      <h4 className={classes.CoverageName}>{insurance.title}</h4>
        <div className={classes.InsuranceDetailsItem}>
          <img
            src={iconTick}
            style={{ boxSizing: 'border-box', padding: '2px' }}
            alt=""
          />
          <p className={classes.InsuranceDetailsText}>{insurance.description}</p>
        </div>
        <div className={classes.InsuranceDetailsItem}>
          <img
            src={iconMoney}
            alt=""
          />
          <p className={classes.InsuranceDetailsText}>{coverage.capital}</p>
        </div>
        <div className={classes.InsuranceDetailsItem}>
          <img
            src={iconMoney}
            alt=""
          />
          <p className={classes.InsuranceDetailsText}>{coverage.cost}</p>
        </div>

      {requirementsEl}

      <Dialog
        classes={{
          paper: classes.ConfirmationDialogPaperRoot
        }}
        title={dialogTitle}
        open={
          props.insuranceRequested ||
          (!!props.responseCode && props.responseCode !== 0)
        }
      >
        <DialogContent>
          <p className={classes.ConfirmationDialogText}>
            {props.responseMessage}
          </p>
          <Button
            color="primary"
            className={classes.ConfirmationDialogButton}
            onClick={onConfirmationDialogClosed}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>

      <ConfirmInsuranceRequestDialog
        text={"¿Confirma que desea contratar el seguro?"}
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirmed={() => {
          setShowConfirmationDialog(false);
          requestInsurance();
        }}
      />

      <TermsAndConditionsDialog
        open={showTermsDialog}
        onClose={() => setShowTermsDialog(false)}
        onAccept={() => setShowTermsDialog(false)}
        termsLink={insurance.terms}
      />
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    requirements: state.insurances.requirements,
    insuranceRequested: state.insurances.insuranceRequested,
    box: state.insurances.box,
    responseMessage: state.insurances.responseMessage,
    responseCode: state.insurances.responseCode,
    loading: state.insurances.loading,
    error: state.insurances.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchInsurance: (token, insuranceId, idGroup) =>
      dispatch(actions.fetchInsuranceDetails(token, insuranceId, idGroup)),
    onRequestInsurance: (token, insuranceId, idGroup, values) =>
      dispatch(actions.requestInsurance(token, insuranceId, idGroup, values)),
    resetInsuranceRequested: () => dispatch(actions.resetInsuranceRequested())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(RequestInsurance));
