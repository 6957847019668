import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

// Card payments 

const fetchMyCardPaymentsStart = () => {
  return {
    type: actionTypes.FETCH_MY_CARD_PAYMENTS_START,
  };
};

const fetchMyCardPaymentsSuccess = payments => {
  return {
    type: actionTypes.FETCH_MY_CARD_PAYMENTS_SUCCESS,
    cardPayments: payments,
  };
};

const fetchMyCardPaymentsFail = error => {
  return {
    type: actionTypes.FETCH_MY_CARD_PAYMENTS_FAIL,
    error: error,
  };
};

export const fetchMyCardPayments = (token) => {
  const body = {
    operation: 'getCardPayment',
    sessionToken: token,
    channel: 'WEB'
  };
  return dispatch => {
    dispatch(fetchMyCardPaymentsStart());
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchMyCardPaymentsFail(response.data.responseMessage));
        } else {
          dispatch(fetchMyCardPaymentsSuccess(response.data.payments));
        }
      })
      .catch(err => {
        dispatch(fetchMyCardPaymentsFail(err));
      });
  };
};


// Cash payments

const fetchMyCashPaymentsStart = () => {
  return {
    type: actionTypes.FETCH_MY_CASH_PAYMENTS_START,
  };
};

const fetchMyCashPaymentsSuccess = payments => {
  return {
    type: actionTypes.FETCH_MY_CASH_PAYMENTS_SUCCESS,
    cashPayments: payments,
  };
};

const fetchMyCashPaymentsFail = error => {
  return {
    type: actionTypes.FETCH_MY_CASH_PAYMENTS_FAIL,
    error: error,
  };
};

export const fetchMyCashPayments = (token) => {
  const body = {
    operation: 'getCashPayment',
    sessionToken: token,
    channel: 'WEB'
  };

  return dispatch => {
    dispatch(fetchMyCashPaymentsStart());
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchMyCashPaymentsFail(response.data.responseMessage));
        } else {
          dispatch(fetchMyCashPaymentsSuccess(response.data.payments));
        }
      })
      .catch(err => {
        dispatch(fetchMyCashPaymentsFail(err));
      });
  };
};