import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as additionalCardActions from '../../../store/actions';
import { updateObject } from '../../../shared/utility';
import { checkValidity } from '../../../shared/validation';
// components
import Card from '../../../components/UI/Card/Card';
import CardTitle from '../../../components/UI/Card/CardTitle/CardTitle';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/CustomButtons/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
// material ui core components
import { Grid } from '@material-ui/core';
// styles
import classes from './AdditionalCard.module.css';

const AdditionalCard = props => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [controls, setControls] = useState({
    name: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Nombre (adicional)',
        fullwidth: true,
      },
      value: '',
      validation: {
        required: true,
      },
      error: null,
      valid: false,
      touched: false,
    },
    document: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Documento (adicional)',
        fullwidth: true,
      },
      value: '',
      validation: {
        required: true,
        isDocument: true,
        maxLength: 8,
      },
      error: null,
      valid: false,
      touched: false,
    },
    birthdate: {
      elementType: 'input',
      elementConfig: {
        type: 'date',
        placeholder: 'Fecha Nacimiento (adicional)',
        fullwidth: true,
      },
      value: 'yyyy-MM-dd',
      validation: {
        required: true,
        isDate: true,
      },
      error: null,
      valid: false,
      touched: false,
    },
  });

  useEffect(() => {
    props.onResetForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If any control change, check form validation
  useEffect(() => {
    let formIsValid = true;
    for (const controlName in controls) {
      formIsValid = controls[controlName].valid && formIsValid;
    }
    setFormIsValid(formIsValid);
  }, [controls]);

  const inputChangedHandler = (value, controlName) => {
    let [valid, error] = checkValidity(value, controls[controlName].validation);
    const updatedControls = updateObject(controls, {
      [controlName]: updateObject(controls[controlName], {
        value,
        valid,
        touched: true,
        error,
      }),
    });
    setControls(updatedControls);
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    props.onCardUpload(controls.name.value, controls.document.value, controls.birthdate.value, props.token);
  };

  let form = null;
  if (props.loading) {
    form = <Spinner />;
  } else if (!props.cardUploaded) {
    form = (
      <Fragment>
        <CardTitle title="Solicitar Tarjeta Adicional" />
        <form onSubmit={handleFormSubmit}>
          <CardBody className={classes.CardBody}>
            <Grid container>
              {Object.keys(controls).map(key => (
                <Grid item xs={12} key={key}>
                  <Input
                    id={key}
                    elementType={controls[key].elementType}
                    elementConfig={controls[key].elementConfig}
                    value={controls[key].value}
                    validation={controls[key].validation}
                    valid={controls[key].valid}
                    touched={controls[key].touched}
                    error={controls[key].error}
                    onChange={
                      controls[key].elementType === 'date'
                        ? (date, _) => inputChangedHandler(date, key)
                        : event => inputChangedHandler(event.target.value, key)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </CardBody>
          <CardFooter className={classes.CardFooter}>
            <div className={classes.CardFooterContainer}>
              <Button type="submit" color="primary" className={classes.RequestButton} disabled={!formIsValid}>
                SOLICITAR TARJETA
              </Button>
            </div>
          </CardFooter>
        </form>
      </Fragment>
    );
  }

  let feedback = null;
  if (props.cardUploaded) {
    feedback = 'Su tarjeta adicional ha sido solicitada con éxito.';
  } else if (props.error) {
    feedback =
      'Ha ocurrido un error al solicitar su tarjeta adicional. Por favor intente nuevamente más tarde.';
  }

  return (
    <Card className={classes.Card}>
      {form}
      {feedback && <p className={classes.Feedback}>{feedback}</p>}
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    cardUploaded: state.additionalCard.cardUploaded,
    loading: state.additionalCard.loading,
    error: state.additionalCard.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCardUpload: (name, document, birthdate, sessionToken) =>
      dispatch(additionalCardActions.uploadAdditionalCard(name, document, birthdate, sessionToken)),
    onResetForm: () => dispatch(additionalCardActions.resetAdditionalCardForm()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdditionalCard);
