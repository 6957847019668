import React, { Fragment, useEffect } from 'react';
// components
import Input from '../../../components/UI/Input/Input';
// material ui core components
import Button from '@material-ui/core/Button';
// styles
import classes from '../Auth.module.css';

const UpdatePassword = props => {
  useEffect(() => {
    document.title = 'Mi Creditel - Actualizar contraseña';
  }, []);

  const formElementsArray = [];
  for (let key in props.controls) {
    formElementsArray.push({
      id: key,
      config: props.controls[key]
    });
  }

  let elements = formElementsArray.map(formElement => (
    <Input
      key={formElement.id}
      id={formElement.id}
      elementType={formElement.config.elementType}
      elementConfig={formElement.config.elementConfig}
      validation={formElement.config.validation}
      valid={formElement.config.valid}
      touched={formElement.config.touched}
      value={formElement.config.value}
      error={formElement.config.error}
      showPassword={formElement.config.showPassword}
      onChange={event => {
        props.onInputChanged(
          event.target.value,
          formElement.id,
          'updatePasswordControls'
        );
      }}
    />
  ));

  return (
    <Fragment>
      <p>Por cuestiones de seguridad, su contraseña fue bloqueada, le pedimos modifique la misma para acceder a su cuenta.</p>
      <p>Motivo: {props.responseMessage}</p>
      <form onSubmit={props.onSubmit} noValidate autoComplete="off" className={classes.Form}>
        {elements}
        <Button
          className={classes.SubmitButton}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!props.formIsValid}
        >
          Resetear Contraseña
      </Button>
        <div>
          <Button onClick={props.onLogout}>Cerrar Sesión</Button>
        </div>
      </form>
    </Fragment>
  );
};

export default UpdatePassword;
