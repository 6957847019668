import React from 'react';
import classNames from 'classnames';
// datepicker
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import esLocale from 'date-fns/locale/es';
// material ui core components
import TextField from '@material-ui/core/TextField';
// styles
import classes from './CustomDatePicker.module.css';

const CustomDatePicker = (props) => {

  const {
    id,
    validation,
    valid,
    touched,
    elementConfig,
    value,
    onChange,
    onBlur
  } = props;

  registerLocale('es', esLocale);

  return (
    <div className={classes.ReactDatePicker}>
      <DatePicker
        id={id}
        selected={value ? value : null}
        onChange={onChange}
        onBlur={onBlur}
        dateFormat='dd/MM/yyyy'
        placeholderText='dd/mm/aaaa'
        showMonthDropdown
        showYearDropdown
        locale='es'
        className={classNames(classes.Dense, classes.TextField)}
        customInput={
          <TextField
            error={!valid && touched}
            label={elementConfig.placeholder}
            required={validation.required}
            type='text'
            variant='outlined'
          />
        }
      />
    </div>
  )
}

export default CustomDatePicker;