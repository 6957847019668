import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_CREDITEL_BACKEND_BASE_URL,
  headers: {
    'X-Client-Platform': 3,
    'X-Client-Build': -1,
    'X-Client-Version': process.env.REACT_APP_BUILD_VERSION,
    'X-Client-Channel': 'web',
  },
});

export default instance;