import React from 'react';
// HOC 
import withDocumentTitle from '../../../hoc/withDocumentTitle/withDocumentTitle';
// components
import Input from '../../../components/UI/Input/Input';
// material ui core components
import Button from '@material-ui/core/Button';
// styles
import classes from '../Auth.module.css';

const Login = (props) => {

  const formElementsArray = [];
  for (let key in props.controls) {
    formElementsArray.push({
      id: key,
      config: props.controls[key]
    });
  }

  let elements = formElementsArray.map(formElement => (
    <Input
      key={formElement.id}
      id={formElement.id}
      elementType={formElement.config.elementType}
      elementConfig={formElement.config.elementConfig}
      validation={formElement.config.validation}
      valid={formElement.config.valid}
      touched={formElement.config.touched}
      value={formElement.config.value}
      error={formElement.config.error}
      showPassword={formElement.config.showPassword}
      onChange={(event) => {
        props.onInputChanged(event.target.value, formElement.id, "loginControls")
      }} />
  ));

  return (
    <div>
      <form onSubmit={props.onSubmit} noValidate autoComplete="off" className={classes.Form}>
        {elements}
        <Button className={classes.ForgotPasswordButton} onClick={props.onForgotPasswordClick}>
            Olvidé mi contraseña
        </Button>
        <Button className={classes.SubmitButton} 
            type="submit" variant="contained" 
            color="primary" disabled={!props.formIsValid}>
            Ingresar
          </Button>
      </form>
      <br></br>
    </div>
  );
}

export default withDocumentTitle(Login, "Mi Creditel - Login");