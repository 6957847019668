import React from 'react';
import moment from 'moment';
import localization from 'moment/locale/es';
import { formatPrice } from '../../../shared/utility';
// material ui core components
import Paper from '@material-ui/core/Paper';
// styles
import classes from './CreditHistoryListItem.module.css';

const CreditHistoryListItem = (props) => {
  const { credit } = props;
  return (
    <Paper className={classes.CreditHistoryListItem} onClick={() => props.onClick(credit)}>
      <p className={classes.Status}>{getStatusText(credit.idStatus, credit.ltp, credit.term)}</p>
      <p className={classes.Date}>{moment(credit.dateStart, "DD-MM-YYYY").locale("es", localization).format("DD MMMM YYYY")}</p>
      <p className={classes.Account}>Nº {credit.cuenta}</p>
      <p className={classes.Amount}>{formatPrice(credit.capital)}</p>
    </Paper>
  );
}

const getStatusText = (status, ltp, term) => {
  if (status === 1) {
    return "CRÉDITO APROBADO";
  } else if (ltp === term) {
    return "CRÉDITO CANCELADO";
  } else {
    return "CRÉDITO ENTREGADO";
  }
}

export default CreditHistoryListItem;