import React from 'react';
// components
import NewsListItem from './NewsListItem/NewsListItem';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './NewsList.module.css';

const NewsList = (props) => {

  return (
    <Grid container spacing={16} className={classes.NewsList}>
      {props.news.length > 0 ? props.news.map((newsItem, index) => {
        return (
          <Grid
            item
            xs={12}
            key={index}
            className={classes.NewsListItem}
            style={{ padding:'5px' }}
          >
            <NewsListItem
              title={newsItem.title}
              description={newsItem.description}
              img={newsItem.img}
              action={newsItem.action}
              actionName={newsItem.actionName}
              date={newsItem.date}
              read={newsItem.read}
              onClick={props.onClick} />
          </Grid>
        )
      }) : <p className={classes.EmptyState}>No tienes novedades</p>}
    </Grid>
  )
}

export default NewsList;