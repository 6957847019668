import React from 'react';
// material ui core components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
// styles
import classes from '../Auth.module.css';

const TermsAndConditions = (props) => {
  return (
    <Dialog
      open={!props.termsAccepted}
      onClose={() => { }}
      classes={{
        paper: classes.DialogPaper
      }}
      title="Términos y Condiciones"
      scroll="paper"
    >
      <DialogContent>
        <div
          className={classes.TermsAndConditonsContainer}
          dangerouslySetInnerHTML={
            {
              __html: "<iframe style='border:none;' src='" +
                this.props.terms + "' width='100%' height='100%'></iframe>"
            }
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onTermsAndConditionsResponse(true)} color="primary" autoFocus>
          Aceptar
      </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TermsAndConditions;