// components
import AccountStatement from '../containers/Card/AccountStatement/AccountStatement';
import CurrentMovements from '../containers/Card/CurrentMovements/CurrentMovements';
import AdditionalCard from '../containers/Card/AdditionalCard/AdditionalCard';
import Account from '../containers/Account/Account';
//import eTicket from '../containers/eTicket/eTicket';
import Recharges from '../containers/Recharges/Recharges';
//import PrepaidAccountStatus from '../containers/PrepaidAccountStatus/PrepaidAccountStatus';
import StmTrips from '../containers/StmTrips/StmTrips';
import DigitalReceipts from '../containers/DigitalReceipts/DigitalReceipts';
import Logout from '../containers/Auth/Logout/Logout';
import CreditLine from '../containers/CreditLine/CreditLine';
import CreditelShop from '../containers/CreditelShops/CreditelShop/CreditelShop';
import CreditelShops from '../containers/CreditelShops/CreditelShops';
import MyPayments from '../containers/MyPayments/MyPayments';
import Debits from '../containers/Debits/Debits';
import CreditHistory from '../containers/CreditHistory/CreditHistory';
import News from '../containers/News/News';
import RequestInsurances from '../containers/RequestInsurances/RequestInsurances';
import RequestInsurance from '../containers/RequestInsurances/RequestInsurance/RequestInsurance';
import MyAccounts from '../containers/BankAccounts/MyAccounts/MyAccounts';
import AddAccounts from '../containers/BankAccounts/AddAccounts/AddAccounts';
import AddAccount from '../containers/BankAccounts/AddAccounts/AddAccount/AddAccount';
import Payment from '../containers/Payment/Payment';

const routes = [
  {
    path: '/seguros/:insuranceId',
    documentTitle: 'Solicitar Seguros',
    component: RequestInsurance
  },
  {
    path: '/agregar-cuenta/:bankId',
    documentTitle: 'Agregar Cuenta',
    component: AddAccount
  },
  {
    path: '/linea-de-credito',
    sidebarName: 'Línea Crédito',
    component: CreditLine
  },
  {
    path: '/cuentas-bancarias',
    sidebarName: 'Cuentas Bancarias',
    routes: [
      {
        path: '/mis-cuentas',
        sidebarName: 'Mis Cuentas',
        component: MyAccounts
      },
      {
        path: '/agregar-cuenta',
        sidebarName: 'Agregar Cuentas',
        component: AddAccounts
      },
    ]
  },
  {
    key: 'card',
    sidebarName: 'Tarjeta',
    disabledForAdditionalAccount: true,
    routes: [
      {
        path: '/estado',
        sidebarName: 'Estado de Cuenta',
        component: AccountStatement,
        disabledForAdditionalAccount: true
      },
      {
        path: '/movimientos',
        sidebarName: 'Movimientos Actuales',
        component: CurrentMovements,
        disabledForAdditionalAccount: true
      },
      {
        path: '/debitos',
        sidebarName: 'Solicitar Débitos',
        component: Debits,
        disabledForAdditionalAccount: true
      },
      {
        path: '/seguros',
        sidebarName: 'Solicitar Seguros',
        component: RequestInsurances,
        disabledForAdditionalAccount: true
      },
      {
        path: '/tarjeta-adicional',
        sidebarName: 'Solicitar Tarjeta Adicional',
        component: AdditionalCard,
        disabledForAdditionalAccount: true
      },
    ]
  },
  {
    key: 'products',
    sidebarName: 'Productos',
    routes: [
      {
        path: '/recargas',
        sidebarName: 'Mis Recargas',
        component: Recharges
      },
      {
        path: '/stm',
        sidebarName: 'Mis Viajes STM',
        component: StmTrips
      },
      {
        path: '/historial-prestamos',
        sidebarName: 'Historial de préstamos',
        component: CreditHistory
      }
    ]
  },
  {
    path: '/tienda-creditel/:shopId',
    documentTitle: 'Tienda Creditel',
    component: CreditelShop
  },
  {
    path: '/tienda-creditel',
    sidebarName: 'Tienda Creditel',
    component: CreditelShops
  },
  {
    path: '/deuda',
    sidebarName: 'Deuda',
    component: Payment
  },
  {
    path: '/mis-pagos',
    sidebarName: 'Mis Pagos',
    component: MyPayments
  },
  {
    path: '/recibos',
    sidebarName: 'Recibos Digitales',
    component: DigitalReceipts
  },
  /*
  {
    path: '/eticket',
    sidebarName: 'e-Ticket',
    component: eTicket
  },
  /*{
    path: '/prepago',
    sidebarName: 'Cuenta Prepaga',
    component: PrepaidAccountStatus
  },*/
  {
    path: '/novedades',
    sidebarName: 'Novedades',
    component: News
  },
  {
    path: '/cuenta',
    sidebarName: 'Mi Cuenta',
    component: Account
  },
  {
    path: '/logout',
    sidebarName: 'Cerrar sesión',
    component: Logout,
    onlyResponsive: true
  }
];

export default routes;
