import React from 'react';
import { Formik, Field, getIn } from 'formik';
import { checkValidity } from '../../../shared/validation';
// components
import Button from '../../UI/CustomButtons/Button';
import Input from '../../UI/Input/Input';
import ConfirmDebitRequestDialog from '../ConfirmDebitRequestDialog/ConfirmDebitRequestDialog';
// material ui icons
import AccountBox from '@material-ui/icons/AccountBox';
// styles
import classes from './AddDebitForm.module.css';

const AddDebitForm = props => {

  const { fields, helpImage, onClick, openConfirmationDialog, onConfirmationDialogClose, onConfirmed } = props;

  const getInitialValues = () => {
    let debitFields = {};
    for (let field of fields) {
      debitFields[field] = ""
    }
    return { ...debitFields }
  }

  const getImageForIndex = (index) => {
    let imageName = null;
    switch (index) {
      case 0:
        imageName = "letter_a.png";
        break;
      case 1:
        imageName = "letter_b.png";
        break;
      case 2:
        imageName = "letter_c.png";
        break;
      case 3:
        imageName = "letter_d.png";
        break;
      default:
        imageName = "";
    }
    return <img className={classes.LetterIcon} src={require("../../../assets/images/debits/" + imageName)} alt="" />;
  }

  const validate = values => {
    let errors = {};
    for (let value in values) {
      const [valid, error] = checkValidity(values[value], { required: true });
      if (!valid) {
        errors[value] = error;
      }
    }
    return errors;
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validate={values => validate(values)}
      render={formProps => (
        <form className={classes.AddDebitForm}> 
          {Object.keys(formProps.values).map((value, index) => {
            let icon = null;
            if (formProps.values.length === 1) {
              icon = <AccountBox className={classes.UserInfoIcon} />;
            } else {
              icon = getImageForIndex(index)
            }
            return (
              <Field
                key={value}
                name={value}
                render={({ field, form }) => (
                  <Input
                    id={value}
                    valid={formProps.values[field.name] !== null && formProps.values[field.name] !== ""}
                    touched={getIn(form.touched, field.name) || form.submitCount > 0}
                    validation={{
                      required: true
                    }}
                    elementConfig={{
                      type: 'number',
                      placeholder: value,
                      fullwidth: true
                    }}
                    value={formProps.values[field.name]}
                    elementType='input'
                    error={formProps.errors[field.name]}
                    onChange={(event) => {
                      form.setFieldTouched(field.name, true);
                      formProps.handleChange(event);
                    }}
                    startAdornment={icon} />
                )}
              />
            )
          })}
          <img
            alt=""
            className={classes.HelpImage}
            src={helpImage} />

          <Button
            color="primary"
            className={classes.Button}
            disabled={!formProps.isValid}
            onClick={onClick}
          >
            Confirmar
          </Button>

          <ConfirmDebitRequestDialog
            open={openConfirmationDialog}
            onClose={onConfirmationDialogClose}
            onConfirmed={() => onConfirmed(formProps.values)}
          />
        </form>
      )}
    />
  )
}

export default AddDebitForm;