import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

const fetchMovementsStart = () => {
  return {
    type: actionTypes.FETCH_MOVEMENTS_START,
  };
};

const fetchMovementsSuccess = movements => {
  return {
    type: actionTypes.FETCH_MOVEMENTS_SUCCESS,
    movements: movements,
  };
};

const fetchMovementsFail = error => {
  return {
    type: actionTypes.FETCH_MOVEMENTS_FAIL,
    error: error,
  };
};

export const fetchMovements = (token, document) => {
  const body = {
    operation: 'getMovements',
    sessionToken: token,
    document: document,
    channel: "WEB"
  };
  return dispatch => {
    dispatch(fetchMovementsStart());
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchMovementsFail(response.data.responseMessage));
        } else {
          dispatch(fetchMovementsSuccess(response.data.currentMovements));
        }
      })
      .catch(err => {
        dispatch(fetchMovementsFail(err));
      });
  };
};
