import React from 'react';
import { parseDate } from '../../../shared/utility';
// components
import ReceiptDetailItem from './ReceiptDetailItem/ReceiptDetailItem';
import Button from '../../UI/CustomButtons/Button';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './DigitalReceipt.module.css';

import iconCreditelPin from '../../../assets/images/creditel_map_pin.svg'

const DigitalReceipt = (props) => {

  return (
    <div className={classes.DigitalReceipt}>
      <div>
        <Grid container justify="center">
          <Grid item xs={3}>
            <img width="55px" src={iconCreditelPin} alt="" />
            <p className={classes.RutText}>RUT 21 122351 0015</p>
          </Grid>
          <Grid item xs={6} className={classes.OfficeInfoContainer}>
            <span className={classes.OfficeTitle}>SOCUR S.A.</span>
            <span className={classes.OfficeInfoText}>{props.receipt.direccion}</span>
            <span className={classes.OfficeInfoText}>Tel.: {props.receipt.telefono}</span>
          </Grid>
          <Grid item xs={3}>
            <p className={classes.SerialNumberText}>{props.receipt.serie}{props.receipt.numero}</p>
          </Grid>
        </Grid>
        <p className={classes.ReceiptType}>RECIBO DE COBRANZA A LIQUIDAR - {props.receipt.tipoRecibo}</p>

        <div className={classes.Divider}></div>

        <p className={classes.ReceiptDetailsText}>Detalles del recibo</p>
        <Grid container>
          <ReceiptDetailItem
            title="Importe"
            value={props.receipt.importe} />

          {props.receipt.seguro ?
            <ReceiptDetailItem
              title="Seguro"
              value={props.receipt.seguro} />
            : null}
          {props.receipt.ivaSeguro ?
            <ReceiptDetailItem
              title="IVA Seguro"
              value={props.receipt.ivaSeguro} />
            : null}

          {props.receipt.multa ?
            <ReceiptDetailItem
              title="Multa"
              value={props.receipt.multa} />
            : null}

          {props.receipt.recargo ?
            <ReceiptDetailItem
              title="Recargo"
              value={props.receipt.recargo} />
            : null}
        </Grid>

        <div className={classes.Divider} style={{ marginTop: '25px' }}></div>

        <Grid container className={classes.TotalContainer}>
          <Grid item xs={6}>
            <span className={classes.TotalValueText}>TOTAL</span>
          </Grid>
          <Grid item xs={6}>
            <span className={classes.TotalValue}>{props.receipt.moneda === "2222" ? "U$S" : "$"} {props.receipt.total}</span>
          </Grid>
        </Grid>
        <div className={classes.Divider}></div>

        <p className={classes.ReceiptDetailsText}>Información del producto</p>

        <Grid container>
          <ReceiptDetailItem
            title="Número de cuenta"
            value={props.receipt.codigoCredito} />

          <ReceiptDetailItem
            title="Cuotas"
            value={props.receipt.cuota} />

          <ReceiptDetailItem
            title="Próximo vencimiento"
            value={props.receipt.proximoVencimiento} />

        </Grid>

        <p className={classes.ReceiptDetailsText}>Otra información</p>
        <Grid container>
          <ReceiptDetailItem
            title="Fecha de ingreso"
            value={parseDate(props.receipt.fecha)} />

          <ReceiptDetailItem
            title="Sucursal"
            value={props.receipt.sucursal} />

          <ReceiptDetailItem
            title="Atendido por"
            value={props.receipt.usuAtendio} />

        </Grid>
      </div>
      <p className={classes.Message}>{props.receipt.mensaje}</p>
      <Button
        color="primary"
        className={classes.EticketButton}
        onClick={() => window.open(props.receipt.url)}>
        Ver E-Ticket
      </Button>
    </div>
  );
};

export default DigitalReceipt;