import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  insurances: null,
  responseCode: null,
  loading: false
};

const fetchInsurancesStart = (state, _) => {
  return updateObject(state, { loading: true });
};

const fetchInsurancesSuccess = (state, action) => {
  return updateObject(state, {
    insurances: action.insurances,
    loading: false
  });
};

const fetchInsurancesFail = (state, _) => {
  return updateObject(state, { loading: false });
};


const fetchInsuranceDetailsStart = (state, _) => {
  return updateObject(state, { loading: true });
};

const fetchInsuranceDetailsSuccess = (state, action) => {
  return updateObject(state, {
    requirements: action.requirements,
    responseCode: action.responseCode,
    responseMessage: action.responseMessage,
    box: action.box,
    loading: false
  });
};

const fetchInsuranceDetailsFail = (state, _) => {
  return updateObject(state, { loading: false });
};


const requestInsuranceStart = (state, _) => {
  return updateObject(state, { loading: true });
};

const requestInsuranceSuccess = (state, action) => {
  return updateObject(state, {
    responseMessage: action.responseMessage,
    insuranceRequested: true,
    loading: false
  });
};

const requestInsuranceFail = (state, _) => {
  return updateObject(state, { loading: false });
};

const resetInsuranceRequested = (state, _) => {
  return updateObject(state, {
    insuranceRequested: false,
    responseCode: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INSURANCES_START: return fetchInsurancesStart(state, action);
    case actionTypes.FETCH_INSURANCES_SUCCESS: return fetchInsurancesSuccess(state, action);
    case actionTypes.FETCH_INSURANCES_FAIL: return fetchInsurancesFail(state, action);

    case actionTypes.FETCH_INSURANCE_START: return fetchInsuranceDetailsStart(state, action);
    case actionTypes.FETCH_INSURANCE_SUCCESS: return fetchInsuranceDetailsSuccess(state, action);
    case actionTypes.FETCH_INSURANCE_FAIL: return fetchInsuranceDetailsFail(state, action);

    case actionTypes.REQUEST_INSURANCE_START: return requestInsuranceStart(state, action);
    case actionTypes.REQUEST_INSURANCE_SUCCESS: return requestInsuranceSuccess(state, action);
    case actionTypes.REQUEST_INSURANCE_FAIL: return requestInsuranceFail(state, action);

    case actionTypes.RESET_INSURANCE_REQUESTED: return resetInsuranceRequested(state, action);
    default: return state;
  }
};

export default reducer;