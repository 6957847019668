import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

export const fetchRechargesSuccess = (recharges) => {
  return {
    type: actionTypes.FETCH_RECHARGES_SUCCESS,
    recharges: recharges
  }
};

export const fetchRechargesFail = (error) => {
  return {
    type: actionTypes.FETCH_RECHARGES_FAIL,
    error: error
  }
};

export const fetchRechargesStart = () => {
  return {
    type: actionTypes.FETCH_RECHARGES_START
  }
};

export const fetchRecharges = (token, date) => {
  let body = {
    operation: "getRecharges",
    sessionToken: token,
    date: date,
    web: "true",
    channel: "WEB"
  };
  return dispatch => {
    dispatch(fetchRechargesStart());
    axios.post('/', body)
      .then(response => {
        dispatch(fetchRechargesSuccess(response.data.recharges));
      })
      .catch(error => {
        dispatch(fetchRechargesFail(error));
      });
  };
};