import React, { Fragment } from 'react';
import classNames from 'classnames';
// styles
import classes from './CustomTabs.module.css';

const CustomTabLabel = ({ label, img, imgSize, header }) => {
  return (
    <Fragment>
      {img ? <img className={classes.TabImg} width={imgSize} src={img} alt="" /> : null}
      <span className={classNames(classes.TabLabel, header ? classes.TabLabelHeader : null)}>{label}</span>
    </Fragment>
  );
}

export default CustomTabLabel;