import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { updateObject, getLast12Months } from '../../shared/utility';
import { checkValidity } from '../../shared/validation';
import * as actions from '../../store/actions/index';
// component
import Card from '../../components/UI/Card/Card';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/CustomButtons/Button';
import CardTitle from '../../components/UI/Card/CardTitle/CardTitle';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Spinner from '../../components/UI/Spinner/Spinner';
// material ui core components
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
// styles
import myClasses from './StmTrips.module.css';
import tableStyle from '../../assets/jss/components/tableStyle';

const tableHeaders = [
  "Cliente STM",
  "Fecha",
  "Cantidad de pasajeros",
  "Importe ($)",
  "Estado STM"
];

const tableRows = [
  "stmClient",
  "date",
  "passengerCount",
  "amount",
  "stmState"
];

class StmTrips extends Component {

  constructor (props) {
    super(props);
    this.state = {
      controls: {
        date: {
          label: "Mes/Año",
          elementType: 'select',
          elementConfig: {
            placeholder: 'Elija Mes/Año',
            options: getLast12Months(),
            variant: 'outlined'
          },
          value: '',
          validation: {
            required: true
          },
          valid: false,
          touched: false
        }
      }
    };
  }

  inputChangedHandler = (value, controlName) => {
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        ...this.state.controls[controlName],
        value: value,
        valid: checkValidity(value, this.state.controls[controlName].validation),
        touched: true
      })
    });
    this.setState({ controls: updatedControls });
  };

  handleShowStmTrips = () => {
    this.props.onFetchStmTrips(this.props.token, this.state.controls.date.value);
  };

  render() {
    let form = Object.keys(this.state.controls).map((key) => {
      let element = this.state.controls[key];
      return (
        <Grid item xs={12} md={5} key={key}>
          <div className={myClasses.InputContainer}>
            <Input
              key={key}
              label={element.label}
              elementType={element.elementType}
              elementConfig={element.elementConfig}
              value={element.value}
              validation={element.validation}
              valid={element.valid}
              touched={element.touched}
              onChange={(event) => this.inputChangedHandler(event.target.value, key)}
            />
          </div>
        </Grid>
      )
    });

    const { classes } = this.props;

    let stmTrips = null;

    if (this.props.loading) {
      stmTrips = <Spinner />;

    } else if (this.props.stmTrips != null) {

      if (this.props.stmTrips.length === 0) {
        stmTrips = <p className={myClasses.EmptyState}>No se encontraron resultados</p>;
      } else {
        const rows = this.props.stmTrips.map((eTicket, idx) => {
          return (
            <TableRow key={idx}>
              {tableRows.map((row, index) => {
                return (
                  <TableCell
                    classes={classNames(classes.tableCell, classes.tableHeadCell)}>
                    {eTicket[row]}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        });

        stmTrips = (
          <Card className={classNames(myClasses.Card, classes.tableResponsive)}>
            <Table style={{ marginTop: '50px' }} className={classes.table}>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => {
                    return (
                      <TableCell
                        key={header}
                        className={classNames(classes.tableCell, classes.tableHeadCell)}
                        align="right">
                        {header}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows}
              </TableBody>
            </Table>
          </Card>
        );
      }
    }

    return (
      <Fragment>
        <Card className={myClasses.Card}>
          <CardTitle title={"Consulta de Viajes STM"} />
          <Grid container alignItems="center" className={myClasses.FiltersContainer}>
            {form}
            <Grid item xs={12} md={2} className={myClasses.SubmitButtonContainer}>
              <Button
                className={myClasses.SubmitButton}
                variant="contained"
                color="primary"
                onClick={this.handleShowStmTrips}
              >
                Consultar
          </Button>
            </Grid>
          </Grid>
        </Card>
        {stmTrips}
      </Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
    stmTrips: state.stm.stmTrips,
    error: state.stm.error,
    loading: state.stm.loading,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchStmTrips: (token, date) => dispatch(actions.fetchStmTrips(token, date))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(tableStyle)(StmTrips));

