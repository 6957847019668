import React from 'react';
import classNames from 'classnames';
import { formatPriceConMoneda } from '../../../shared/utility';
// material ui core components
import Grid from '@material-ui/core/Grid';
// material ui icons
import Info from '@material-ui/icons/Info';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Assessment from '@material-ui/icons/Assessment';
// styles
import classes from './PaymentsCashDetail.module.css';
import FeeDetail from './FeeDetail';

const PaymentCashDetail = (props) => {
  return (
    <div style={{ padding: '30px', width: '300px' }}>
      <Grid container alignItems="center">
        <Grid item xs={12} className={classes.ImageContainer}>
          <h3>Detalle del préstamo</h3>
        </Grid>
        <Grid item xs={1} className={classes.GridItem}>
            <Info className={classes.Icon} />
        </Grid>
        <Grid item xs={6} className={classNames(classes.FieldTitle, classes.GridItem)}>
            <span className={classes.PaymentFieldText}>Cuenta</span>
        </Grid>
        <Grid item xs={5} className={classes.GridItem}>
            <span className={classes.PaymentFieldValue}>{props.payment.account}</span>
        </Grid>
        <Grid item xs={1} className={classes.GridItem}>
          <Assessment className={classes.Icon} />
        </Grid>
        <Grid item xs={6} className={classNames(classes.FieldTitle, classes.GridItem)}>
          <span className={classes.PaymentFieldText}>Cuotas adeudadas</span>
        </Grid>
        <Grid item xs={5} className={classes.GridItem}>
          <span className={classes.PaymentFieldValue}>{props.payment.fees.length }</span>
        </Grid>
        <Grid item xs={1} className={classes.GridItem}>
          <AttachMoney className={classes.Icon} />
        </Grid>
        <Grid item xs={6} className={classNames(classes.FieldTitle, classes.GridItem)}>
          <span className={classes.PaymentFieldText}>Monto</span>
        </Grid>
        <Grid item xs={5} className={classes.GridItem}>
          <span className={classes.PaymentFieldValue}>{formatPriceConMoneda(props.payment.amount, props.payment.currency)}</span>
        </Grid>
        <Grid item xs={12} className={classes.ImageContainer}>
          <hr></hr>
          <h4>Detalle del cuotas</h4>
        </Grid>
        {
            props.payment.fees.map(fee => {
            return (
                <FeeDetail payments={fee} />
            )
          })
        }

      </Grid>
    </div>
  )
}

export default PaymentCashDetail;