import React from 'react';
import PropTypes from 'prop-types';
// styles
import classes from './CardTitle.module.css';

const CardTitle = props => (
  <h1 className={classes.CardTitle} style={{ textAlign: props.align ? props.align : "left", ...props.style}}>{props.title}</h1>
);

CardTitle.propTypes = {
  title: PropTypes.string
};

export default CardTitle;