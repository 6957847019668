import React from 'react';
// material ui components
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
// styles
import classes from './DebitRequestSuccessDialog.module.css';

const DebitRequestSuccessDialog = props => {
  const { open, onClose, responseMessageTop, responseMessageBottom } = props;

  return (
    <Dialog
      classes={{
        paper: classes.SuccessDialogPaperRoot
      }}
      open={open}
      onClose={onClose}>
      <DialogContent>
        <p className={classes.MessageTop}>{responseMessageTop}</p>
        <img className={classes.SuccessImage} alt="" src={require("../../../assets/images/debits/icon_tilde.png")} />
        <p className={classes.MessageBottom}>{responseMessageBottom}</p>
        <Button
          className={classes.OkButton}
          onClick={onClose}>
          OK
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default DebitRequestSuccessDialog;