import React from 'react';
// material ui core components
import Grid from '@material-ui/core/Grid';
// components
import CardListItem from './PaymentItem/CardListItem';
// styles
import classes from './Payment.module.css';

const PaymentCard = (props) => {
  return (
    <div className={classes.MyPaymentsList}>
      {props.payments.length > 0 ?
        <Grid container spacing={16}>
          {props.payments.map(payment => {
            return (
              <Grid item xs={12} md={4} key={payment.authNumber} style={{ padding:'5px' }}>
                <CardListItem payment={payment}/>
              </Grid>
            )
          })}
        </Grid>
        :
        <div className={classes.EmptyStateContainer}>
          <img className={classes.EmptyStateImg} alt="" src={require("../../assets/images/myPayments/historial_pagos.png")} />
          <p className={classes.EmptyStateText}>No tienes pagos de tarjeta pendientes</p>
        </div>
      }
    </div>
  )
};

export default PaymentCard;