import React from 'react';
// components
import RequestInsurance from './RequestInsurance/RequestInsurance';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './RequestInsurancesList.module.css';

const RequestInsurancesList = props => {
  return (
    props.insurances ?
      <Grid container spacing={24} className={classes.GridContainer} alignItems="center">
        {props.insurances.map(insurance => {
          return (
            <Grid
              item
              xs={6}
              sm={3}
              key={insurance.idGroup}
              onClick={() => props.onSelectedInsurance(insurance)}
              style={{ padding:'5px' }}
            >
              <RequestInsurance
                hasInsurance={insurance.hasInsurance}
                name={insurance.name}
                logo={insurance.logoImageUrl} />
            </Grid>
          );
        })}
      </Grid>
      : null
  )
}

export default RequestInsurancesList;