import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

export const fetchAccountStatementSuccess = (accountStatement) => {
  return {
    type: actionTypes.FETCH_ACCOUNT_STATEMENT_SUCCESS,
    accountStatement
  }
};

export const fetchAccountStatementFail = (error) => {
  return {
    type: actionTypes.FETCH_ACCOUNT_STATEMENT_FAIL,
    error: error
  }
};

export const fetchAccountStatementStart = () => {
  return {
    type: actionTypes.FETCH_ACCOUNT_STATEMENT_START
  }
};

export const fetchAccountStatement = (token) => {
  let body = {
    operation: "getCycles",
    sessionToken: token,
    channel: "WEB"
  };
  return (dispatch) => {
    dispatch(fetchAccountStatementStart());
    axios.post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchAccountStatementFail(response.data.responseMessage));
        } else {
          dispatch(fetchAccountStatementSuccess(response.data.cycles));
        }
      })
      .catch(error => {
        dispatch(fetchAccountStatementFail(error));
      });
  };
};