import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  stmTrips: null,
  loading: false
};

const fetchStmTripsStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchStmTripsSuccess = (state, action) => {
  return updateObject(state, {
    stmTrips: action.stmTrips,
    loading: false
  });
};

const fetchStmTripsFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STM_TRIPS_START: return fetchStmTripsStart(state, action);
    case actionTypes.FETCH_STM_TRIPS_SUCCESS: return fetchStmTripsSuccess(state, action);
    case actionTypes.FETCH_STM_TRIPS_FAIL: return fetchStmTripsFail(state, action);
    default: return state;
  }
};

export default reducer;