import React from 'react';
import PropTypes from 'prop-types';
// material ui core components
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
// styles
import classes from './CustomInput.module.css';

const getInputElementClasses = (type, fullwidth, textTransform, noDense) => {
  let elementClasses = [];

  if (!noDense) {
    elementClasses.push(classes.Dense);
  }

  if (type === "search") {
    elementClasses.push(classes.Search);
  }

  if (fullwidth) {
    elementClasses.push(classes.FullWidth);
  } else if (type === "textarea") {
    elementClasses.push(classes.Textarea);
  } else {
    elementClasses.push(classes.TextField);
  }

  if (textTransform) {
    elementClasses.push(classes.Uppercase);
  }

  return elementClasses.join(' ');
}

const CustomInput = (props) => {

  const {
    id,
    valid,
    touched,
    validation,
    elementConfig,
    value,
    elementType,
    error,
    onChange,
    onBlur,
    startAdornment,
    showPassword
  } = props;

  const elementClasses = getInputElementClasses(
    elementType,
    elementConfig.fullwidth,
    elementConfig.textTransform,
    elementConfig.noDense
  );

  return (
    <div style={{ textAlign: 'center' }}>
      <TextField
        id={id}
        error={!valid && touched}
        required={validation.required}
        variant="outlined"
        onCopy={() => { return false }}
        onPaste={(event) => {
          if (elementConfig.type === 'password') {
            event.preventDefault();
          }
        }}
        label={elementConfig.placeholder}
        className={elementClasses}
        margin={elementConfig.noDense ? "none" : "dense"}
        value={value}
        multiline={elementType === "textarea"}
        onChange={onChange}
        onBlur={onBlur}
        type={elementConfig.type}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment:
            elementConfig.type === 'password' || showPassword ?
              (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Cambiar visibilidad contraseña"
                    onClick={elementConfig.onClickShowPassword}
                    onMouseDown={elementConfig.onMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : null
          ,
          inputProps: {
            maxLength: validation.maxLength,
            max: validation.maxLength,
            className: classes.Input
          }
        }} />
      {error && touched ?
        <FormHelperText
          error={true}
          className={classes.HelperText}
        >{error}</FormHelperText> : null}
    </div>
  );
}

CustomInput.propTypes = {
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  validation: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])),
  elementConfig: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
      PropTypes.func
    ]
    )
  ),
  value: PropTypes.node,
  elementType: PropTypes.string,
  error: PropTypes.string
};

export default CustomInput;