import React, { Fragment } from 'react';
import { formatPriceConMoneda } from '../../../shared/utility'; 
// material ui core components
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// material ui icons
import CheckCircle from '@material-ui/icons/CheckCircle';
import Warning from '@material-ui/icons/Warning';
import Calendar from '@material-ui/icons/CalendarToday';
// styles
import classes from './MyPaymentsItem.module.css';

const MyPaymentsItem = (props) => {

  let paymentTypeLogo = null;
  let imageAlternate = ""
  if (props.payment.subsidiary.toUpperCase() === "BANRED") {
    paymentTypeLogo = require("../../../assets/images/myPayments/logo_banred.png");
    imageAlternate = "Banred";
  } else if (props.payment.subsidiary.toUpperCase() === "REDPAGOS") {
    paymentTypeLogo = require("../../../assets/images/myPayments/logo_redpagos.png");
    imageAlternate = "Redpagos";
  } else if (props.payment.subsidiary.toUpperCase() === "PAGANZA") {
    paymentTypeLogo = require("../../../assets/images/myPayments/logo_paganza.png");
    imageAlternate = "Paganza";
  }else {
    paymentTypeLogo = require("../../../assets/images/myPayments/logo_creditel.png");
    imageAlternate = "Creditel";
  }

  return (
    <Card className={classes.MyPaymentsListItem} onClick={() => props.onClick(props.payment)}>
      <CardContent>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <img alt={imageAlternate} src={paymentTypeLogo} style={{ maxHeight: '60px' }} />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: '10px', position: 'relative' }}>
            <div className={classes.Divider}></div>
            <div style={{ marginLeft: '10px' }}>
              <p className={classes.AmountText}>{formatPriceConMoneda(props.payment.payment, props.payment.moneda)}</p>
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <Calendar className={classes.DateIcon} />
                </Grid>
                <Grid item xs={10}>
                  <span className={classes.DateText}>{props.payment.date.split(" ")[0]}</span>
                </Grid>
              </Grid>
              <div>
                {props.payment.cancelled === 0 ?
                  <Grid container alignItems="center" className={classes.StatusContainer}>
                    <Grid item>
                      <CheckCircle className={classes.ConfirmedIcon} />
                    </Grid>
                    <Grid item>
                      <span className={classes.Status}>CONFIRMADO</span>
                    </Grid>
                  </Grid>
                  :
                  <Fragment>
                    <Warning className={classes.CancelledIcon} />
                    <span className={classes.Status}>ANULADO</span>
                  </Fragment>}
              </div>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MyPaymentsItem;