import React from 'react';
import classNames from 'classnames';
// material ui core components
import { IconButton, Grid } from '@material-ui/core';
// material ui icons
import DeleteIcon from '@material-ui/icons/Clear';
// styles
import classes from './BankAccount.module.css';

const BankAccount = (props) => {

  const {
    image,
    name,
    description,
    type,
    account,
    onDelete
  } = props;


  return (
    <div style={{ position: 'relative' }}>

      <IconButton
        className={classes.DeleteIcon}
        aria-label="Elimiar cuenta"
        onClick={onDelete}>
        <DeleteIcon />
      </IconButton>

      <Grid
        container
        spacing={40}
        className={classes.GridContainer}>

        <Grid
          item
          xs={3}
          sm={4}
          md={3}>
          <img className={classes.Image} src={image} alt="" />
        </Grid>

        <Grid
          item
          xs={9}
          sm={8}
          md={9}
          className={classes.InfoGridItem}>

          <span
            className={classNames(classes.BankAccountText, classes.Name)}>
            {name}
          </span>
          <span
            className={classNames(classes.BankAccountText, classes.Account)}>
            {account}
          </span>
          <span
            className={classNames(classes.BankAccountText, classes.AccountType)}>
            {type} - {description}
          </span>
        </Grid>
      </Grid>
    </div>
  )
}

export default BankAccount;