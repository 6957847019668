import React from 'react';
import { formatPriceConMoneda,parseDate } from '../../../shared/utility'; 
// material ui core components
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// styles
import classes from './CashListItem.module.css';

const CashListItem = (props) => {

  let color="";
  if(props.payment.fees.length > 1){
    color="red";
  }

  return (
    <Card className={classes.MyPaymentsListItem} onClick={() => props.onClick(props.payment)}>
      <CardContent>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={5} style={{ textAlign: 'center', 'font-size': '23px'  }}>
            Préstamo N°: { props.payment.account}
          </Grid>
          <Grid item xs={7} style={{ paddingLeft: '10px', position: 'relative' }}>
            <div className={classes.Divider}></div>
            <div style={{ marginLeft: '10px' }}>
              <p className={classes.AmountText}>{formatPriceConMoneda(props.payment.amount, props.payment.currency)}</p>
              <p className={classes.Vto} style={{color:color}}>Vencimiento: {parseDate(props.payment.expirationDate)}</p>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CashListItem;