import React, { useEffect, useState, Fragment } from 'react';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import moment from 'moment';
// components
import CardTitle from '../../components/UI/Card/CardTitle/CardTitle';
import Spinner from '../../components/UI/Spinner/Spinner';
import Card from '../../components/UI/Card/Card';
// material ui core components 
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// styles
import classes from './CreditHistory.module.css';
import CreditHistoryList from '../../components/CreditHistory/CreditHistoryList';
import CreditHistoryDetail from '../../components/CreditHistory/CreditHistoryDetail/CreditHistoryDetail';

const CreditHistory = (props) => {

  useEffect(() => {
    props.onFetchCreditHistory(props.token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedCredit, setSelectedCredit] = useState(null)

  const handleShowCreditDetails = (credit) => {
    setSelectedCredit(credit);
  };

  let credit = null;
  if (selectedCredit != null) {
    credit = (
      <Dialog
        open={selectedCredit != null}
        onClose={() => { setSelectedCredit(null) }}
        classes={{
          paper: classes.DialogPaper
        }}
        scroll={'paper'}
      >
        <DialogContent>
          {selectedCredit ?
            <CreditHistoryDetail
              dateStart={selectedCredit.dateStart}
              term={selectedCredit.term}
              ltp={selectedCredit.ltp}
              arrears={selectedCredit.arrears}
              quotas={selectedCredit.quotas}
              idStatus={selectedCredit.idStatus}
              status={selectedCredit.status}
              amount={selectedCredit.capital}
               />
            : null}
        </DialogContent>
      </Dialog>
    );
  }


  let creditHistory = null;
  if (props.loading) {
    creditHistory = <Spinner />;
  } else if (props.credits) {
    creditHistory = {};
    for (const credit of props.credits) {
      const date = moment(credit.dateStart, "DD-MM-YYYY").format("MMMM YYYY");
      if (creditHistory.hasOwnProperty(date)) {
        creditHistory[date].push(credit);
      } else {
        creditHistory[date] = [credit];
      }
    }
    creditHistory = (
      <CreditHistoryList
        credits={creditHistory}
        onClick={handleShowCreditDetails} />
    );
  }

  let errorMessage = null;
  if (props.error) {
    errorMessage = (
      <p className={classes.Error}>
        Se produjo un error al intentar obtener el historial de préstamos
      </p>
    );
  }

  return (
    <Fragment>
      {credit}
      <Card className={classes.Card}>
        <CardTitle title='Historial de préstamos' />
        {creditHistory}
        {errorMessage}
      </Card>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    credits: state.creditHistory.credits,
    loading: state.creditHistory.loading,
    error: state.creditHistory.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchCreditHistory: token => dispatch(actions.fetchCreditHistory(token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditHistory);