import React from 'react';
// material ui core components
import Grid from '@material-ui/core/Grid';
// components
import DebitProviderListItem from './Debit/DebitProviderListItem';
// styles
import classes from './DebitProvidersList.module.css';

const DebitProvidersList = (props) => {
  return (
    <Grid container spacing={24} className={classes.GridContainer}>
      {props.providers.map(provider => {
        return (
          <Grid item xs={6} sm={4} lg={3} key={provider.id} style={{ padding:'5px' }}>
            <DebitProviderListItem
              image={provider.logoImageUrl}
              name={provider.name}
              selected={provider.hasDebit}
              onClick={() => props.onClick(provider)} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default DebitProvidersList;