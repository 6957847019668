import React from 'react';
// components
import Button from '../../../components/UI/CustomButtons/Button';
// material ui components
import DialogContent from '@material-ui/core/DialogContent';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// styles
import classes from './ConfirmDebitRequestDialog.module.css';

const ConfirmDebitRequestDialog = props => {
  const { open, onClose, onConfirmed } = props;
  return (
    <Dialog
      classes={{
        paper: classes.ConfirmationDialogPaperRoot
      }}
      title="CONFIRMAR"
      open={open}
      onClose={onClose}>
      <DialogContent>
        <p className={classes.ConfirmationDialogText}>¿Confirma que desea ingresar el débito?</p>
        <MuiButton
          color="primary"
          className={classes.CancelButton}
          onClick={onClose}>
          Cancelar
      </MuiButton>

        <Button
          color="primary"
          className={classes.ConfirmationDialogButton}
          onClick={onConfirmed}>
          OK
      </Button>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDebitRequestDialog;