import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  news: null,
  unreadNews: 0,
  loading: false
};

const fetchNewsStart = (state, _) => {
  return updateObject(state, { loading: true });
};

const fetchNewsSuccess = (state, action) => {
  return updateObject(state, {
    news: action.news,
    loading: false
  });
};

const fetchNewsFail = (state, _) => {
  return updateObject(state, { loading: false });
};

const setNewsCount = (state, action) => {
  return updateObject(state, {
    unreadNews: action.unreadNews
  })
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NEWS_START: return fetchNewsStart(state, action);
    case actionTypes.FETCH_NEWS_SUCCESS: return fetchNewsSuccess(state, action);
    case actionTypes.FETCH_NEWS_FAIL: return fetchNewsFail(state, action);
    case actionTypes.SET_NEWS_COUNT: return setNewsCount(state, action);
    default: return state;
  }
};

export default reducer;