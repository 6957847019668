import React, { Fragment, useState, useEffect } from 'react';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import classNames from 'classnames';
// components
import BankAccountsList from '../../../components/BankAccounts/BankAccountsList';
import CardTitle from '../../../components/UI/Card/CardTitle/CardTitle';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Card from '../../../components/UI/Card/Card';
import Button from '../../../components/UI/CustomButtons/Button';
import ConfirmDeleteDialog from '../../../components/UI/Dialog/ConfirmRequestDialog/ConfirmRequestDialog';
import InfoDialog from '../../../components/UI/Dialog/IframeDialog/IframeDialog';
// material ui core components
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
// styles
import classes from './MyAccounts.module.css';

const MyAccounts = (props) => {

  const [bankAccountToDelete, setBankAccountToDelete] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  useEffect(() => {
    props.onFetchBankAccounts(props.token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteBankAccount = (id) => {
    setBankAccountToDelete(id);
  }

  const onDeleteBankAccountConfirmed = () => {
    setBankAccountToDelete(null);
    props.onDeleteBankAccount(props.token, bankAccountToDelete);
  }

  const onDeleteCancelled = () => {
    setBankAccountToDelete(null);
  }

  const onResponseDialogClosed = () => {
    props.resetBankAccountDeleted();
    props.onFetchBankAccounts(props.token);
  };

  const showMoreInfo = () => {
    setShowInfoDialog(true);
  };

  const openAddAccounts = () => {
    props.history.push('/agregar-cuenta');
  };

  const {
    loading,
    accounts,
    helpName,
    helpUrl,
    bankAccountDeleted,
    responseCode,
    responseMessage,
    error
  } = props;

  let bankAccounts = null;
  if (loading) {
    bankAccounts = <Spinner />;
  } else if (accounts) {
    bankAccounts = (
      <BankAccountsList
        accounts={accounts}
        onDelete={handleDeleteBankAccount} />
    );
  }

  let errorMessage = null;
  if (error) {
    errorMessage = (
      <p className={classes.ErrorMessage}>
        Se produjo un error al intentar obtener las cuentas bancarias
      </p>
    );
  }

  return (
    <Fragment>
      <Card className={classes.Card}>
        <CardTitle title='Mis Cuentas' />
        {bankAccounts}
        {helpName ?
          <div className={classes.ButtonsContainer}>
            <Button
              className={classNames(classes.Button, classes.InfoButton)}
              onClick={showMoreInfo}>
              {helpName}
            </Button>
            <Button
              color="primary"
              className={classes.Button}
              onClick={openAddAccounts}>
              Agregar cuentas
            </Button>
          </div>
          : null}
        {errorMessage}
      </Card>

      <ConfirmDeleteDialog
        text="¿Estás seguro que deseas eliminar esta cuenta?"
        open={bankAccountToDelete != null}
        onClose={onDeleteCancelled}
        onConfirmed={onDeleteBankAccountConfirmed} />

      <Dialog
        classes={{
          paper: classes.ConfirmationDialogPaperRoot
        }}
        open={
          bankAccountDeleted ||
          (!!responseCode && responseCode !== 0)
        }>
        <DialogContent>
          <p className={classes.ConfirmationDialogText}>
            {responseMessage}
          </p>
          <Button
            color="primary"
            className={classes.ConfirmationDialogButton}
            onClick={onResponseDialogClosed}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>

      {helpName ?
        <InfoDialog
          open={showInfoDialog}
          onClose={() => setShowInfoDialog(false)}
          link={helpUrl}
          onAccept={() => setShowInfoDialog(false)}
        />
        : null}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    accounts: state.bankAccounts.accounts,
    helpName: state.bankAccounts.helpName,
    helpUrl: state.bankAccounts.helpUrl,
    bankAccountDeleted: state.bankAccounts.bankAccountDeleted,
    responseCode: state.bankAccounts.responseCode,
    responseMessage: state.bankAccounts.responseMessage,
    loading: state.bankAccounts.loading,
    error: state.bankAccounts.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchBankAccounts: token => dispatch(actions.fetchBankAccounts(token)),
    onDeleteBankAccount: (token, id) => dispatch(actions.deleteBankAccount(token, id)),
    resetBankAccountDeleted: () => dispatch(actions.resetBankAccountDeleted())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyAccounts);