import React, { Fragment } from 'react';
import classNames from 'classnames';
import { formatPrice } from '../../../shared/utility';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './CreditHistoryDetail.module.css';

const CreditHistoryDetail = (props) => {

  const { idStatus, ltp, term, dateStart, quotas, amount, arrears } = props;

  let debtInfo = null;
  let statusDescription = "";
  if (idStatus === 1) {
    statusDescription = "CRÉDITO APROBADO";
  } else if (ltp === term) {
    statusDescription = "CRÉDITO CANCELADO";
    debtInfo = (
      <Fragment>
        <Grid item xs={6}>
          <p className={classes.DetailTitle}>Última cuota paga</p>
        </Grid>
        <Grid item xs={6}>
          <p className={classes.Value}>{ltp}</p>
        </Grid>
      </Fragment>
    )
  } else {
    statusDescription = "CRÉDITO ENTREGADO";
    debtInfo = (
      <Fragment>
        <Grid item xs={6}>
          <p className={classes.DetailTitle}>Última cuota paga</p>
        </Grid>
        <Grid item xs={6}>
          <p className={classes.Value}>{ltp}</p>
        </Grid>
        {arrears > 0 ?
          <Fragment>
            <Grid item xs={6}>
              <p className={classNames(classes.DetailTitle, classes.Arrears)}>Días de atraso</p>
            </Grid>
            <Grid item xs={6}>
              <p className={classNames(classes.Value, classes.Arrears)}>{arrears}</p>
            </Grid>
          </Fragment>
          : null}
      </Fragment>
    )
  }
  return (
    <div>
      <p className={classes.LoanTitle}>PRÉSTAMO</p>
      <p className={classes.Status}>{statusDescription}</p>

      <Grid container>
        <Grid item xs={6}>
          <p className={classes.DetailTitle}>Fecha de inicio</p>
        </Grid>
        <Grid item xs={6}>
          <p className={classes.Value}>{dateStart.replace(/-/g, "/")}</p>
        </Grid>
        <Grid item xs={6}>
          <p className={classes.DetailTitle}>Cantidad de cuotas</p>
        </Grid>
        <Grid item xs={6}>
          <p className={classes.Value}>{term}</p>
        </Grid>
        <Grid item xs={6}>
          <p className={classes.DetailTitle}>Valor de cuota</p>
        </Grid>
        <Grid item xs={6}>
          <p className={classes.Value}>{formatPrice(quotas)}</p>
        </Grid>
        {debtInfo}
        <Grid item xs={6}>
          <p className={classNames(classes.DetailTitle, classes.TotalAmount)}>Monto total</p>
        </Grid>
        <Grid item xs={6}>
          <p className={classNames(classes.Value, classes.TotalAmount)}>{formatPrice(amount)}</p>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreditHistoryDetail;