import React, { Fragment } from 'react';
import moment from 'moment';
import localization from 'moment/locale/es';
import { formatPrice } from '../../shared/utility';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
// assets
import cardImage from '../../assets/images/creditLine/linea_credito_tarjeta.svg';
import cashImage from '../../assets/images/creditLine/linea_credito_efectivo.svg';
import cardImageMc from '../../assets/images/creditLine/master.png';
// material ui components
import { Grid, Button } from '@material-ui/core';
// components
import Card from '../../components/UI/Card/Card';
import CreditLineHeader from '../../components/CreditLine/CreditLineHeader/CreditLineHeader';
// styles
import classes from './CreditLine.module.css';

const CreditLine = (props) => {

  const handleAccountClick = () => {
    props.history.push('/estado');
    window.scrollTo(0, 0);
  }

  const handleCurrentMovementsClick = () => {
    props.history.push('/movimientos');
    window.scrollTo(0, 0);
  }

  let creditLine = null;
  if (localStorage.getItem('creditLine')) {
    const { cardTotal, cardUsed, CashTotal, CashUsed } = JSON.parse(localStorage.getItem('creditLine'));
    let card = null;
    if (localStorage.getItem('cards')) {
      const cards = JSON.parse(localStorage.getItem('cards'));
      card = cards[0];
    }

    let account = localStorage.getItem('account');
    
    creditLine = (
      <Fragment>
        <div className={classes.Summary}>
          <p>Tu línea de crédito es de:</p>
          <h2>{formatPrice(CashTotal + cardTotal)}</h2>
        </div>
        <CreditLineHeader
          title="Efectivo"
          icon={cashImage}
          limit={""}
          total={CashTotal}
          used={CashUsed}
          available={Number(CashTotal) - Number(CashUsed)} />

        {card ?
          <Fragment>
            <CreditLineHeader
              title="Tarjeta"
              subtitle="Número de Cuenta:"
              subtitleValue={account}
              icon={
                card.brand ==="MASTERCARD" ? cardImageMc :  cardImage
              }
              limit={card.creditLimit}
              total={cardTotal}
              used={cardUsed}
              available={Number(cardTotal) - Number(cardUsed)} />

            <div className={classes.CardDetails}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Button
                    className={classNames(classes.CardButton, classes.AccountStatement)}
                    onClick={handleAccountClick}
                    variant="outlined">
                    Estados de Cuenta
                  </Button>
                  <Button
                    className={classNames(classes.CardButton, classes.CurrentMovements)}
                    onClick={handleCurrentMovementsClick}
                    color="primary"
                    variant="contained">
                    Movimientos actuales
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={6} className={classes.GridItemPadding}>
                      <p className={classes.CardDetailsText}>Fecha de cierre</p>
                      <p className={classNames(classes.CardDetailsValue, classes.CardDetailsValueFirst)}>{moment(card.currentClosingDate, "YYYY-MM-DD").locale("es", localization).format("DD MMMM YYYY")}</p>
                    </Grid>
                    <Grid item xs={6} className={classes.GridItemPadding}>
                      <p className={classes.CardDetailsText}>Fecha de vencimiento</p>
                      <p className={classNames(classes.CardDetailsValue, classes.CardDetailsValueFirst)}>{moment(card.currentPaymentDate, "YYYY-MM-DD").locale("es", localization).format("DD MMMM YYYY")}</p>
                    </Grid>
                    <div className={classes.CardDetailsDivider}></div>
                    <Grid item xs={6} className={classes.GridItemPadding}>
                      <p className={classes.CardDetailsText}>Próximo cierre</p>
                      <p className={classes.CardDetailsValue}>{moment(card.nextClosingDate, "YYYY-MM-DD").locale("es", localization).format("DD MMMM YYYY")}</p>
                    </Grid>
                    <Grid item xs={6} className={classes.GridItemPadding}>
                      <p className={classes.CardDetailsText}>Próximo vencimiento</p>
                      <p className={classes.CardDetailsValue}>{moment(card.nextPaymentDate, "YYYY-MM-DD").locale("es", localization).format("DD MMMM YYYY")}</p>
                    </Grid>
                    <div className={classes.CardDetailsDivider}></div>
                    <Grid item xs={6} className={classes.GridItemPadding}>
                      <p className={classes.CardDetailsText}>Saldo adeudado</p>
                      <p className={classes.CardDetailsValue}>{formatPrice(card.currentFullPayment)}</p>
                    </Grid>
                    <Grid item xs={6} className={classes.GridItemPadding}>
                      <p className={classes.CardDetailsText}>Pago mínimo</p>
                      <p className={classes.CardDetailsValue}>{formatPrice(card.currentMinimumPayment)}</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>  
          </Fragment>
          : null
        }
      </Fragment>
    );
  } else {
    creditLine = (
      <div className={classes.Error}>
        <p>Se produjo un error al intentar obtener su línea de crédito.</p>
        <p>Intente iniciar sesión nuevamente.</p>
      </div>
    );
  }

  return (
    <Card className={classes.Card}>
    <div className={classes.CreditLine}>
      {creditLine}
    </div>
    </Card>
    
  );
};

export default withRouter(CreditLine);
