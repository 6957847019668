import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  providers: null,
  loading: false,
  error: null,
};

const fetchDebitProvidersStart = (state, _) => {
  return updateObject(state, { loading: true });
};

const fetchDebitProvidersSuccess = (state, action) => {
  return updateObject(state, {
    providers: action.providers,
    loading: false,
  });
};

const fetchDebitProvidersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const requestDebitStart = (state, _) => {
  return updateObject(state, { loadingRequest: true });
};

const requestDebitSuccess = (state, action) => {
  return updateObject(state, {
    responseMessageTop: action.responseMessageTop,
    responseMessageBottom: action.responseMessageBottom,
    loadingRequest: false,
    requestDebitError: null
  });
};

const requestDebitFail = (state, action) => {
  return updateObject(state, {
    requestDebitError: action.requestDebitError,
    loadingRequest: false,
  });
};

const resetRequestDebit = (state, _) => {
  return updateObject(state, {
    requestDebitError: null,
    responseMessageTop: null,
    responseMessageBottom: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DEBIT_PROVIDERS_START:
      return fetchDebitProvidersStart(state, action);
    case actionTypes.FETCH_DEBIT_PROVIDERS_SUCCESS:
      return fetchDebitProvidersSuccess(state, action);
    case actionTypes.FETCH_DEBIT_PROVIDERS_FAIL:
      return fetchDebitProvidersFail(state, action);
      
    case actionTypes.REQUEST_DEBIT_START:
      return requestDebitStart(state, action);
    case actionTypes.REQUEST_DEBIT_SUCCESS:
      return requestDebitSuccess(state, action);
    case actionTypes.REQUEST_DEBIT_FAIL:
      return requestDebitFail(state, action);
    case actionTypes.RESET_REQUEST_DEBIT:
      return resetRequestDebit(state, action);
    default:
      return state;
  }
};

export default reducer;
