import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { parseDate } from '../../shared/utility';
// components
import DigitalReceiptsList from '../../components/DigitalReceipts/DigitalReceiptsList';
import DigitalReceipt from '../../components/DigitalReceipts/DigitalReceipt/DigitalReceipt';
import Button from '../../components/UI/CustomButtons/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
// material ui core components
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// styles
import classes from './DigitalReceipts.module.css';

class DigitalReceipts extends Component {

  state = {
    receipt: null,
    lastNumberOfSectionsCount: 0,
    message: ''
  };

  componentDidMount() {
    this.fetchDigitalReceipts(1);
  }

  handleShowReceiptDetails = (receipt) => {
    if (receipt.mostrarDatos) {
      this.setState({ receipt: receipt, message: null });
    } else {
      this.setState({ message: receipt.mensaje, receipt: null });
    }
  };

  handleShowMessageDialogClosed = () => {
    this.setState({ message: null })
  };

  handleShowMore = () => {
    this.fetchDigitalReceipts(this.props.page + 1);
  };

  fetchDigitalReceipts = (page) => {
    this.props.onFetchDigitalRecepits(this.props.token, page);
  }

  render() {

    let messageDialog = null;
    if (this.state.message) {
      messageDialog = (
        <Dialog
          open={this.state.message != null}
          onClose={this.handleShowMessageDialogClosed}
          scroll={'paper'}
        >
          <DialogContent>
            {this.state.message ? <p className={classes.Message}>{this.state.message}</p> : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleShowMessageDialogClosed} color="primary" className={classes.OKButton}>
              OK
          </Button>
          </DialogActions>
        </Dialog>
      );
    }

    let digitalReceipt = null;
    if (this.state.receipt != null) {
      digitalReceipt = (
        <Dialog
          open={this.state.receipt != null}
          onClose={() => { this.setState({ receipt: null }) }}
          classes={{
            paper: classes.DialogPaper
          }}
          scroll={'paper'}
        >
          <DialogContent>
            {this.state.receipt ? <DigitalReceipt receipt={this.state.receipt} /> : null}
          </DialogContent>
        </Dialog>
      );
    }

    let receipts = {};
    if (this.props.receipts) {
      for (const receipt of this.props.receipts) {
        const date = parseDate(receipt.fecha, 'MM/DD/YYYY');
        if (receipts.hasOwnProperty(date)) {
          receipts[date].push(receipt);
        } else {
          receipts[date] = [receipt];
        }
      }
    }
    return (
      <Fragment>
        {digitalReceipt}
        {messageDialog}
        <Card className={classes.Card}>
          <div className={classes.InfoTextContainer}>
            <p className={classes.InfoText}>En caso de que el pago se realice con cheque, quedará sujeto al cobro del mismo.</p>
          </div>
          {this.props.loading ?
            <Spinner />
            :
            receipts ?
              <Fragment>
                <DigitalReceiptsList
                  receipts={receipts}
                  onReceiptClick={this.handleShowReceiptDetails}
                />
                {this.props.showMore ?
                  <Button
                    className={classes.ShowMoreButton}
                    color="primary"
                    onClick={this.handleShowMore}>
                    Mostrar Más
              </Button>
                  : null}
              </Fragment>
              : null}
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    receipts: state.digitalReceipts.receipts,
    page: state.digitalReceipts.page,
    showMore: state.digitalReceipts.showMore,
    error: state.digitalReceipts.error,
    loading: state.digitalReceipts.loading,
    token: state.auth.token
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchDigitalRecepits: (token, page) => dispatch(actions.fetchDigitalReceipts(token, page)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DigitalReceipts);