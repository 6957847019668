import React, { useEffect } from 'react';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
// components
import Card from '../../../components/UI/Card/Card';
import Spinner from '../../../components/UI/Spinner/Spinner';
// styles
import classes from './CreditelShop.module.css';

const CreditelShop = props => {
  useEffect(() => {
    props.onFetchShop(props.match.params.shopId, props.token)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.shop) {
    let tokenParam = `?tokenTienda=${props.shop.tokenTienda}`;
    
    /*if (props.match.params.shopId === '2') {
      tokenParam = `?${props.shop.tokenCreditickets}`;
    }*/

    return (
      <Card className={classes.SelectedShopCard}>
        <div
          className={classes.SelectedShopContainer}
          dangerouslySetInnerHTML={{
            __html: `<iframe style='border:none;' src="${props.shop.apiUrl +
              tokenParam}" width='100%' height='100%'></iframe>`,
          }}
        />
      </Card>
    );
  } else if (props.error) {
    return (
      <p className={classes.Error}>
        Se produjo un error al intentar acceder a la tienda seleccionada
      </p>
    );
  }

  return <Spinner />
};

const mapStateToProps = state => {
  return {
    shop: state.shop.shop,
    error: state.shop.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchShop: (shopId, token) => dispatch(actions.fetchShop(shopId, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditelShop);
