import React from 'react';
import classNames from 'classnames';
// material ui core components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// material ui icons
import CheckCircle from '@material-ui/icons/CheckCircle';
// styles
import classes from './DebitProviderListItem.module.css';

const DebitProviderListItem = (props) => {
  const { selected, image, name, onClick } = props;

  let selectedClass = null;
  if (selected) {
    selectedClass = classes.DebitProviderSelected;
  }
  return (
    <Card
      className={classNames(classes.DebitProviderListItem, selectedClass)} onClick={onClick}>
      <CardContent className={classes.CardContent}>
        {selected ? <CheckCircle className={classes.CheckIcon} /> : null}
        <img className={classes.DebitProviderImage} src={image} alt="" />
        <p className={classes.Name}>{name}</p>
      </CardContent>
    </Card>
  )
}

export default DebitProviderListItem;