import React from 'react';
import { parseDate } from '../../../shared/utility';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './DigitalReceiptListItem.module.css';

const DigitalReceiptsListItem = (props) => (
  <div className={classes.DigitalReceiptsListItem} onClick={() => props.onClick(props.receipt)}>
    <Grid container>
      <Grid item xs={8}>
        <p className={classes.Type}>{props.receipt.tipoRecibo === "Tarjeta" ? "TARJETA DE CRÉDITO" : "PRÉSTAMO EN EFECTIVO"}</p>
        <p className={classes.DateText}>{parseDate(props.receipt.fecha, "DD/MM/YYYY")}</p>
      </Grid>
      <Grid item xs={4}>
        <p className={classes.AmountText}>{props.receipt.moneda === "2222" ? "U$S" : "$"} {props.receipt.total}</p>
      </Grid>
    </Grid>
    <div className={classes.Divider}></div>
  </div>
);

export default DigitalReceiptsListItem;