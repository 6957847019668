import React from 'react';
// components 
import CustomTabLabel from './CustomTabLabel';
// material ui core components
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
// styles
import classes from './CustomTabs.module.css';

const CustomTabs = (props) => {
  return (
    <div>
      <Tabs
        value={props.value}
        onChange={props.onChange}
        indicatorColor="transparent"
        textColor="primary"
        centered
        className={classes.Root}
      >
        {props.tabs.map((tab) => {
          return (
            <Tab
              key={tab.label}
              classes={{
                root: classes.TabsRoot,
                selected: classes.TabSelected
              }}
              label={
                <CustomTabLabel
                  label={tab.label}
                  img={tab.img}
                  imgSize={tab.imgSize} />
              } />
          )
        })}
      </Tabs>
      {props.children}
    </div>
  )
}

export default CustomTabs;