import React, { useEffect, useState } from 'react';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
// components
import Card from '../../../../components/UI/Card/Card';
import CardTitle from '../../../../components/UI/Card/CardTitle/CardTitle';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import Requirements from '../../../../components/Requirements/Requirements';
import Button from '../../../../components/UI/CustomButtons/Button';
import ConfirmAddBankAccountDialog from '../../../../components/UI/Dialog/ConfirmRequestDialog/ConfirmRequestDialog';
import InfoDialog from '../../../../components/UI/Dialog/IframeDialog/IframeDialog';
// material ui core components
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MuiButton from '@material-ui/core/Button';
// styles
import classes from './AddAccount.module.css';


const AddAccount = props => {

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [isPrimaryAccount, setPrimaryAccount] = useState(false);
  const [showPrimaryAccountConfirmation, setShowPrimaryAccountConfirmation] = useState(false);


  useEffect(() => {
    props.onFetchBankRequirements(
      props.token,
      props.match.params.bankId
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestBankAccount = () => {
    const reqValues = Object.keys(formValues).map(req => {
      return {
        value: formValues[req],
        name: req
      };
    });

    props.onRequestBankAccount(
      props.token,
      props.match.params.bankId,
      reqValues,
      isPrimaryAccount
    );
  };

  const handleResponseDialogClosed = () => {
    const responseCode = props.responseCode;
    props.resetBankAccountRequested();
    if (responseCode === 0) {
      props.history.push('/mis-cuentas');
    }
  };

  const handleShowMoreInfo = () => {
    setShowInfoDialog(true);
  };

  const handlePrimaryAccountChange = event => {
    if (event.target.checked) {
      setShowPrimaryAccountConfirmation(true);
    } else {
      setPrimaryAccount(false);
    }
  };

  const handleConfirmPrimaryAccountClosed = () => {
    setShowPrimaryAccountConfirmation(false);
  };

  const handlePrimaryAccountConfirmed = () => {
    setPrimaryAccount(true);
    handleConfirmPrimaryAccountClosed();
  };

  const {
    loading,
    requirements,
    box
  } = props;

  const {
    bank
  } = props.location.state;

  let requirementsEl = (
    <p className={classes.EmptyState}>
      No se pudieron obtener los requerimientos para el banco seleccionado.
    </p>
  );
  if (loading) {
    requirementsEl = <Spinner />;
  } else if (requirements) {
    requirementsEl = (
      <div className={classes.RequirementsContainer}>
        <Requirements
          requirements={requirements}
          box={box}
          onClick={values => {
            setFormValues(values);
            setShowConfirmationDialog(true);
          }}
          showConfirmButton={true}
          confirmButtonText="Agregar"
          showInfoButton={true}
          infoButtonText={bank.info}
          onShowMoreInfo={handleShowMoreInfo}>

          <FormControlLabel
            control={
              <Switch
                checked={isPrimaryAccount}
                onChange={handlePrimaryAccountChange}
                name="Cuenta primaria"
                color="primary" />
            }
            label="Marcar como cuenta primaria"
          />

        </Requirements>
      </div>
    );
  }

  let dialogTitle = 'Cuenta agregada correctamente';
  if (props.responseCode !== 0) {
    dialogTitle = 'Ocurrió un error';
  }

  return (
    <Card className={classes.AddAccount}>
      <CardTitle
        className={classes.CardTitle}
        title="Agregar Cuenta"
      />

      <div className={classes.InsuranceImageContainer}>
        <img className={classes.InsuranceImage} alt="" src={bank.img} />
      </div>

      {requirementsEl}

      <ConfirmAddBankAccountDialog
        text="¿Confirma que desea agregar la cuenta?"
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirmed={() => {
          setShowConfirmationDialog(false);
          requestBankAccount();
        }}
      />

      <Dialog
        classes={{
          paper: classes.ConfirmationDialogPaperRoot
        }}
        title={dialogTitle}
        open={
          props.bankAccountRequested ||
          (!!props.responseCode && props.responseCode !== 0)
        }
      >
        <DialogContent>
          <p className={classes.ConfirmationDialogText}>
            {props.responseMessage}
          </p>
          <Button
            color="primary"
            className={classes.ConfirmationDialogButton}
            onClick={handleResponseDialogClosed}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showPrimaryAccountConfirmation}
        classes={{
          paper: classes.PrimaryConfirmationDialogPaperRoot
        }}
      >
        <DialogTitle>
          Marcar cuenta como primaria
        </DialogTitle>
        <DialogContent>
          <p className={classes.ConfirmationDialogText}>
            Si marcas esta cuenta como primaria, la cuenta que ya tengas ingresada como primaria pasará a ser secundaria.
          </p>
          <div className={classes.DialogButtonsContainer}>
            <MuiButton
              color="primary"
              className={classes.PrimaryConfirmationDialogButton}
              onClick={handleConfirmPrimaryAccountClosed}
            >
              Cancelar
            </MuiButton>
            <Button
              color="primary"
              className={classes.PrimaryConfirmationDialogButton}
              onClick={handlePrimaryAccountConfirmed}
            >
              OK
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <InfoDialog
        open={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
        link={bank.infoUrl}
        onAccept={() => setShowInfoDialog(false)}
      />
    </Card>
  );
}

const mapStateToProps = state => {
  return {
    requirements: state.bankAccounts.requirements,
    box: state.bankAccounts.box,
    bankAccountRequested: state.bankAccounts.bankAccountRequested,
    responseCode: state.bankAccounts.responseCode,
    responseMessage: state.bankAccounts.responseMessage,
    loading: state.bankAccounts.loading,
    error: state.bankAccounts.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchBankRequirements: (token, bankId) => dispatch(actions.fetchBankRequirements(token, bankId)),
    onRequestBankAccount: (token, bankId, values, primaryAccount) => dispatch(actions.requestBankAccount(token, bankId, values, primaryAccount)),
    resetBankAccountRequested: () => dispatch(actions.resetBankAccountRequested())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAccount);