/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React, { Fragment } from 'react';
// components
import Button from '../../UI/CustomButtons/Button';
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
// material ui icons
import Logout from '@material-ui/icons/ExitToApp';
// styles
import headerLinkStyle from '../../../assets/jss/components/headerLinkStyle';

class HeaderLinks extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Button
          style={{ float: 'right'}}
          color={"transparent"}
          aria-label="Cerrar sesión"
          className={classes.buttonLink}
          onClick={this.props.onLogout}
        >
          <p className={classes.linkText}>Cerrar Sesión</p>
          <Logout style={{marginLeft: '10px', marginBottom: '2px'}} className={classes.icons} />
          
        </Button>
      </Fragment>
    );
  }
}

export default withStyles(headerLinkStyle)(HeaderLinks);
