import React, { useEffect, Fragment } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as currentMovementsActions from '../../../store/actions';
// components
import Card from '../../../components/UI/Card/Card';
import CardTitle from '../../../components/UI/Card/CardTitle/CardTitle';
import Spinner from '../../../components/UI/Spinner/Spinner';
// material ui components
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';

// styles
import myClasses from './CurrentMovements.module.css';
import tableStyle from '../../../assets/jss/components/tableStyle';


const tableHeaders = ['Detalle', 'Fecha',  'Importe', 'Cuotas'];
const tableRows = ['description', 'date',  '', ''];

const CurrentMovements = ({ classes, ...props }) => {
  useEffect(() => {
    props.onFetchMovements(props.token, props.document);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let currentMovements = null;

  if (props.loading) {
    currentMovements = (
      <div style={{ marginTop: '50px', marginBottom: '50px' }}>
        <Spinner />
      </div>
    );
  } else if (props.movements) {
    if (props.movements.length === 0) {
      currentMovements = <p className={myClasses.EmptyState}>No se encontraron movimientos.</p>;
    } else {
      const rows = props.movements.map((movement, index) => (
        <TableRow key={index}>
          {tableRows.map((row, index) => (
            <TableCell
              key={index}
              className={
                classNames(
                  classes.tableCell,
                  classes.tableHeadCell
                )}>
              {
                index === tableRows.length - 2 ? `${movement.currency} ${movement.installmentAmount.toLocaleString('es-ES')}` : 
                index === tableRows.length - 1 ? (movement.installmentNumber === 0 ? (movement.pending === 1 ? "En proceso":"") 
                : `${movement.installmentNumber} / ${movement.term}`) : movement[row]
              }
            </TableCell>
          ))}
        </TableRow>
      ));

      currentMovements = (
        <Table style={{ marginTop: '25px', overflowX: 'auto' }} className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeaders.map(header => (
                <TableCell
                  key={header}
                  className={classNames(classes.tableCell, classes.tableHeadCell)}
                  align="right"
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      );
    }
  } else if (props.error) {
    currentMovements = (
      <p className={myClasses.EmptyState}>Ocurrió un error al intentar obtener los movimientos.</p>
    );
  }

  return (
    <Fragment>
      <Card className={classNames(myClasses.Card, classes.tableResponsive)}>
        <CardTitle title="Movimientos Actuales" />
        {currentMovements}
      </Card>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    document: state.auth.document,
    movements: state.movements.movements,
    loading: state.movements.loading,
    error: state.movements.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchMovements: (token, document) => dispatch(currentMovementsActions.fetchMovements(token, document)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(tableStyle)(CurrentMovements));
