import React, { Component } from 'react';
import classNames from 'classnames';
// material ui core components
import Button from '@material-ui/core/Button';
// styles
import classes from '../Auth.module.css';

class CheckUserRegistration extends Component {

  render() {
    return (
      <form onSubmit={this.props.onSubmit} noValidate autoComplete="off" className={classes.Form}>
        <p className={classes.InsertCodeText}>Ingrese el número de validación recibido por SMS.</p>
        <div>
          {Object.keys(this.props.controls).map((key, index) => {
            const control = this.props.controls[key];
            return (
              <input
                key={index}
                ref={index}
                className={classNames(
                  control.value && control.value.length > 0 ? classes.InsertCodeInputActive : null,
                  classes.InsertCodeInput)}
                value={control.value}
                onChange={(event) => {
                  this.props.onInputChanged(event.target.value, index, "codeControls");
                  if (event.target.value) {
                    let next = this.refs[index].nextSibling;

                    if (next && next.tagName === "INPUT") {
                      this.refs[index].nextSibling.focus();
                    }
                  }
                }}
                type="text"
                maxLength="1"
                autoFocus={index === 0}
              />
            )
          })}
        </div>
        <Button
          className={classes.SubmitButton}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!this.props.formIsValid}
        >
          Enviar
      </Button>
      </form>
    )
  }
}

export default CheckUserRegistration;