import React, { Fragment } from 'react';
import { monthNames } from '../../shared/utility';
// components
import DigitalReceiptsListItem from './DigitalReceiptListItem/DigitalReceiptListItem';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './DigitalReceiptsList.module.css';

const DigitalReceiptsList = (props) => {

  const sortedDates = Object.keys(props.receipts).sort(function (a, b) {
    return new Date(b) - new Date(a);
  });
  return (
    <div>
      <div>
        <Grid container>
          {sortedDates.length > 0 ? 
          sortedDates.map((date) => {
            let dateObject = new Date(date);
            const year = dateObject.getFullYear();
            const month = monthNames[dateObject.getMonth()];
            const fullDate = month + " " + year;
            return (
              <Fragment key={date}>
                <Grid item xs={12}>
                  <p className={classes.MonthYearTitle}>{fullDate}</p>
                </Grid>
                {props.receipts[date].map((receipt, index) => {
                  return (
                    <Grid item xs={12} md={4} key={index} style={{ padding:'5px' }}>
                      <DigitalReceiptsListItem
                        receipt={receipt}
                        onClick={props.onReceiptClick}
                      />
                    </Grid>
                  )
                })}
              </Fragment>
            )
          })
          :
          <p className={classes.EmptyState}>No tiene recibos digitales</p>
        }
        </Grid>
      </div>
    </div>
  );
}

export default DigitalReceiptsList;