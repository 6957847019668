import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { updateObject, obviousPasswords } from '../../shared/utility';
import { checkValidity } from '../../shared/validation';
// components
import Spinner from '../../components/UI/Spinner/Spinner';
import CheckUserRegistration from './Signup/CheckUserRegistration';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import Login from './Login/Login';
import Signup from './Signup/Signup';
// material ui core components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
// styles
import classes from './Auth.module.css';
import UpdatePassword from './UpdatePassword/UpdatePassword';

import creditelLogo from  '../../assets/images/creditel_logo.svg';


class Auth extends Component {
  
  state = {
    controls: {
      loginControls: {
        document: {
          elementType: 'input',
          elementConfig: {
            type: 'tel',
            placeholder: 'Documento (CI)',
            fullwidth: true,
          },
          value: '',
          validation: {
            required: true,
            isDocument: true,
            maxLength: 8,
            minLength: 4
          },
          error: null,
          valid: false,
          touched: false
        },
        password: {
          elementType: 'input',
          elementConfig: {
            type: 'password',
            placeholder: 'Contraseña',
            fullwidth: true,
            onClickShowPassword: () => this.handleClickShowPassword("password", "loginControls"),
            onMouseDownPassword: this.handleMouseDownPassword
          },
          showPassword: false,
          value: '',
          validation: {
            required: true
          },
          error: null,
          valid: false,
          touched: false
        }
      },
      signupControls: {
        document: {
          elementType: 'input',
          elementConfig: {
            type: 'tel',
            placeholder: 'Usuario (CI)',
            fullwidth: true
          },
          value: '',
          validation: {
            required: true,
            isDocument: true,
            maxLength: 8,
            minLength: 4
          },
          error: null,
          valid: false,
          touched: false
        },
        phone: {
          elementType: 'input',
          elementConfig: {
            type: 'tel',
            placeholder: 'Celular',
            fullwidth: true
          },
          value: '',
          validation: {
            required: true,
            isPhone: true,
            maxLength: 9,
            minLength: 9
          },
          error: null,
          valid: false,
          touched: false
        },
        password: {
          elementType: 'input',
          elementConfig: {
            type: 'password',
            placeholder: 'Contraseña',
            fullwidth: true,
            onClickShowPassword: () => this.handleClickShowPassword("password", "signupControls"),
            onMouseDownPassword: this.handleMouseDownPassword
          },
          showPassword: false,
          value: '',
          validation: {
            required: true,
            minLength: 8,
            notIn: obviousPasswords,
            isAlphaNumeric: true
          },
          error: null,
          valid: false,
          touched: false
        },
        repeatPassword: {
          elementType: 'input',
          elementConfig: {
            type: 'password',
            placeholder: 'Repetir contraseña',
            fullwidth: true,
            onClickShowPassword: () => this.handleClickShowPassword("repeatPassword", "signupControls"),
            onMouseDownPassword: this.handleMouseDownPassword
          },
          showPassword: false,
          value: '',
          validation: {
            required: true,
            minLength: 8,
            notIn: obviousPasswords,
            isAlphaNumeric: true
          },
          error: null,
          valid: false,
          touched: false
        }
      },
      codeControls: [...Array(5)].map(key => {
        return {
          elementType: 'input',
          elementConfig: {
            type: 'number',
            placeholder: 'Código',
            fullwidth: true
          },
          value: '',
          validation: {
            required: true
          },
          error: null,
          valid: false,
          touched: false
        };
      }),
      updatePasswordControls: {
        currentPassword: {
          elementType: 'input',
          elementConfig: {
            type: 'password',
            placeholder: 'Contraseña actual',
            fullwidth: true,
            onClickShowPassword: () => this.handleClickShowPassword("currentPassword", "updatePasswordControls"),
            onMouseDownPassword: this.handleMouseDownPassword
          },
          showPassword: false,
          value: '',
          validation: {
            required: true
          },
          error: null,
          valid: false,
          touched: false
        },
        newPassword: {
          elementType: 'input',
          elementConfig: {
            type: 'password',
            placeholder: 'Nueva contraseña',
            fullwidth: true,
            onClickShowPassword: () => this.handleClickShowPassword("newPassword", "updatePasswordControls"),
            onMouseDownPassword: this.handleMouseDownPassword
          },
          showPassword: false,
          value: '',
          validation: {
            required: true,
            minLength: 8,
            isAlphaNumeric: true
          },
          error: null,
          valid: false,
          touched: false
        },
        repeatNewPassword: {
          elementType: 'input',
          elementConfig: {
            type: 'password',
            placeholder: 'Repetir nueva contraseña',
            fullwidth: true,
            onClickShowPassword: () => this.handleClickShowPassword("repeatNewPassword", "updatePasswordControls"),
            onMouseDownPassword: this.handleMouseDownPassword
          },
          showPassword: false,
          value: '',
          validation: {
            required: true,
            minLength: 8,
            isAlphaNumeric: true
          },
          error: null,
          valid: false,
          touched: false
        }
      }
    },
    isSignup: false,
    termsAccepted: false,
    forgotPassword: false
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.forceUpdatePassword && this.props.forceUpdatePassword) {
      this.setState({ formIsValid: false });
    }
  }

  handleClickShowPassword = (controlName, formName) => {
    const showPassword = !this.state.controls[formName][controlName].showPassword;

    let updatedControls = updateObject(this.state.controls, {
      [formName]: updateObject(this.state.controls[formName], {
        [controlName]: updateObject(this.state.controls[formName][controlName], {
          ...this.state.controls[formName][controlName],
          elementConfig: {
            ...this.state.controls[formName][controlName].elementConfig,
            type: showPassword ? 'text' : 'password'
          },
          showPassword
        })
      })
    });

    this.setState({ controls: updatedControls });
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  inputChangedHandler = (value, controlName, formName) => {
    let [valid, error] = checkValidity(
      value,
      this.state.controls[formName][controlName].validation
    );

    if (controlName === 'repeatNewPassword') {
      const isValid = this.state.controls[formName].newPassword.value === value;
      valid = valid && isValid;
      error = !valid && !error ? 'Las contraseñas deben coincidir' : error;
    }

    let updatedControls = updateObject(this.state.controls, {
      [formName]: updateObject(this.state.controls[formName], {
        [controlName]: updateObject(
          this.state.controls[formName][controlName],
          {
            ...this.state.controls[formName][controlName],
            value: value,
            valid: valid,
            touched: true,
            error: error
          }
        )
      })
    });

    if (controlName === 'newPassword') {
      const passwordsMatch =
        this.state.controls[formName].repeatNewPassword.value === value;
      updatedControls = updateObject(updatedControls, {
        [formName]: updateObject(updatedControls[formName], {
          repeatNewPassword: updateObject(
            updatedControls[formName].repeatNewPassword,
            {
              ...updatedControls[formName].repeatNewPassword,
              valid: passwordsMatch,
              error: !passwordsMatch ? 'Las contraseñas deben coincidir' : null
            }
          )
        })
      });
    }

    this.checkIfFormIsValid(updatedControls[formName]);
    this.setState({ controls: updatedControls });
  };

  checkIfFormIsValid = controls => {
    let formIsValid = true;
    for (let inputIdentifier in controls) {
      formIsValid = controls[inputIdentifier].valid && formIsValid;
    }
    this.setState({ formIsValid: formIsValid });
  };

  switchAuthModeHandler = () => {
    this.setState(prevState => {
      let isSignup = !prevState.isSignup && !prevState.forgotPassword;
      let controls = null;
      if (isSignup || this.state.forgotPassword) {
        if (this.props.userRegistered && !this.props.userRegistrationChecked) {
          controls = this.state.controls.codeControls;
        } else {
          controls = this.state.controls.signupControls;
        }
      } else {
        controls = this.state.controls.loginControls;
      }
      let formIsValid = true;
      for (let inputIdentifier in controls) {
        formIsValid = controls[inputIdentifier].valid && formIsValid;
      }
      this.props.resetAuthProps();
      return { isSignup, formIsValid, forgotPassword: false };
    });
  };

  forgotPasswordHandler = () => {
    this.setState(prevState => {
      return {
        forgotPassword: !prevState.forgotPassword
      };
    });
  };

  updatePasswordHandler = event => {
    event.preventDefault();
    this.props.onUpdatePassword(
      this.props.token,
      this.state.controls.updatePasswordControls.currentPassword.value,
      this.state.controls.updatePasswordControls.newPassword.value,
      this.state.controls.updatePasswordControls.repeatNewPassword.value
    );
  };

  submitHandler = event => {
    event.preventDefault();
    if (!this.state.isSignup && !this.state.forgotPassword) {
      this.props.onLogin(
        this.state.controls.loginControls.document.value,
        this.state.controls.loginControls.password.value
      );
    } else if (
      this.props.userRegistered &&
      !this.props.userRegistrationChecked
    ) {
      let code = '';
      for (let codeInput in this.state.controls.codeControls) {
        code += this.state.controls.codeControls[codeInput].value;
      }
      this.props.onCheckUserRegistration(
        code,
        this.state.controls.signupControls.document.value,
        this.state.controls.signupControls.password.value
      );
    } else {
      this.props.onSignup(
        this.state.controls.signupControls.document.value,
        this.state.controls.signupControls.phone.value,
        this.state.controls.signupControls.password.value,
        this.state.controls.signupControls.repeatPassword.value,
        this.state.forgotPassword
      );
      this.checkIfFormIsValid(this.state.controls.codeControls);
    }
  };

  handleTermsAndConditions(accepted) {
    this.setState({ termsAccepted: accepted });
    if (accepted) {
      this.props.onTermsAndConditionsAccepted();
    }
  }

  handleLogoClick() {
    window.open(
      process.env.REACT_APP_PUBLIC_SECTION_BASE_URL,
      '_self'
    );
  }

  render() {
    let dialog = null;
    if (this.props.terms && !this.props.termsAccepted) {
      dialog = (
        <TermsAndConditions
          termsAccepted={this.state.termsAccepted}
          onTermsAndConditionsResponse={this.handleTermsAndConditions}
        />
      );
    }

    let errorMessage = null;
    const message = this.props.error || this.props.updatePasswordError;
    if (!this.props.loading && message) {
      errorMessage = <p className={classes.ErrorMessage}>{message}</p>;
    }

    let form = null;
    let showSwitchButton = true;
    if (this.props.loading ||  this.props.loadingPassword) {
      form = <Spinner />;
      showSwitchButton = false;
    } else {
      if (this.props.forceUpdatePassword) {
        showSwitchButton = false;
        form = (
          <UpdatePassword
            controls={this.state.controls.updatePasswordControls}
            onSubmit={this.updatePasswordHandler}
            onInputChanged={this.inputChangedHandler}
            formIsValid={this.state.formIsValid}
            onLogout={this.props.onLogout}
            responseMessage={this.props.responseMessage}
            updatePasswordError={this.props.updatePasswordError}
          />
        );
      } else {
        form = (
          <Login
            controls={this.state.controls.loginControls}
            onSubmit={this.submitHandler}
            onForgotPasswordClick={this.forgotPasswordHandler}
            onInputChanged={this.inputChangedHandler}
            formIsValid={this.state.formIsValid}
          />
        );
      }

      if (this.state.forgotPassword || this.state.isSignup) {
        if (!this.props.userRegistered) {
          form = (
            <Signup
              controls={this.state.controls.signupControls}
              onSubmit={this.submitHandler}
              onInputChanged={this.inputChangedHandler}
              formIsValid={this.state.formIsValid}
              forgotPassword={this.state.forgotPassword}
            />
          );
        } else if (
          this.props.userRegistered &&
          !this.props.userRegistrationChecked
        ) {
          form = (
            <CheckUserRegistration
              controls={this.state.controls.codeControls}
              onSubmit={this.submitHandler}
              onInputChanged={this.inputChangedHandler}
              formIsValid={this.state.formIsValid}
            />
          );
        }
      }
    }
    let authRedirect = null;
    const isAuthenticated = localStorage.getItem("token") != null
    if (
      isAuthenticated &&
      !this.props.terms &&
      !this.props.forceUpdatePassword
    ) {
      authRedirect = <Redirect to="/linea-de-credito" />;
    }

    return (
      <div className={classes.Auth}>
        {authRedirect}
        {dialog}
        <div className={classes.AuthFormContainer}>
          <div className={classes.AuthFormSubContainer}>
            <img
              className={classes.Logo}
              src={creditelLogo}
              alt="Creditel logo"
              onClick={this.handleLogoClick}
            />
            <Card className={classes.AuthForm}>
              { this.state.isSignup || this.state.forgotPassword ? null :
               <h1 className={classes.Welcome}>Bienvenido</h1>}
              
              { this.state.isSignup ?
               <h2 className={classes.Registro}>Registrarme</h2> : null}

              {form}
              {showSwitchButton ? (
                <div>
                  { this.state.isSignup || this.state.forgotPassword ? (
                  <Button onClick={this.switchAuthModeHandler}>Ingresar</Button>) :
                  (
                  <div>
                    <p>¿No tenés cuenta?</p>
                    <Button onClick={this.switchAuthModeHandler}>Registrarme</Button>
                  </div>
                )} 
                </div>
              ) : null}
              {errorMessage}
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    forceUpdatePassword: state.auth.forceUpdatePassword,
    terms: state.auth.terms,
    termsAccepted: state.auth.termsAccepted,
    userRegistered: state.auth.userRegistered,
    userRegistrationChecked: state.auth.userRegistrationChecked,
    loadingPassword: state.auth.loadingPassword,
    updatePasswordError: state.auth.updatePasswordError,
    responseMessage: state.auth.responseMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (document, password) =>
      dispatch(actions.login(document, password)),
    onSignup: (document, phone, password, repeatPassword, forgotPassword) =>
      dispatch(
        actions.signup(
          document,
          phone,
          password,
          repeatPassword,
          forgotPassword
        )
      ),
    onUpdatePassword: (
      token,
      currentPassword,
      newPassword,
      newPasswordRepeated
    ) =>
      dispatch(
        actions.authUpdatePassword(
          token,
          currentPassword,
          newPassword,
          newPasswordRepeated
        )
      ),
    onCheckUserRegistration: (code, document, password) =>
      dispatch(actions.checkRegistration(code, document, password)),
    onTermsAndConditionsAccepted: () =>
      dispatch(actions.postTermsAndConditionsAccepted()),
    resetAuthProps: () => dispatch(actions.resetAuthProps()),
    onLogout: () => dispatch(actions.logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
