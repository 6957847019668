import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import {
  updateObject,
  isSmallOrExtraSmallScreen
} from '../../shared/utility';
import { checkValidity } from '../../shared/validation';
// component
import Card from '../../components/UI/Card/Card';
import CardBody from '../../components/UI/Card/CardBody';
import CardFooter from '../../components/UI/Card/CardFooter';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/CustomButtons/Button';
import CardTitle from '../../components/UI/Card/CardTitle/CardTitle';
// material ui core components
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
// styles
import classes from './Account.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';

//íconos
import iconPerfil  from '../../assets/images/profile/CreditelWeb_Profile.svg'
import iconEmail  from '../../assets/images/profile/CreditelWeb_Email.svg'
import iconPhone  from '../../assets/images/profile/CreditelWeb_Phone.svg'

const DATA_CONTROLS_FORM_NAME = 'dataControls';
const PASSWORD_CONTROLS_FORM_NAME = 'passwordControls';

class Account extends Component {
  state = {
    dataControls: {
      cellphone: {
        elementType: 'input',
        elementConfig: {
          type: 'tel',
          placeholder: 'Celular',
          mdUpSize: 12,
          fullwidth: true
        },
        value: localStorage.getItem('phone'),
        validation: {
          required: true,
          isPhone: true,
          maxLength: 9,
          minLength: 9
        },
        error: null,
        valid: true,
        touched: false
      },
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'Email',
          mdUpSize: 12,
          fullwidth: true
        },
        value: localStorage.getItem('email'),
        validation: {
          required: true,
          isEmail: true
        },
        error: null,
        valid: true,
        touched: false
      }
    },
    passwordControls: {
      currentPassword: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Contraseña Actual',
          mdUpSize: 12,
          fullwidth: true,
          onClickShowPassword: () => this.handleClickShowPassword("currentPassword"),
          onMouseDownPassword: this.handleMouseDownPassword
        },
        showPassword: false,
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      newPassword: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Contraseña Nueva',
          mdUpSize: 6,
          fullwidth: true,
          onClickShowPassword: () => this.handleClickShowPassword("newPassword"),
          onMouseDownPassword: this.handleMouseDownPassword
        },
        showPassword: false,
        value: '',
        validation: {
          required: true,
          minLength: 8,
          isAlphaNumeric: true
        },
        valid: false,
        touched: false
      },
      repeatNewPassword: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Repetir Contraseña',
          mdUpSize: 6,
          fullwidth: true,
          onClickShowPassword: () => this.handleClickShowPassword("repeatNewPassword"),
          onMouseDownPassword: this.handleMouseDownPassword
        },
        showPassword: false,
        value: '',
        validation: {
          required: true,
          minLength: 8,
          isAlphaNumeric: true
        },
        valid: false,
        touched: false
      }
    },
    dataFormIsValid: false,
    passwordFormIsValid: false,
    isSignup: false,
    profileImageContainerWidth: 200
  };

  constructor(props) {
    super(props);
    this.profileImageContainer = React.createRef();
  }

  componentDidMount() {
    this.props.resetForms();

    this.calculateProfileImageContainerHeight();
    window.addEventListener(
      'resize',
      this.calculateProfileImageContainerHeight
    );
  }

  calculateProfileImageContainerHeight = () => {
    // gets the current width of the profile image container to calculate the height to make it square
    let profileImageContainerHeight = this.profileImageContainer.current
      .offsetHeight;
    this.setState({ profileImageContainerWidth: profileImageContainerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.calculateProfileImageContainerHeight
    );
  }

  handleClickShowPassword = (controlName) => {
    const showPassword = !this.state.passwordControls[controlName].showPassword;

    let updatedControls = updateObject(this.state.passwordControls, {
      [controlName]: updateObject(this.state.passwordControls[controlName], {
        ...this.state.passwordControls[controlName],
        elementConfig: {
          ...this.state.passwordControls[controlName].elementConfig,
          type: showPassword ? 'text' : 'password'
        },
        showPassword
      })
    });

    this.setState({ passwordControls: updatedControls });
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  inputChangedHandler = (value, controlName, formType) => {
    let [valid, error] = checkValidity(
      value,
      this.state[formType][controlName].validation
    );

    let updatedControls = {};

    // Check if repeatNewPassword is the same as newPassword
    if (controlName === 'repeatNewPassword') {
      valid = valid && this.state.passwordControls.newPassword.value === value;
      error = !valid && !error ? 'Las contraseñas deben coincidir' : error;
    } else if (controlName === 'newPassword') {
      const passwordsMatch =
        this.state.passwordControls.repeatNewPassword.value === value;
      updatedControls = {
        repeatNewPassword: {
          ...this.state.passwordControls.repeatNewPassword,
          valid: passwordsMatch,
          error: !passwordsMatch ? 'Las contraseñas deben coincidir' : null
        }
      };
    }

    updatedControls = updateObject(this.state[formType], {
      [controlName]: updateObject(this.state[formType][controlName], {
        ...this.state[formType][controlName],
        value: value,
        valid: valid,
        touched: true,
        error
      }),
      ...updatedControls
    });

    this.setState({ [formType]: updatedControls });
    this.checkIfFormIsValid(updatedControls, formType);
  };

  checkIfFormIsValid = (controls, formType) => {
    let formIsValid = true;
    for (let inputIdentifier in controls) {
      formIsValid = controls[inputIdentifier].valid && formIsValid;
    }
    if (formType === DATA_CONTROLS_FORM_NAME) {
      this.setState({ dataFormIsValid: formIsValid });
    } else {
      this.setState({ passwordFormIsValid: formIsValid });
    }
  };

  createInputElements = formType => {
    return Object.keys(this.state[formType]).map(key => {
      let authElement = this.state[formType][key];
      return (
        <Grid item xs={12} md={authElement.elementConfig.mdUpSize} key={key}>
          <div
            style={{
              width: authElement.elementConfig.mdUpSize === 6 ? '82%' : '90%',
              margin: '0 auto'
            }}
          >
            <Input
              id={key}
              elementType={authElement.elementType}
              elementConfig={authElement.elementConfig}
              value={authElement.value}
              validation={authElement.validation}
              valid={authElement.valid}
              touched={authElement.touched}
              error={authElement.error}
              showPassword={authElement.showPassword}
              onChange={event =>
                this.inputChangedHandler(event.target.value, key, formType)
              }
            />
          </div>
        </Grid>
      );
    });
  };

  handleUpdateDataSubmit = () => {
    this.props.onUpdateData(
      this.props.token,
      this.state.dataControls.cellphone.value,
      this.state.dataControls.email.value
    );
  };

  handleUpdatePasswordSubmit = () => {
    this.props.onUpdatePassword(
      this.props.token,
      this.state.passwordControls.currentPassword.value,
      this.state.passwordControls.newPassword.value,
      this.state.passwordControls.repeatNewPassword.value
    );

    const updatedControls = updateObject(this.state.passwordControls, {
      currentPassword: updateObject(
        this.state.passwordControls.currentPassword,
        {
          ...this.state.passwordControls.currentPassword,
          value: '',
          valid: true,
          touched: false
        }
      ),
      newPassword: updateObject(this.state.passwordControls.newPassword, {
        ...this.state.passwordControls.newPassword,
        value: '',
        valid: true,
        touched: false
      }),
      repeatNewPassword: updateObject(
        this.state.passwordControls.repeatNewPassword,
        {
          ...this.state.passwordControls.repeatNewPassword,
          value: '',
          valid: true,
          touched: false
        }
      )
    });
    this.setState({ passwordControls: updatedControls });
  };

  render() {
    /*let dataFeedback = null;
    if (this.props.dataUpdated) {
      dataFeedback = 'Sus datos han sido modificados';
    } else if (this.props.updateDataError) {
      dataFeedback ='Ha ocurrido un error al modificar sus datos. Por favor intente nuevamente más tarde.';
    }
/*
    let dataForm = (
      <Grid item xs={12} sm={12} lg={6}>
        <Card className={classes.Card}>
          <CardTitle title={'Modificar Datos'} />
          {this.props.loadingData ? (
            <Spinner />
          ) : (
            <Fragment>
              <CardBody className={classes.CardBody}>
                <Grid container>
                  {this.createInputElements(DATA_CONTROLS_FORM_NAME)}
                </Grid>
              </CardBody>
              <CardFooter className={classes.CardFooter}>
                <div className={classes.CardFooterContainer}>
                  <Button
                    color="primary"
                    className={classes.UpdateButton}
                    onClick={this.handleUpdateDataSubmit}
                    disabled={!this.state.dataFormIsValid}
                  >
                    Modificar
                  </Button>
                </div>
              </CardFooter>
            </Fragment>
          )}
          <p className={classes.Feedback}>{dataFeedback}</p>
        </Card>
      </Grid>
    );
*/
    let cardProfileStyle = {};
    if (!isSmallOrExtraSmallScreen(this.props.width)) {
      cardProfileStyle = {
        padding: '15px 8px 8px 200px',
        paddingLeft: this.state.profileImageContainerWidth
      };
    }
    let personalData = (
      <Grid item xs={12} sm={7} md={11} lg={7}>
        <div className={classes.PersonalDataContainer}>
          <div className={classes.PersonInfoContainer}>
            <div
              className={classes.ClientProfileImageContainer}
              ref={this.profileImageContainer}
              style={{ width: this.state.profileImageContainerWidth }}
            >
              <img
                className={classes.ProfileImage}
                src={iconPerfil}
                alt="Ícono perfil"
              />
              <p className={classes.PersonName}>
                {localStorage.getItem('fullName')}
              </p>
            </div>
            <Card profile className={classes.Card} style={cardProfileStyle}>
              <CardBody className={classes.ProfileInfoCardBody}>
                <Grid container alignItems="baseline">
                  <Grid item xs={2} className={classes.PersonInfoItemContainer}>
                    <img
                      width="20px"
                      src={iconPhone}
                      alt="Ícono teléfono"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    className={classes.PersonInfoItemContainer}
                  >
                    <span className={classes.PersonInfoText}>
                      {localStorage.getItem('phone')}
                    </span>
                  </Grid>
                  <Grid item xs={2} className={classes.PersonInfoItemContainer}>
                    <img
                      width="20px"
                      src={iconEmail}
                      alt="Ícono email"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    className={classes.PersonInfoItemContainer}
                  >
                    <span className={classes.PersonInfoText}>
                      {localStorage.getItem('email')}
                    </span>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </div>
        </div>
      </Grid>
    );

    let passwordFeedback = null;
    if (this.props.passwordUpdated) {
      passwordFeedback = 'Su contraseña ha sido actualizada';
    } else if (this.props.updatePasswordError) {
      passwordFeedback = this.props.updatePasswordError;
    }

    let passwordForm = (
      <Grid item xs={12} sm={12} lg={6}>
        <Card className={classes.Card}>
          <CardTitle title={'Modificar Contraseña'} />
          {this.props.loadingPassword ? (
            <Spinner />
          ) : (
            <Fragment>
              <CardBody className={classes.CardBody}>
                <Grid container>
                  {this.createInputElements(PASSWORD_CONTROLS_FORM_NAME)}
                </Grid>
              </CardBody>
              <CardFooter className={classes.CardFooter}>
                <Button
                  color="primary"
                  className={classes.UpdateButton}
                  onClick={this.handleUpdatePasswordSubmit}
                  disabled={!this.state.passwordFormIsValid}
                >
                  Modificar
                </Button>
              </CardFooter>
            </Fragment>
          )}
          <p className={classes.Feedback}>{passwordFeedback}</p>
        </Card>
      </Grid>
    );

    return (
      <div className={classes.Account}>
        {this.props.error}
        <Grid container spacing={40} alignItems="center">
          {personalData}
          {passwordForm}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loadingData: state.account.loadingData,
    loadingPassword: state.account.loadingPassword,
    updateDataError: state.account.updateDataError,
    updatePasswordError: state.account.updatePasswordError,
    dataUpdated: state.account.dataUpdated,
    passwordUpdated: state.account.passwordUpdated,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateData: (token, phone, email) =>
      dispatch(actions.updateData(token, phone, email)),
    onUpdatePassword: (
      token,
      currentPassword,
      newPassword,
      newPasswordRepeated
    ) =>
      dispatch(
        actions.accountUpdatePassword(
          token,
          currentPassword,
          newPassword,
          newPasswordRepeated
        )
      ),
    resetForms: () => dispatch(actions.resetAccountForms())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(Account));
