import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

export const fetchCreditHistorySuccess = (credits) => {
  return {
    type: actionTypes.FETCH_CREDIT_HISTORY_SUCCESS,
    credits
  }
};

export const fetchCreditHistoryFail = (error) => {
  return {
    type: actionTypes.FETCH_CREDIT_HISTORY_FAIL,
    error: error
  }
};

export const fetchCreditHistoryStart = () => {
  return {
    type: actionTypes.FETCH_CREDIT_HISTORY_START
  }
};

export const fetchCreditHistory = (token, page) => {
  let body = {
    operation: "getCreditHistory",
    sessionToken: token,
    channel: "WEB",
    channelId: "2"
  };
  return (dispatch) => {
    dispatch(fetchCreditHistoryStart());
    axios.post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchCreditHistoryFail(response.data.responseMessage));
        } else {
          dispatch(fetchCreditHistorySuccess(response.data.credits));
        }
      })
      .catch(error => {
        dispatch(fetchCreditHistoryFail(error));
      });
  };
};