import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  cardPayments: null,
  cashPayments: null,
  loading: false,
  error: null,
};

const fetchMyCardPaymentsStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchMyCardPaymentsSuccess = (state, action) => {
  return updateObject(state, {
    cardPayments: action.cardPayments,
    loading: false,
  });
};

const fetchMyCardPaymentsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const fetchMyCashPaymentsStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchMyCashPaymentsSuccess = (state, action) => {
  return updateObject(state, {
    cashPayments: action.cashPayments,
    loading: false,
  });
};

const fetchMyCashPaymentsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_CARD_PAYMENTS_START:
      return fetchMyCardPaymentsStart(state, action);
    case actionTypes.FETCH_MY_CARD_PAYMENTS_SUCCESS:
      return fetchMyCardPaymentsSuccess(state, action);
    case actionTypes.FETCH_MY_CARD_PAYMENTS_FAIL:
      return fetchMyCardPaymentsFail(state, action);

    case actionTypes.FETCH_MY_CASH_PAYMENTS_START:
      return fetchMyCashPaymentsStart(state, action);
    case actionTypes.FETCH_MY_CASH_PAYMENTS_SUCCESS:
      return fetchMyCashPaymentsSuccess(state, action);
    case actionTypes.FETCH_MY_CASH_PAYMENTS_FAIL:
      return fetchMyCashPaymentsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
