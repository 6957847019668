import React, { Fragment } from 'react';
import classNames from 'classnames';
import { formatPriceConMoneda } from '../../../shared/utility';
// material ui core components
import Grid from '@material-ui/core/Grid';
// material ui icons
import Calendar from '@material-ui/icons/CalendarToday';
import Domain from '@material-ui/icons/Domain';
import Person from '@material-ui/icons/Person';
import Info from '@material-ui/icons/Info';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Assessment from '@material-ui/icons/Assessment';
// styles
import classes from './MyPaymentsDetail.module.css';

const MyPaymentsDetail = (props) => {

  let paymentTypeLogo = null;
  let imageAlternate = ""
  if (props.payment.subsidiary.toUpperCase() === "BANRED") {
    paymentTypeLogo = require("../../../assets/images/myPayments/logo_banred.png");
    imageAlternate = "Banred";
  } else if (props.payment.subsidiary.toUpperCase() === "REDPAGOS") {
    paymentTypeLogo = require("../../../assets/images/myPayments/logo_redpagos.png");
    imageAlternate = "Redpagos";
  }  else if (props.payment.subsidiary.toUpperCase() === "PAGANZA") {
    paymentTypeLogo = require("../../../assets/images/myPayments/logo_paganza.png");
    imageAlternate = "Paganza";
  }else {
    paymentTypeLogo = require("../../../assets/images/myPayments/logo_creditel.png");
    imageAlternate = "Creditel";
  }
  return (
    <div style={{ padding: '30px', width: '300px' }}>
      <Grid container alignItems="center">
        <Grid item xs={12} className={classes.ImageContainer}>
          <img alt={imageAlternate} src={paymentTypeLogo} style={{ maxHeight: '70px', maxWidth: '240px' }}  />
        </Grid>

        <Grid item xs={1} className={classes.GridItem}>
          <Calendar className={classes.Icon} />
        </Grid>
        <Grid item xs={6} className={classNames(classes.FieldTitle, classes.GridItem)}>
          <span className={classes.PaymentFieldText}>Fecha de Ingreso </span>
        </Grid>
        <Grid item xs={5} className={classes.GridItem}>
          <span className={classes.PaymentFieldValue}>{props.payment.date}</span>
        </Grid>

        <Grid item xs={1} className={classes.GridItem}>
          <Domain className={classes.Icon} />
        </Grid>
        <Grid item xs={6} className={classNames(classes.FieldTitle, classes.GridItem)}>
          <span className={classes.PaymentFieldText}>Sucursal </span>
        </Grid>
        <Grid item xs={5} className={classes.GridItem}>
          <span className={classes.PaymentFieldValue}>{props.payment.subsidiary}</span>
        </Grid>

        <Grid item xs={1} className={classes.GridItem}>
          <Person className={classes.Icon} />
        </Grid>
        <Grid item xs={6} className={classNames(classes.FieldTitle, classes.GridItem)}>
          <span className={classes.PaymentFieldText}>Atendido por </span>
        </Grid>
        <Grid item xs={5} className={classes.GridItem}>
          <span className={classes.PaymentFieldValue}>{props.card ? props.payment.user : props.payment.executive}</span>
        </Grid>

        <Grid item xs={1} className={classes.GridItem}>
          <Assessment className={classes.Icon} />
        </Grid>
        <Grid item xs={6} className={classNames(classes.FieldTitle, classes.GridItem)}>
          <span className={classes.PaymentFieldText}>Estado actual </span>
        </Grid>
        <Grid item xs={5} className={classes.GridItem}>
          <span className={classes.PaymentFieldValue}>{props.payment.cancelled === 0 ? "CONFIRMADO" : "ANULADO"}</span>
        </Grid>

        {!props.card ?
          <Fragment>
            <Grid item xs={1} className={classes.GridItem}>
              <Info className={classes.Icon} />
            </Grid>
            <Grid item xs={6} className={classNames(classes.FieldTitle, classes.GridItem)}>
              <span className={classes.PaymentFieldText}>Préstamo Nº </span>
            </Grid>
            <Grid item xs={5} className={classes.GridItem}>
              <span className={classes.PaymentFieldValue}>{props.payment.loanNumber}</span>
            </Grid>
          </Fragment>
          : null}

        <Grid item xs={1} className={classes.GridItem}>
          <AttachMoney className={classes.Icon} />
        </Grid>
        <Grid item xs={6} className={classNames(classes.FieldTitle, classes.GridItem)}>
          <span className={classes.PaymentFieldText}>Importe </span>
        </Grid>
        <Grid item xs={5} className={classes.GridItem}>
          <span className={classes.PaymentFieldValue}>{formatPriceConMoneda(props.payment.payment, props.payment.moneda)}</span>
        </Grid>

      </Grid>
    </div>
  )
}

export default MyPaymentsDetail;