import axios from '../../creditel-axios-private';
import { hashPassword } from '../../shared/hashing';

// generic
export const updatePasswordRequest = (token, currentPassword, newPassword, newPasswordRepeated) => {
  let body = {
    operation: 'changePassword',
    oldPass: hashPassword(currentPassword),
    newPass: hashPassword(newPassword),
    newPassConfirm: hashPassword(newPasswordRepeated),
    sessionToken: token,
    channel: 'WEB'
  };
  return new Promise((resolve, reject) => {
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          reject(response.data.responseMessage)
        } else {
          resolve();
        }
      })
      .catch(error => {
        reject(error);
      });
  })
};