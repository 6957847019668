import React from 'react';
import PropTypes from 'prop-types';
// material ui core components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

const CustomDialog = (props) => {
  return (
    <Dialog
      scroll="paper"
      open={props.open}
      onClose={props.onClose}
    >
      {props.title ?
        <DialogTitle>
          {props.title}
        </DialogTitle>
        : null}
      {props.children}
    </Dialog>
  );
}

CustomDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default CustomDialog;