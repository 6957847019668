import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  accounts: null,
  helpName: null,
  helpUrl: null,
  banks: null,
  loading: false
};

const fetchBankAccountsStart = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const fetchBankAccountsSuccess = (state, action) => {
  return updateObject(state, {
    accounts: action.accounts,
    helpName: action.helpName,
    helpUrl: action.helpUrl,
    loading: false
  });
};

const fetchBankAccountsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true
  });
};

const fetchBanksStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchBanksSuccess = (state, action) => {
  return updateObject(state, {
    banks: action.banks,
    loading: false
  });
};

const fetchBanksFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true
  });
};

const fetchBankRequirementsStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchBankRequirementsSuccess = (state, action) => {
  return updateObject(state, {
    requirements: action.requirements,
    box: action.box,
    loading: false
  });
};

const fetchBankRequirementsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true
  });
};



const requestBankAccountStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const requestBankAccountSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    bankAccountRequested: true,
    responseCode: action.responseCode,
    responseMessage: action.responseMessage,
  });
};

const requestBankAccountFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    responseCode: action.responseCode,
    responseMessage: action.responseMessage
  });
};

const resetBankAccountRequested = (state, _) => {
  return updateObject(state, {
    bankAccountRequested: false,
    responseCode: null,
    responseMessage: null
  });
};



const deleteBankAccountStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteBankAccountSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    bankAccountDeleted: true,
    responseCode: action.responseCode,
    responseMessage: action.responseMessage
  });
};

const deleteBankAccountFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    responseCode: action.responseCode,
    responseMessage: action.responseMessage
  });
};

const resetBankAccountDeleted = (state, _) => {
  return updateObject(state, {
    bankAccountDeleted: false,
    responseCode: null,
    responseMessage: null
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BANK_ACCOUNTS_START: return fetchBankAccountsStart(state, action);
    case actionTypes.FETCH_BANK_ACCOUNTS_SUCCESS: return fetchBankAccountsSuccess(state, action);
    case actionTypes.FETCH_BANK_ACCOUNTS_FAIL: return fetchBankAccountsFail(state, action);

    case actionTypes.FETCH_BANKS_START: return fetchBanksStart(state, action);
    case actionTypes.FETCH_BANKS_SUCCESS: return fetchBanksSuccess(state, action);
    case actionTypes.FETCH_BANKS_FAIL: return fetchBanksFail(state, action);

    case actionTypes.FETCH_BANK_REQUIREMENTS_START: return fetchBankRequirementsStart(state, action);
    case actionTypes.FETCH_BANK_REQUIREMENTS_SUCCESS: return fetchBankRequirementsSuccess(state, action);
    case actionTypes.FETCH_BANK_REQUIREMENTS_FAIL: return fetchBankRequirementsFail(state, action);

    case actionTypes.REQUEST_BANK_ACCOUNT_START: return requestBankAccountStart(state, action);
    case actionTypes.REQUEST_BANK_ACCOUNT_SUCCESS: return requestBankAccountSuccess(state, action);
    case actionTypes.REQUEST_BANK_ACCOUNT_FAIL: return requestBankAccountFail(state, action);

    case actionTypes.DELETE_BANK_ACCOUNT_START: return deleteBankAccountStart(state, action);
    case actionTypes.DELETE_BANK_ACCOUNT_SUCCESS: return deleteBankAccountSuccess(state, action);
    case actionTypes.DELETE_BANK_ACCOUNT_FAIL: return deleteBankAccountFail(state, action);

    case actionTypes.RESET_BANK_ACCOUNT_REQUESTED: return resetBankAccountRequested(state, action);
    case actionTypes.RESET_BANK_ACCOUNT_DELETED: return resetBankAccountDeleted(state, action);

    default: return state;
  }
};

export default reducer;