import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';
import { hashPassword } from '../../shared/hashing';
import Fingerprint2 from 'fingerprintjs2';
import { setNewsCount } from './news'
import { updatePasswordRequest } from './generic';

export const LOGOUT_OPERATION = 'logoutV2';

export const FastRegistrationType = {
  signup: 1,
  forgotPassword: 2
}

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
}

// login

export const loginSuccess = (token, document) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token: token,
    document: document
  };
};

export const loginFail = (error) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error: error
  };
};

export const login = (document, password) => {
  return dispatch => {
    dispatch(authStart());

    getUniqueDeviceId((deviceId) => {

      const body = {
        operation: "loginWeb",
        document,
        password: hashPassword(password),
        deviceId: deviceId,
        web: "true",
        channel: "WEB"
      };
      axios.post('/', body)
        .then(response => {

          if (response.data.termCondition) {
            dispatch(termsConditionsRequired(response.data.termCondition));
          }
          const token = response.data.sessionToken;
          if (token) {
            localStorage.setItem('token', response.data.sessionToken);
            localStorage.setItem('document', document);
            localStorage.setItem('phone', response.data.phone);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('fullName', response.data.fullName);
            localStorage.setItem('account', response.data.account);
            localStorage.setItem('tokenOut', response.data.tokenOut);
            localStorage.setItem('creditLine', JSON.stringify(response.data.creditLine));
            localStorage.setItem('cards', JSON.stringify(response.data.cards));
            const expirationDate = new Date(new Date().getTime() + response.data.sessionTokenExpiration * 60000);
            localStorage.setItem('expirationDate', expirationDate);

            dispatch(setNewsCount(response.data.notifications));
            dispatch(loginSuccess(response.data.sessionToken, document));
            dispatch(checkAuthTimeout(response.data.sessionTokenExpiration * 60000));
          } else {
            return dispatch(loginFail(response.data.responseMessage));
          }
        })
        .catch(err => {
          let error = null;
          if (typeof err === 'object') {
            error = err.message;
          }
          dispatch(loginFail(error));
        });
    });
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout(false));
    }, expirationTime);
  };
};

export const termsConditionsRequired = (terms) => {
  return {
    type: actionTypes.TERMS_CONDITIONS_REQUIRED,
    terms: terms
  }
};

const getUniqueDeviceId = async callback => {
  if (window.requestIdleCallback) {
    requestIdleCallback(function () {
      getFingerprint(callback);
    });
  } else {
    setTimeout(function () {
      getFingerprint(callback);
    }, 500);
  }
};

const getFingerprint = callback => {
  Fingerprint2.get(function (components) {
    var values = components.map(function (component) { return component.value });
    var hash = Fingerprint2.x64hash128(values.join(''), 31);
    callback(hash);
  });
};

// signup

export const signupSuccess = () => {
  return {
    type: actionTypes.SIGNUP_SUCCESS
  };
};

export const signupFail = (error) => {
  return {
    type: actionTypes.SIGNUP_FAIL,
    error: error
  };
};

export const signup = (document, phone, password, repeatPassword, forgotPassword) => {
  return dispatch => {
    if (password !== repeatPassword) {
      dispatch(signupFail("Las contraseñas deben coincidir"))
    } else {
      dispatch(authStart());
      const body = {
        operation: "userFastRegistration",
        phone,
        document,
        type: forgotPassword ? FastRegistrationType.forgotPassword : FastRegistrationType.signup,
        password: hashPassword(password),
        channel: "web"
      };
      axios.post('/', body)
        .then(response => {
          if (response.data.responseCode !== 0) {
            dispatch(signupFail(response.data.responseMessage));
          } else {
            dispatch(signupSuccess());
          }
        })
        .catch(err => {
          dispatch(signupFail(err));
        })
    }
  };
};

// check registration

export const checkRegistrationStart = () => {
  return {
    type: actionTypes.CHECK_REGISTRATION_START,
    userRegistrationChecked: false,
    error: null
  };
};

export const checkRegistrationSuccess = (token, document) => {
  return {
    type: actionTypes.CHECK_REGISTRATION_SUCCESS,
    userRegistrationChecked: true,
    token: token,
    document: document
  };
};

export const checkRegistrationFail = (error) => {
  return {
    type: actionTypes.CHECK_REGISTRATION_FAIL,
    error: error
  };
};

export const checkRegistration = (code, document, password, type) => {
  return dispatch => {
    dispatch(checkRegistrationStart());

    getUniqueDeviceId((deviceId) => {

      const body = {
        operation: "userCheckRegistrationWeb",
        code: code,
        document: document,
        password: hashPassword(password),
        deviceId: deviceId,
        web: "true",
        channel: "WEB"
      };
      axios.post('/', body)
        .then(response => {
          if (response.data.responseCode !== 0) {
            dispatch(checkRegistrationFail(response.data.responseMessage));
          } else {
            if (response.data.terminosCondiciones) {
              dispatch(termsConditionsRequired(response.data.terminosCondiciones));
            }
            const token = response.data.sessionToken;
            if (token) {
              localStorage.setItem('token', response.data.sessionToken);
              localStorage.setItem('document', document);
              localStorage.setItem('phone', response.data.phone);
              localStorage.setItem('email', response.data.email);
              localStorage.setItem('fullName', response.data.fullName);
              localStorage.setItem('account', response.data.account);
              localStorage.setItem('tokenOut', response.data.tokenOut);
              localStorage.setItem('creditLine', JSON.stringify(response.data.creditLine));
              localStorage.setItem('cards', JSON.stringify(response.data.cards));
              const expirationDate = new Date(new Date().getTime() + response.data.sessionTokenExpiration * 60000);
              localStorage.setItem('expirationDate', expirationDate);

              dispatch(setNewsCount(response.data.notifications));
              dispatch(checkRegistrationSuccess(response.data.sessionToken, document));
            } else {
              return dispatch(checkRegistrationFail(response.data.responseMessage));
            }
          }
        })
        .catch(err => {
          dispatch(checkRegistrationFail(err));
        });
    });
  };
};

// logout

export const logout = (fromService, responseMessage) => {

  return dispatch => {

    dispatch(authStart());
    if (fromService) {
      const body = {
        operation: LOGOUT_OPERATION,
        tokenOut: localStorage.getItem('tokenOut'),
        channel: "WEB"
      };
      axios.post('/', body)
        .then(response => {

          if (response.data.responseCode !== 0) {
            dispatch(logoutFail());
          } else {
            localLogout();
            dispatch(logoutSuccess());
          }
        })
        .catch(err => {
          let error = null;
          if (typeof err === 'object') {
            error = err.message;
          }
          dispatch(logoutFail(error));
        });
    } else {
      localLogout();
      dispatch(forceLogout(responseMessage));
    }
  }
};

const localLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('document');
  localStorage.removeItem('phone');
  localStorage.removeItem('email');
  localStorage.removeItem('fullName');
  localStorage.removeItem('account');
  localStorage.removeItem('tokenOut');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('cards');
  localStorage.removeItem('creditLine');
  localStorage.removeItem('notifications');
 // window.location.reload();
}

export const logoutFail = (error) => {
  return {
    type: actionTypes.LOGOUT_FAIL,
    error: error
  };
};

export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS
  };
};

export const forceLogout = (error) => {
  return {
    type: actionTypes.FORCE_LOGOUT,
    error
  };
};

// terms and conditions accepted

export const postTermsAndConditionsAcceptedStart = () => {
  return {
    type: actionTypes.POST_TERMS_CONDITIONS_ACCEPTED_START
  };
};

export const postTermsAndConditionsAcceptedSuccess = () => {
  return {
    type: actionTypes.POST_TERMS_CONDITIONS_ACCEPTED_SUCCESS
  };
};

export const postTermsAndConditionsAcceptedFail = () => {
  return {
    type: actionTypes.POST_TERMS_CONDITIONS_ACCEPTED_FAIL
  };
};

export const postTermsAndConditionsAccepted = () => {
  return dispatch => {
    dispatch(postTermsAndConditionsAcceptedStart());
    const body = {
      operation: "postCondicionesUso",
      sessionToken: localStorage.getItem('token'),
      channel: "WEB"
    };
    axios.post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(postTermsAndConditionsAcceptedFail(response.data.responseMessage));
        } else {
          dispatch(postTermsAndConditionsAcceptedSuccess(response));
        }
      })
      .catch(err => {
        dispatch(postTermsAndConditionsAcceptedFail(err));
      })
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout(false));
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        dispatch(logout(false));
      } else {
        const document = localStorage.getItem('document');
        dispatch(loginSuccess(token, document));
        dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime())));
      }
    }
  };
};

export const resetAuthProps = () => {
  return {
    type: actionTypes.RESET_AUTH_PROPS
  };
};

export const forceUpdatePassword = (responseMessage) => {
  return {
    type: actionTypes.FORCE_UPDATE_PASSWORD,
    responseMessage
  }
};

export const updatePasswordSuccess = () => {
  return {
    type: actionTypes.UPDATE_PASSWORD_AUTH_SUCCESS
  }
};

export const updatePasswordFail = (error) => {
  return {
    type: actionTypes.UPDATE_PASSWORD_AUTH_FAIL,
    updatePasswordError: error
  }
};

export const updatePasswordStart = () => {
  return {
    type: actionTypes.UPDATE_PASSWORD_AUTH_START
  }
};

export const authUpdatePassword = (token, currentPassword, newPassword, newPasswordRepeated) => {
  return dispatch => {
    dispatch(updatePasswordStart());
    updatePasswordRequest(token, currentPassword, newPassword, newPasswordRepeated)
      .then(() => dispatch(updatePasswordSuccess()))
      .catch(error => dispatch(updatePasswordFail(error)))
  }
};