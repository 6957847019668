import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
// components
import Spinner from '../../../components/UI/Spinner/Spinner';
import DebitRequestSuccessDialog from '../../../components/Debits/DebitRequestSuccessDialog/DebitRequestSuccessDialog';
import AddDebitForm from '../../../components/Debits/AddDebitForm/AddDebitForm';
// styles
import classes from './AddDebit.module.css';

const AddDebit = props => {

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const changeConfirmationDialogVisibility = (visible) => {
    setShowConfirmationDialog(visible);
  }

  const handleRequestDebitConfirmation = (values) => {
    setShowConfirmationDialog(false);
    let fields = [];
    for (let value of Object.keys(values)) {
      fields.push(values[value]);
    }
    props.onRequestDebit(props.token, props.debitId, fields);
  }

  let errorMessage = null;
  if (props.error) {
    errorMessage = (
      <p className={classes.ErrorMessage}>
        {props.error}
      </p>
    );
  }

  let form = null;
  if (props.loading) {
    form = (
      <div className={classes.SpinnerContainer}>
        <Spinner />
      </div>
    );
  } else {
    form = (
      <div className={classes.AddDebit}>
        <p className={classes.Description}>Ingrese la información del débito de acuerdo con la imagen</p>

        <AddDebitForm
          fields={props.fields}
          helpImage={props.helpImage}
          openConfirmationDialog={showConfirmationDialog && props.responseMessageTop == null && props.responseMessageBottom == null}
          onClick={() => changeConfirmationDialogVisibility(true)}
          onConfirmationDialogClose={() => changeConfirmationDialogVisibility(false)}
          onConfirmed={handleRequestDebitConfirmation} />

        {errorMessage}
        <DebitRequestSuccessDialog
          open={props.responseMessageTop != null || props.responseMessageBottom != null}
          onClose={props.onSuccess}
          responseMessageTop={props.responseMessageTop}
          responseMessageBottom={props.responseMessageBottom} />
      </div>
    );
  }

  return form;
}

const mapStateToProps = state => {
  return {
    responseMessageTop: state.debits.responseMessageTop,
    responseMessageBottom: state.debits.responseMessageBottom,
    error: state.debits.requestDebitError,
    loading: state.debits.loadingRequest,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRequestDebit: (token, debitProviderId, fields) => dispatch(actions.requestDebit(token, debitProviderId, fields))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDebit);