import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

export const fetchStmTripsSuccess = (stmTrips) => {
  return {
    type: actionTypes.FETCH_STM_TRIPS_SUCCESS,
    stmTrips: stmTrips
  }
};

export const fetchStmTripsFail = (error) => {
  return {
    type: actionTypes.FETCH_STM_TRIPS_FAIL,
    error: error
  }
};

export const fetchStmTripsStart = () => {
  return {
    type: actionTypes.FETCH_STM_TRIPS_START
  }
};

export const fetchStmTrips = (token, date) => {
  let body = {
    operation: "getSTM",
    sessionToken: token,
    date: date,
    web: "true",
    channel: "WEB"
  };
  return dispatch => {
    dispatch(fetchStmTripsStart());
    axios.post('/', body)
      .then(response => {
        dispatch(fetchStmTripsSuccess(response.data.stmHistoric));
      })
      .catch(error => {
        dispatch(fetchStmTripsFail(error));
      });
  };
};