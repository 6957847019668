/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux'
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { isSmallOrExtraSmallScreen } from '../../../shared/utility';
import { routeIsEnabledForAccount } from '../../../shared/check-account-routes';
import * as actions from '../../../store/actions'
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// material ui icons
import Menu from '@material-ui/icons/Menu';
// styles
import sidebarStyle from '../../../assets/jss/components/sidebarStyle.jsx';
import myClasses from './FixedSideDrawer.module.css';

const FixedSideDrawer = ({ ...props }) => {

  useEffect(() => {
    if (props.sideDrawerOpen) {
      document.body.style.overflow = 'hidden';
    }
    return function cleanup() {
      document.body.style.overflow = 'unset';
    };
  });

  const { classes, routes, logo, subMenus, onSubMenuToggle,
    sideDrawerOpen, onSideDrawerClose, onSideDrawerToggle } = props;

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        const screenIsSmallOrExtraSmall = isSmallOrExtraSmallScreen(props.width)
        if ((prop.onlyResponsive && !screenIsSmallOrExtraSmall) || prop.redirect || !prop.sidebarName || !routeIsEnabledForAccount(prop)) return null;

        let pulsing = false;
        let badge = null;
        if (prop.path === '/novedades') {
          pulsing = props.unreadNews > 0;
          badge = props.unreadNews;
        }

        // Check if is a route or a sub-menu
        if (prop.routes) {
          return (
            <Fragment key={key}>
              <ListItem
                button
                className={classNames([classes.itemLink, myClasses.ItemLink])}
                onClick={() => onSubMenuToggle(prop.key)}
              >
                {prop.icon ? (
                  <ListItemIcon className={classes.itemIcon}>
                    {typeof prop.icon === "string" ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                  </ListItemIcon>
                ) : null}
                <ListItemText
                  primary={prop.sidebarName}
                  className={classes.itemText}
                  disableTypography={true}
                />
                {subMenus[prop.key] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={subMenus[prop.key]}
                timeout="auto"
              >
                <List component="div" disablePadding>
                  {prop.routes.map((child, childKey) => {
                    if (!routeIsEnabledForAccount(child)) return null;
                    return (
                      <NavLink
                        to={child.path}
                        className={classNames([classes.item, myClasses.ChildItem])}
                        onClick={onSideDrawerClose}
                        activeClassName={myClasses.Active}
                        key={childKey}
                      >
                        <ListItem button className={classes.itemLink}>
                          {prop.icon ? (
                            <ListItemIcon className={classes.itemIcon}>
                              {typeof prop.icon === "string" ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                            </ListItemIcon>
                          ) : null}
                          <ListItemText
                            primary={child.sidebarName}
                            className={classNames([classes.itemText, myClasses.ItemText])}
                            disableTypography={true}
                          />
                        </ListItem>
                      </NavLink>
                    )
                  })}
                </List>
              </Collapse>
            </Fragment>
          )
        } else {
          return (
            <NavLink
              to={prop.path}
              className={classes.item}
              onClick={onSideDrawerClose}
              activeClassName={myClasses.Active}
              key={key}
            >
              <ListItem button className={classes.itemLink}>
                <div className={pulsing ? myClasses.Pulse : null}></div>
                {prop.icon ? (
                  <ListItemIcon className={classes.itemIcon}>
                    {typeof prop.icon === "string" ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                  </ListItemIcon>
                ) : null}
                <ListItemText
                  primary={prop.sidebarName}
                  className={classNames([classes.itemText, myClasses.ItemText])}
                  disableTypography={true}
                />
                {badge > 0 ?
                  <div className={myClasses.BadgeContainer}><span className={myClasses.Badge}>{badge}</span></div>
                  : null
                }
              </ListItem>
            </NavLink>
          );
        }
      })}
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_PUBLIC_SECTION_BASE_URL} className={classes.logoLink}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden lgUp implementation="css">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onSideDrawerToggle}
        >
          <Menu />
        </IconButton>
        <Drawer
          anchor="left"
          variant="temporary"
          color="primary"
          classes={{
            paper: classes.drawerPaper
          }}
          open={sideDrawerOpen}
          onClose={onSideDrawerClose}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
        </Drawer>
      </Hidden>
    </div>
  );
};

FixedSideDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    subMenus: state.menu.subMenus,
    sideDrawerOpen: state.menu.sideDrawerOpen,
    unreadNews: state.news.unreadNews
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubMenuToggle: (subMenu) => dispatch(actions.toggleSubMenu(subMenu)),
    onSideDrawerToggle: () => dispatch(actions.toggleSideDrawer()),
    onSideDrawerClose: () => dispatch(actions.closeSideDrawer())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sidebarStyle)(withWidth()(FixedSideDrawer)));
