// polyfills
import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import axios from './creditel-axios-private';
// components
import App from './containers/App';
// material ui core components
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// redux
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

// redux reducers
import authReducer from './store/reducers/auth';
import accountReducer from './store/reducers/account';
import eticketsReducer from './store/reducers/etickets';
import rechargesReducer from './store/reducers/recharges';
import stmReducer from './store/reducers/stmTrips';
import prepaidHistoricReducer from './store/reducers/prepaidHistoric';
import digitalReceiptsReducer from './store/reducers/digitalReceipts';
import movementsReducer from './store/reducers/movements';
import shopReducer from './store/reducers/shop';
import paymentReducer from './store/reducers/payment';
import myPaymentsReducer from './store/reducers/myPayments';
import additionalCardReducer from './store/reducers/additionalCard';
import debitsReducer from './store/reducers/debits';
import creditHistoryReducer from './store/reducers/creditHistory';
import newsReducer from './store/reducers/news';
import accountStatementReducer from './store/reducers/accountStatement';
import insurancesReducer from './store/reducers/insurances';
import bankAccountsReducer from './store/reducers/bankAccounts';
import menu from './store/reducers/menu';
import { logout } from './store/actions/auth.js';
import { forceUpdatePassword } from './store/actions/auth.js';
import { LOGOUT_OPERATION } from './store/actions/auth';

import './index.css';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: '"Proxima Nova"',
    useNextVariants: true
  },
  palette: {
    primary: {
      main: '#0d42fb'
    },
    secondary: {
      main: '#fed900'
    }
  }
});

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;


const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  etickets: eticketsReducer,
  recharges: rechargesReducer,
  stm: stmReducer,
  prepaid: prepaidHistoricReducer,
  digitalReceipts: digitalReceiptsReducer,
  movements: movementsReducer,
  shop: shopReducer,
  payments: myPaymentsReducer,
  additionalCard: additionalCardReducer,
  debits: debitsReducer,
  creditHistory: creditHistoryReducer,
  news: newsReducer,
  accountStatement: accountStatementReducer,
  insurances: insurancesReducer,
  bankAccounts: bankAccountsReducer,
  menu: menu,
  payment: paymentReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);


const app = (
  <Provider store={store}>
    <MuiThemeProvider theme={muiTheme}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_REF}>
        <App />
      </BrowserRouter>
    </MuiThemeProvider>
  </Provider >
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    if (response.data.actionCode && response.data.actionCode === 1) {
      // force update password
      store.dispatch(forceUpdatePassword(response.data.responseMessage));
      return response;
    } else if (
      response.data.responseCode === -7 &&
      !response.config.data.includes(LOGOUT_OPERATION)
    ) {
      store.dispatch(logout(false, response.data.responseMessage));
    } else {
      return response;
    }
  },
  function (error) { }
);

ReactDOM.render(app, document.getElementById('root'));