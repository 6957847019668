import React from 'react';
// material ui core components
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
// styles
import classes from './Coverages.module.css';

const Coverages = props => {

  const { open, onClose, onChange, coverages, value } = props;


  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.DialogPaper
      }}
      scroll={'paper'}
    >
      <DialogTitle>
        Seleccione una cobertura
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup aria-label="coverage" name="coverage" value={value} onChange={onChange}>
            {coverages.map(coverage => {
              return (
                <FormControlLabel
                  value={coverage.id.toString()}
                  control={<Radio />}
                  label={coverage.name}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
}

export default Coverages;