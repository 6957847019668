import React from 'react';
import { formatPrice } from '../../../shared/utility';
// material ui core components
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
//styles
import classes from './CreditLineHeader.module.css';

const CreditLineHeaders = (props) => {
  const { title, subtitle, subtitleValue, icon, limit, total, used, available } = props;
  let usedProgress = 0;
  if (total > 0) {
    usedProgress = parseInt(used * 100 / total, 10);
  }
  return (
    <div className={classes.CreditLines}>
      <h2>{title}</h2>
      {subtitle ?
        <div className={classes.SubtitleContainer}>
          <p className={classes.Subtitle}>{subtitle}</p>
          <span className={classes.SubtitleValue}>{subtitleValue}</span>
        </div>
        : null}

      <div className={classes.CreditLineContainer}>
        <Grid container alignItems="center">
          <Grid item xs={2} className={classes.CreditLineIconContainer}>
            <div style={{ position: 'relative' }}>
              <div className={classes.CreditLineIconBackground}></div>
              <img className={classes.CreditLineIcon} src={icon} style={{ maxWidth: '75px'}} alt="" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {limit ?
              <div className={classes.LimitContainer}>
                <p className={classes.CreditLineItemText}>Límite en Pesos</p>
                <p className={classes.CreditLineItemValue}>{formatPrice(limit)}</p>
              </div>
              : null}
          </Grid>

          <Grid item md={1}>
            {limit ?
              <div className={classes.CreditLineDivider}></div>
              : null}
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <div style={{ display: 'inline-block', paddingBottom: '10px', textAlign: 'center', width: '100%' }}>
              <p className={classes.CreditLineItemText}>Usado</p>
              <p className={classes.CreditLineItemValue}>{formatPrice(used)}</p>
            </div>

          </Grid>
          <Grid item md={1}>
            <div className={classes.CreditLineDivider}></div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div style={{ display: 'inline-block', paddingBottom: '10px', textAlign: 'center', width: '100%' }}>
              <p className={classes.CreditLineItemText}>Disponible</p>
              <p className={classes.CreditLineItemValue}>{formatPrice(available)}</p>
            </div>
          </Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.LinearProgressContainer}>
          <Grid item xs={8} sm={9}>
            <LinearProgress
              color="secondary"
              className={classes.LinearProgress}
              variant="determinate"
              value={usedProgress} />
          </Grid>
          <Grid item xs={4} sm={3} className={classes.UsedProgressContainer}>
            <span className={classes.ProgressUsedText}>Usado</span>
            <span className={classes.ProgressUsedValue}>{usedProgress + "%"}</span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CreditLineHeaders;