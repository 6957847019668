import React from 'react';
import { Formik, Field, getIn } from 'formik';
import { checkValidity } from '../../shared/validation';
// components
import Input from '../UI/Input/Input';
import Button from '../UI/CustomButtons/Button';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './Requirements.module.css';


const Requirements = props => {

  const {
    requirements,
    box,
    termsLink,
    onTermsLinkClick,
    showConfirmButton,
    confirmButtonText,
    showInfoButton,
    infoButtonText,
    onShowMoreInfo
  } = props;

  const validation = {};

  for (let requirement of requirements) {
    validation[requirement.box] = {
      required: requirement.maxLength !== 0,
      maxLength: requirement.maxLength,
      minLength: requirement.minLength,
      isNumeric: requirement.type === 'number'
    }
  }

  const getInitialValues = () => {
    let fields = {};
    for (let requirement of requirements) {
      fields[requirement.box] = '';
    }
    if (termsLink) {
      fields["tyc"] = false;
    }
    return fields;
  };

  const getSelectOptions = (requirement, box) => {
    let options = [];
    for (let b of box) {
      if (b.type === requirement.box) {
        for (let value of b.values) {
          options.push({ label: value.name, value: value.id });
        }
      }
    }
    return options;
  };

  const getRequirementFromField = field => {
    let requirementList = requirements.filter(req => {
      return req.box === field.name;
    });
    return requirementList.length > 0 ? requirementList[0] : null;
  };
  /*
    const validate = values => {
      let errors = {};
      for (let value in values) {
        const [valid, error] = checkValidity(values[value], { required: true });
        if (!valid) {
          errors[value] = error;
        }
      }
      return errors;
    };
  */
  const validate = (values) => {
    let errors = {};
    for (let field in validation) {
      const [valid, error] = checkValidity(values[field], validation[field]);
      if (!valid) {
        errors[field] = error;
      }
    }
    return errors;
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validate={values => validate(values)}>

      {formProps => {
        const requirementsFields = { ...formProps.values };
        delete requirementsFields.tyc;

        return (
          <form className={classes.RequirementsForm}>
            <Grid container spacing={16}>
              {Object.keys(requirementsFields).map((value, index) => {
                return (
                  <Grid item xs={12} md={6} key={index}>
                    <Field
                      key={value}
                      name={value}>
                      {({ field, form }) => {
                        let requirement = getRequirementFromField(field);
                        if (requirement) {
                          let options = [];
                          if (requirement.type === 'list') {
                            options = getSelectOptions(requirement, box);
                          }
                          return (
                            <Input
                              id={value}
                              valid={
                                formProps.values[field.name] !== null &&
                                formProps.values[field.name] !== ''
                              }
                              touched={
                                getIn(form.touched, field.name) ||
                                form.submitCount > 0
                              }
                              validation={{
                                required: requirement.maxLength !== 0,
                                maxLength: requirement.maxLength,
                                minLength: requirement.minLength,
                                isNumeric: requirement.type === 'number'
                              }}
                              label={requirement.help}
                              elementConfig={{
                                type: requirement.type === 'number' ? 'tel' : requirement.type,
                                placeholder: requirement.help,
                                options: options,
                                variant: 'outlined',
                                fullwidth: true,
                                noDense: true
                              }}
                              value={formProps.values[field.name]}
                              elementType={
                                requirement.type === 'list' ? 'select' : 'input'
                              }
                              error={formProps.errors[field.name]}
                              onChange={event => {
                                form.setFieldTouched(field.name, true);
                                formProps.handleChange(event);
                              }}
                            />
                          );
                        } else {
                          return null;
                        }
                      }}
                    </Field>
                  </Grid>
                );
              })}
              {termsLink ?
                <Grid item xs={12}>
                  <Field
                    name="tyc"
                    render={({ field, form }) => (
                      <Input
                        elementType="checkbox"
                        elementConfig={{
                          label: 'Términos y condiciones'
                        }}
                        value={formProps.values[field.name]}
                        onLabelClick={onTermsLinkClick}
                        onChange={() => {
                          form.setFieldTouched(field.name, true);
                          form.setFieldValue(field.name, !field.value);
                        }}
                        validation={{
                          isChecked: true
                        }}
                      />
                    )}
                  />
                </Grid>
                : null}
            </Grid>

            {props.children}

            <div className={classes.ButtonsContainer}>

              {showInfoButton ? (
                <Button
                  className={classes.InfoButton}
                  onClick={onShowMoreInfo}>
                  {infoButtonText}
                </Button>
              ) : null}

              {showConfirmButton ? (
                <Button
                  className={classes.ConfirmButton}
                  color="primary"
                  disabled={!formProps.isValid}
                  onClick={() => props.onClick(requirementsFields)}>
                  {confirmButtonText}
                </Button>
              ) : null}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default Requirements;