import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  prepaidHistoric: null,
  loading: false
};

const fetchPrepaidHistoricStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchPrepaidHistoricSuccess = (state, action) => {
  return updateObject(state, {
    prepaidHistoric: action.prepaidHistoric,
    loading: false
  });
};

const fetchPrepaidHistoricFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PREPAID_HISTORIC_START: return fetchPrepaidHistoricStart(state, action);
    case actionTypes.FETCH_PREPAID_HISTORIC_SUCCESS: return fetchPrepaidHistoricSuccess(state, action);
    case actionTypes.FETCH_PREPAID_HISTORIC_FAIL: return fetchPrepaidHistoricFail(state, action);
    default: return state;
  }
};

export default reducer;