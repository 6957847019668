import React, { Fragment } from 'react';
// components
import BankAccount from './BankAccount/BankAccount';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './BankAccountsList.module.css';


const BankAccountsList = (props) => {

  const {
    accounts,
    onDelete,
    //showDeleteAccount
  } = props;

  const primaryAccountsList = accounts.filter(account => account.primary);

  let otherAccounts = accounts;
  let primaryAccount = null;
  if (primaryAccountsList.length > 0) {
    primaryAccount = primaryAccountsList[0];
    otherAccounts = accounts.filter(account => primaryAccount.idAccount !== account.idAccount);
  } 

  return (
    accounts.length > 0 ?
      (
        <div>

          {primaryAccount ?
            <Fragment>
              <p className={classes.AccountsHeader}>Cuenta primaria</p>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <BankAccount
                    image={primaryAccount.img}
                    name={primaryAccount.name}
                    description={primaryAccount.description}
                    type={primaryAccount.type}
                    account={primaryAccount.account}
                    onDelete={() => onDelete(primaryAccount.idAccount)}
                  />
                </Grid>
                <Grid item xs={6}>
                </Grid>
              </Grid>
            </Fragment> : null}
          {accounts.length > 1 ?
            <Fragment>
              <p className={classes.AccountsHeader}>Otras cuentas</p>
            </Fragment>
            : null}
          <Grid container>
            {otherAccounts.map(account => {
              return (
                <Grid item xs={12} sm={6} key={account.idAccount}>
                  <BankAccount
                    image={account.img}
                    name={account.name}
                    description={account.description}
                    type={account.type}
                    account={account.account}
                    onDelete={() => onDelete(account.idAccount)}
                  />
                </Grid>
              )
            })}
          </Grid>

        </div>
      )
      :
      <p className={classes.EmptyState}>No tiene cuentas bancarias asociadas.</p>
  )
}

export default BankAccountsList;