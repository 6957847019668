import React from 'react';
// components
import Button from '../../CustomButtons/Button';
// material ui components
import DialogContent from '@material-ui/core/DialogContent';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// styles
import classes from './ConfirmRequestDialog.module.css';

const ConfirmRequestDialog = props => {
  const { text, open, onClose, onConfirmed } = props;
  return (
    <Dialog
      classes={{
        paper: classes.ConfirmationDialogPaperRoot
      }}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <p className={classes.ConfirmationDialogText}>
          {text}
        </p>
        <MuiButton
          color="primary"
          className={classes.CancelButton}
          onClick={onClose}
        >
          Cancelar
        </MuiButton>

        <Button
          color="primary"
          className={classes.ConfirmationDialogButton}
          onClick={onConfirmed}
        >
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmRequestDialog;
