import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

export const fetchNewsSuccess = (news) => {
  return {
    type: actionTypes.FETCH_NEWS_SUCCESS,
    news
  }
};

export const fetchNewsFail = (error) => {
  return {
    type: actionTypes.FETCH_NEWS_FAIL,
    error: error
  }
};

export const fetchNewsStart = () => {
  return {
    type: actionTypes.FETCH_NEWS_START
  }
};

export const setNewsCount = (count) => {
  localStorage.setItem('notifications', count);
  return {
    type: actionTypes.SET_NEWS_COUNT,
    unreadNews: count
  }
};

export const fetchNews = (token, page) => {
  let body = {
    operation: "getNews",
    sessionToken: token,
    channel: "WEB",
    web: "true"
  };
  return (dispatch) => {
    dispatch(fetchNewsStart());
    axios.post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchNewsFail(response.data.responseMessage));
        } else {
          dispatch(fetchNewsSuccess(response.data.news));
        }
      })
      .catch(error => {
        dispatch(fetchNewsFail(error));
      });
  };
};