import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';
import { updatePasswordRequest } from './generic';

export const updateDataSuccess = () => {
  return {
    type: actionTypes.UPDATE_DATA_SUCCESS
  }
};

export const updateDataFail = (error) => {
  return {
    type: actionTypes.UPDATE_DATA_FAIL,
    updateDataError: error
  }
};

export const updateDataStart = () => {
  return {
    type: actionTypes.UPDATE_DATA_START
  }
};

export const updateData = (token, phone, email) => {
  let body = {
    operation: "saveUserData",
    phone,
    email,
    sessionToken: token,
    channel: "WEB"
  };
  return dispatch => {
    dispatch(updateDataStart());
    axios.post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(updateDataFail(response.data.responseMessage));
        } else {
          localStorage.setItem('phone', phone);
          localStorage.setItem('email', email);
          dispatch(updateDataSuccess());
        }
      })
      .catch(error => {
        dispatch(updateDataFail(error));
      });
  };
};

export const updatePasswordSuccess = () => {
  return {
    type: actionTypes.UPDATE_PASSWORD_ACCOUNT_SUCCESS
  }
};

export const updatePasswordFail = (error) => {
  return {
    type: actionTypes.UPDATE_PASSWORD_ACCOUNT_FAIL,
    updatePasswordError: error
  }
};

export const updatePasswordStart = () => {
  return {
    type: actionTypes.UPDATE_PASSWORD_ACCOUNT_START
  }
};

export const accountUpdatePassword = (token, currentPassword, newPassword, newPasswordRepeated) => {
  return dispatch => {
    dispatch(updatePasswordStart());
    updatePasswordRequest(token, currentPassword, newPassword, newPasswordRepeated)
      .then((response) => {
        dispatch(updatePasswordSuccess())
      })
      .catch(error => dispatch(updatePasswordFail(error)))
  }
};

export const resetAccountForms = () => {
  return {
    type: actionTypes.RESET_ACCOUNT_FORMS
  };
};