import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
// components
import PaymentCash from '../../components/Payment/PaymentCash';
import PaymentCard from '../../components/Payment/PaymentCard';
import CustomDialog from '../../components/UI/Dialog/Dialog';
import PaymentCashDetail from '../../components/Payment/PaymentDetail/PaymentsCashDetail';
// styles
import classes from './Payment.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import CustomTabs from '../../components/UI/CustomTabs/CustomTabs';

import paganzaImg from '../../assets/images/myPayments/logo_paganza.png'


const tabs = [
    {
      label: "Tarjeta",
      img: null,
      imgSize: "25px"
    },
    {
      label: "Préstamos",
      img: null,
      imgSize: "22px"
    }
];

const Payment = (props) => {
    const [selectedPayment, setPayment] = useState(null);
    const [tabsIndex, setTabsIndex] = useState(props.location.state ? this.props.location.state.tabIndex : 0);
  
    useEffect(() => {
      props.onFetchCardPayment(props.token);
      props.onFetchCashPayment(props.token);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    const handleTabIndexChange = (_, value) => {
      setTabsIndex(value);
    };
  
    const onPaymentClick = (payment) => {
      setPayment(payment);
    }
  
    const handlePaymentDialogClose = () => {
      setPayment(null);
    }

    return (
      <div>
        <CustomTabs
          tabs={tabs}
          value={tabsIndex}
          onChange={handleTabIndexChange}
        />
        <div className={classes.PromotionsViews}>
          {tabsIndex === 0 ? props.cardPayment ?
            <Fragment>
              <PaymentCard payments={props.cardPayment} />
            </Fragment>
            : <Spinner /> : null}
          {tabsIndex === 1 ? props.cashPayment ?
            <PaymentCash
              payments={props.cashPayment}
              onPaymentClick={onPaymentClick} /> : <Spinner /> : null}
        </div>
  
        {selectedPayment && tabsIndex === 1 ?
          <CustomDialog
            open={selectedPayment}
            onClose={handlePaymentDialogClose}>
            <PaymentCashDetail
              payment={selectedPayment}
              card={tabsIndex === 0} />
          </CustomDialog>
          : null}

        <div className={classes.ContenedorPaganza}>
          <p>Descargá la APP y pagá seguro con</p>
          <img alt="Paganza" src={paganzaImg} />
        </div>
      </div>
    );
  }


const mapStateToProps = state => {
    return {
      cardPayment: state.payment.cardPayment,
      cashPayment: state.payment.cashPayment,
      error: state.payments.error,
      loading: state.payment.loading,
      token: state.auth.token
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      onFetchCardPayment: (token) => dispatch(actions.fetchMyCardPayment(token)),
      onFetchCashPayment: (token) => dispatch(actions.fetchMyCashPayment(token))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Payment);