import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

const fetchMyCardPaymentStart = () => {
  return {
    type: actionTypes.FETCH_MY_CARD_PAYMENT_START,
  };
};

const fetchMyCardPaymentSuccess = payment => {
  return {
    type: actionTypes.FETCH_MY_CARD_PAYMENT_SUCCESS,
    cardPayment: payment,
  };
};

const fetchMyCardPaymentFail = error => {
  return {
    type: actionTypes.FETCH_MY_CARD_PAYMENT_FAIL,
    error: error,
  };
};

export const fetchMyCardPayment = (token) => {
  const body = {
    operation: 'getPaymentsDebt',
    product: 'CARD',
    sessionToken: token,
    channel: 'WEB'
  };
  return dispatch => {
    dispatch(fetchMyCardPaymentStart());
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchMyCardPaymentFail(response.data.responseMessage));
        } else {
          dispatch(fetchMyCardPaymentSuccess(response.data.cardSummary));
        }
      })
      .catch(err => {
        dispatch(fetchMyCardPaymentFail(err));
      });
  };
};


// Cash payments

const fetchMyCashPaymentStart = () => {
  return {
    type: actionTypes.FETCH_MY_CASH_PAYMENT_START,
  };
};

const fetchMyCashPaymentSuccess = payment => {
  return {
    type: actionTypes.FETCH_MY_CASH_PAYMENT_SUCCESS,
    cashPayment: payment,
  };
};

const fetchMyCashPaymentFail = error => {
  return {
    type: actionTypes.FETCH_MY_CASH_PAYMENT_FAIL,
    error: error,
  };
};

export const fetchMyCashPayment = (token) => {
  const body = {
    operation: 'getPaymentsDebt',
    product : 'LOAN',
    sessionToken: token,
    channel: 'WEB'
  };

  return dispatch => {
    dispatch(fetchMyCashPaymentStart());
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchMyCashPaymentFail(response.data.responseMessage));
        } else {
          dispatch(fetchMyCashPaymentSuccess(response.data.loanSummary));
        }
      })
      .catch(err => {
        dispatch(fetchMyCashPaymentFail(err));
      });
  };
};