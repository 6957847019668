import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  credits: null,
  loading: false
};

const fetchCreditHistoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchCreditHistorySuccess = (state, action) => {
  return updateObject(state, {
    credits: action.credits,
    loading: false
  });
};

const fetchCreditHistoryFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CREDIT_HISTORY_START: return fetchCreditHistoryStart(state, action);
    case actionTypes.FETCH_CREDIT_HISTORY_SUCCESS: return fetchCreditHistorySuccess(state, action);
    case actionTypes.FETCH_CREDIT_HISTORY_FAIL: return fetchCreditHistoryFail(state, action);
    default: return state;
  }
};

export default reducer;