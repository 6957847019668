import React, { Fragment } from 'react';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './ReceiptDetailItem.module.css';

const ReceiptDetailItem = (props) => (
  <Fragment>
    <Grid item xs={6}>
      <span className={classes.DetailTitle}>{props.title}</span>
    </Grid>
    <Grid item xs={6}>
      <span className={classes.Values}>{props.value}</span>
    </Grid>
  </Fragment>
);

export default ReceiptDetailItem;