import { useEffect, useState } from 'react';
import createPersistedState from 'use-persisted-state';

export function useClearCache(props = {}) {
  const { duration = 60 * 1000, auto = false } = props;

  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(true);
  const useAppVersionState = createPersistedState('appVersion');
  const [appVersion, setAppVersion] = useAppVersionState('');
  const [latestVersion, setLatestVersion] = useState(appVersion);

  async function setVersion(version) {
    await setAppVersion(version);
  }

  const emptyCacheStorage = async (version) => {
    console.log('Borrando caché y recargando...');
    if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach(name => {
            caches.delete(name);
          })
      });
    }

    // clear browser cache and reload page
    await setVersion(version || latestVersion).then(() =>
      window.location.reload(true)
    );
  };

  function fetchMeta() {
    fetch(process.env.REACT_APP_BASE_REF + 'meta.json', {
      cache: 'no-store'
    })
      .then((response) => response.json())
      .then((meta) => {
        const newVersion = meta.version;
        const currentVersion = appVersion;
        const isUpdated = newVersion === currentVersion;
        if (!isUpdated && !auto) {
          console.log('¡Hay disponible una actualización!');
          setLatestVersion(newVersion);
          setLoading(false);
          setIsLatestVersion(false);
        } else if (!isUpdated && auto) {
          emptyCacheStorage(newVersion);
        } else {
          setIsLatestVersion(true);
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    const fetchCacheTimeout = setInterval(() => fetchMeta(), duration);
    return () => {
      clearInterval(fetchCacheTimeout);
    };
  }, [loading]);

  useEffect(() => {
    fetchMeta();
  }, []);

  return {
    loading,
    isLatestVersion,
    emptyCacheStorage,
    latestVersion
  };
}