import React from 'react';
// material ui core components
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// styles
import classes from './CustomRadioButton.module.css';

const CustomRadioButton = (props) => {

  const {
    id,
    elementConfig,
    value,
    onChange
  } = props;
  return (
    <div style={{ textAlign: 'center' }}>
      <FormControl id={id} component="fieldset" className={classes.RadioButton}>
        <FormLabel
          component="legend">
          {elementConfig.label}
        </FormLabel>
        <RadioGroup
          id={id}
          aria-label={elementConfig.label}
          name={elementConfig.label}
          className={elementConfig.direction === 'column' ? classes.ColumnRadioGroup : classes.RowRadioGroup}
          value={value}
          onChange={onChange}
        >
          {elementConfig.options.map(option => {
            return (
              <FormControlLabel
                key={option.label}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label} />
            )
          })}
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default CustomRadioButton;
