import React from 'react';
import { formatPriceConMoneda , parseDate} from '../../../shared/utility';
// styles
import classes from './PaymentsCashDetail.module.css';

const esFechaAtrasada = date => {
    const hoy=new Date();
    const fechaRecibida= new Date(date);
    const anio=hoy.getFullYear()-70;
    const fechaMinima= new Date(anio,11,31); // 31/12/aaaa-70
    if ((fechaRecibida<hoy) && (fechaRecibida>fechaMinima)) {
      return true;
    }
    return false;
}

const FeeDetail = (props) => {
    let color="";
    if(esFechaAtrasada(props.payments.expirationFee)){
      color="red";
    }

  return (
    <div className={classes.DetalleCuota}>
        <p className={classes.PaymentFieldValue}>Cuota N° {props.payments.fee}</p>
        <p className={classes.PaymentFieldText} style={{color:color}}>Vencimiento: {parseDate(props.payments.expirationFee)}</p>
        <p className={classes.PaymentFieldText}>Monto: {formatPriceConMoneda(props.payments.amountFee, 0)}</p>
    </div>
  )
}

export default FeeDetail;