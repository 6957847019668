import * as actionTypes from './actionTypes';

export const toggleSubMenu = subMenu => {
  return dispatch => {
    dispatch({
      type: actionTypes.TOGGLE_SUB_MENU,
      subMenu: subMenu,
    });
  };
};

export const toggleSideDrawer = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.TOGGLE_SIDE_DRAWER,
    });
  };
};

export const closeSideDrawer = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLOSE_SIDE_DRAWER,
    });
  };
};
