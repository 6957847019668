import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

export const fetchInsurancesSuccess = insurances => {
  return {
    type: actionTypes.FETCH_INSURANCES_SUCCESS,
    insurances
  }
};

export const fetchInsurancesFail = error => {
  return {
    type: actionTypes.FETCH_INSURANCES_FAIL,
    error: error
  }
};

export const fetchInsurancesStart = () => {
  return {
    type: actionTypes.FETCH_INSURANCES_START
  }
};

export const fetchInsurances = (token) => {
  let body = {
    operation: "getInsurances",
    sessionToken: token,
    channel: "WEB"
  };
  return dispatch => {
    dispatch(fetchInsurancesStart());
    axios.post('/', body)
      .then(response => {
        dispatch(fetchInsurancesSuccess(response.data.insurances));
      })
      .catch(error => {
        dispatch(fetchInsurancesFail(error));
      });
  };
};


export const fetchInsuranceDetailsSuccess = (requirements, box, responseCode, responseMessage) => {
  return {
    type: actionTypes.FETCH_INSURANCE_SUCCESS,
    responseCode,
    responseMessage,
    requirements,
    box
  }
};

export const fetchInsuranceDetailsFail = error => {
  return {
    type: actionTypes.FETCH_INSURANCE_FAIL,
    error: error
  }
};

export const fetchInsuranceDetailsStart = () => {
  return {
    type: actionTypes.FETCH_INSURANCE_START
  }
};

export const fetchInsuranceDetails = (token, id, idGroup) => {
  let body = {
    operation: "getInsurance",
    sessionToken: token,
    id,
    idGroup,
    channel: "WEB"
  };
  return dispatch => {
    dispatch(fetchInsuranceDetailsStart());
    axios.post('/', body)
      .then(response => {
        dispatch(fetchInsuranceDetailsSuccess(response.data.requirements, response.data.box, response.data.responseCode, response.data.responseMessage));
      })
      .catch(error => {
        dispatch(fetchInsuranceDetailsFail(error));
      });
  };
};

// Request insurance
export const requestInsuranceSuccess = responseMessage => {
  return {
    type: actionTypes.REQUEST_INSURANCE_SUCCESS,
    responseMessage
  }
};

export const requestInsuranceFail = error => {
  return {
    type: actionTypes.REQUEST_INSURANCE_FAIL,
    error: error
  }
};

export const requestInsuranceStart = () => {
  return {
    type: actionTypes.REQUEST_INSURANCE_START
  }
};

export const requestInsurance = (token, id, idGroup, values) => {
  let body = {
    operation: "requestInsurance",
    sessionToken: token,
    id,
    idGroup,
    values,
    channel: "WEB"
  };
  return dispatch => {
    dispatch(requestInsuranceStart());
    axios.post('/', body)
      .then(response => {
        dispatch(requestInsuranceSuccess(response.data.responseMessage));
      })
      .catch(error => {
        dispatch(requestInsuranceFail(error));
      });
  };
};

export const resetInsuranceRequested = () => {
  return {
    type: actionTypes.RESET_INSURANCE_REQUESTED
  }
};