/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
// components
import HeaderLinks from './HeaderLinks/HeaderLinks';
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
// styles
import headerStyle from '../../assets/jss/components/headerStyle.jsx';

const Header = (props) => {
  const { classes, color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <Hidden smDown>
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <div style={{ width: '100%' }}>
            <HeaderLinks onLogout={props.onLogout} />
          </div>
        </Toolbar>
      </AppBar>
    </Hidden>
  );
}

export default withRouter(withStyles(headerStyle)(Header));