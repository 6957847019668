import React, { Fragment, useEffect } from 'react';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
// components
import CardTitle from '../../../components/UI/Card/CardTitle/CardTitle';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Card from '../../../components/UI/Card/Card';
// styles
import classes from './AddAccounts.module.css';
import BanksList from '../../../components/Banks/BanksList';

const AddAccounts = (props) => {

  useEffect(() => {
    props.onFetchBanks(props.token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBankClick = (bank) => {
      // show details
      props.history.push({
        pathname: `/agregar-cuenta/${bank.idBank}`,
        state: {
          bank: bank
        }
      });
  }

  let banks = null;
  if (props.loading) {
    banks = <Spinner />;
  } else if (props.banks) {
    banks = 
    <BanksList 
      banks={props.banks}
      onClick={handleBankClick} />
  }

  let errorMessage = null;
  if (props.error) {
    errorMessage = (
      <p className={classes.ErrorMessage}>
        Se produjo un error al intentar obtener los bancos
      </p>
    );
  }

  return (
    <Fragment>
      <Card className={classes.Card}>
        <CardTitle title='Agregar Cuentas' />
        {banks}
        {errorMessage}
      </Card>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    banks: state.bankAccounts.banks,
    loading: state.bankAccounts.loading,
    error: state.bankAccounts.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchBanks: token => dispatch(actions.fetchBanks(token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAccounts);