import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

const fetchShopsStart = () => {
  return {
    type: actionTypes.FETCH_SHOPS_START,
  };
};

const fetchShopsSuccess = shops => {
  return {
    type: actionTypes.FETCH_SHOPS_SUCCESS,
    shops: shops,
  };
};

const fetchShopsFail = error => {
  return {
    type: actionTypes.FETCH_SHOPS_FAIL,
    error: error,
  };
};

export const fetchShops = token => {
  const body = {
    channel: 'WEB',
    operation: 'getTiendaCreditel',
    sessionToken: token,
  };
  return dispatch => {
    dispatch(fetchShopsStart());
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchShopsFail(response.data.responseMessage));
        } else {
          dispatch(fetchShopsSuccess(response.data.Proveedores));
        }
      })
      .catch(err => {
        dispatch(fetchShopsFail(err));
      });
  };
};

const fetchShopStart = () => {
  return {
    type: actionTypes.FETCH_SHOP_START
  };
};

const fetchShopSuccess = shop => {
  return {
    type: actionTypes.FETCH_SHOP_SUCCESS,
    shop: shop
  };
};

const fetchShopFail = error => {
  return {
    type: actionTypes.FETCH_SHOP_FAIL,
    error: error
  };
};

export const fetchShop = (shopId, token) => {
  let body = {
    channel: 'WEB',
    operation: 'eCommerceTienda',
    sessionToken: token,
    orden: shopId
  };
/*
  if (shopId === '2') {
    body = {
      channel: 'WEB',
      operation: 'getCreditickets',
      sessionToken: token,
    };
  }*/

  return dispatch => {
    dispatch(fetchShopStart());
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0 && response.data.responseCode !== '0') {
          dispatch(fetchShopFail(response.data.responseMessage));
        } else {
          dispatch(fetchShopSuccess(response.data));
        }
      })
      .catch(err => dispatch(fetchShopFail(err)));
  };
};

