import React from 'react';
import PropTypes from 'prop-types';
// components
import Button from '../../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
// styles
import classes from './IframeDialog.module.css';

const IframeDialog = props => {

  const {
    open,
    onClose,
    onAccept,
    link
  } = props;

  return (
    <Dialog
      PaperProps={{
        style: {
          height: '60vh'
        }
      }}
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <div
        className={classes.TermsContainer}
        dangerouslySetInnerHTML={{
          __html: `
            <iframe 
              style="height: 100%; width: 100%; border: none;" 
              src="${link}">
            </iframe>
          `
        }}
      />
      <div className={classes.AcceptTermsButtonContainer}>
        <Button
          className={classes.AcceptTermsButton}
          color="primary"
          onClick={onAccept}
        >
          Ok
        </Button>
      </div>
    </Dialog>
  );
};

IframeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired
};

export default IframeDialog;
