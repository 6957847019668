import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';


// fetch bank accounts

export const fetchBankAccountsSuccess = (accounts, helpName, helpUrl) => {
  return {
    type: actionTypes.FETCH_BANK_ACCOUNTS_SUCCESS,
    accounts,
    helpName,
    helpUrl
  }
};

export const fetchBankAccountsFail = error => {
  return {
    type: actionTypes.FETCH_BANK_ACCOUNTS_FAIL,
    error: error
  }
};

export const fetchBankAccountsStart = () => {
  return {
    type: actionTypes.FETCH_BANK_ACCOUNTS_START
  }
};

export const fetchBankAccounts = token => {
  let body = {
    operation: "getBankAccount",
    sessionToken: token,
    channel: "WEB"
  };
  return (dispatch) => {
    dispatch(fetchBankAccountsStart());
    axios.post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchBankAccountsFail(response.data.responseMessage));
        } else {
          dispatch(fetchBankAccountsSuccess(
            response.data.accounts,
            response.data.helpName,
            response.data.helpUrl
          ));
        }
      })
      .catch(error => {
        dispatch(fetchBankAccountsFail(error));
      });
  };
};


// fetch banks

export const fetchBanksSuccess = banks => {
  return {
    type: actionTypes.FETCH_BANKS_SUCCESS,
    banks
  }
};


export const fetchBanksFail = error => {
  return {
    type: actionTypes.FETCH_BANKS_FAIL,
    error: error
  }
};

export const fetchBanksStart = () => {
  return {
    type: actionTypes.FETCH_BANKS_START
  }
};

export const fetchBanks = token => {
  let body = {
    operation: "getBanks",
    sessionToken: token,
    channel: "WEB"
  };
  return (dispatch) => {
    dispatch(fetchBanksStart());
    axios.post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchBanksFail(response.data.responseMessage));
        } else {
          dispatch(fetchBanksSuccess(response.data.banks));
        }
      })
      .catch(error => {
        dispatch(fetchBanksFail(error));
      });
  };
};


// bank requirements

export const fetchBankRequirementsSuccess = (requirements, box) => {
  return {
    type: actionTypes.FETCH_BANK_REQUIREMENTS_SUCCESS,
    requirements,
    box
  }
};


export const fetchBankRequirementsFail = error => {
  return {
    type: actionTypes.FETCH_BANK_REQUIREMENTS_FAIL,
    error: error
  }
};

export const fetchBankRequirementsStart = () => {
  return {
    type: actionTypes.FETCH_BANK_REQUIREMENTS_START
  }
};

export const fetchBankRequirements = (token, idBank) => {
  let body = {
    operation: "getBankRequirements",
    sessionToken: token,
    idBank,
    channel: "WEB"
  };
  return (dispatch) => {
    dispatch(fetchBankRequirementsStart());
    axios.post('/', body)
      .then(response => {

        if (response.data.responseCode !== 0) {
          dispatch(fetchBankRequirementsFail(response.data.responseMessage));
        } else {
          dispatch(fetchBankRequirementsSuccess(response.data.requirements, response.data.box));
        }
      })
      .catch(error => {
        dispatch(fetchBankRequirementsFail(error));
      });
  };
};


// Request bank account

export const requestBankAccountSuccess = (responseCode, responseMessage) => {
  return {
    type: actionTypes.REQUEST_BANK_ACCOUNT_SUCCESS,
    responseCode,
    responseMessage
  }
};


export const requestBankAccountFail = (responseCode, error) => {
  return {
    type: actionTypes.REQUEST_BANK_ACCOUNT_FAIL,
    responseCode,
    responseMessage: error
  }
};

export const requestBankAccountStart = () => {
  return {
    type: actionTypes.REQUEST_BANK_ACCOUNT_START
  }
};

export const requestBankAccount = (token, idBank, values, primaryAccount) => {
  let body = {
    operation: "requestBankAccount",
    sessionToken: token,
    idBank,
    values,
    primary: primaryAccount,
    channel: "WEB"
  };
  return (dispatch) => {
    dispatch(requestBankAccountStart());
    axios.post('/', body)
      .then(response => {

        if (response.data.responseCode !== 0) {
          dispatch(requestBankAccountFail(response.data.responseCode, response.data.responseMessage));
        } else {
          dispatch(requestBankAccountSuccess(response.data.responseCode, response.data.responseMessage));
        }
      })
      .catch(error => {
        dispatch(requestBankAccountFail(error));
      });
  };
};

export const resetBankAccountRequested = () => {
  return {
    type: actionTypes.RESET_BANK_ACCOUNT_REQUESTED
  }
};

// Delete bank account

export const deleteBankAccountSuccess = (responseCode, responseMessage) => {
  return {
    type: actionTypes.DELETE_BANK_ACCOUNT_SUCCESS,
    responseCode,
    responseMessage
  }
};


export const deleteBankAccountFail = (responseCode, responseMessage) => {
  return {
    type: actionTypes.DELETE_BANK_ACCOUNT_FAIL,
    responseCode,
    responseMessage
  }
};

export const deleteBankAccountStart = () => {
  return {
    type: actionTypes.DELETE_BANK_ACCOUNT_START
  }
};

export const deleteBankAccount = (token, idAccount) => {
  let body = {
    operation: "deleteBankAccount",
    sessionToken: token,
    idAccount,
    channel: "WEB"
  };
  return (dispatch) => {
    dispatch(deleteBankAccountStart());
    axios.post('/', body)
      .then(response => {

        if (response.data.responseCode !== 0) {
          dispatch(deleteBankAccountFail(response.data.responseCode, response.data.responseMessage));
        } else {
          dispatch(deleteBankAccountSuccess(response.data.responseCode, response.data.responseMessage));
        }
      })
      .catch(error => {
        dispatch(deleteBankAccountFail(error));
      });
  };
};

export const resetBankAccountDeleted = () => {
  return {
    type: actionTypes.RESET_BANK_ACCOUNT_DELETED
  }
};
