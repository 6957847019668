import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  receipts: null,
  loading: false,
  page: 1
};

const fetchDigitalReceiptsStart = (state, action) => {
  return updateObject(state, { 
    loading: true 
  });
};

const fetchDigitalReceiptsSuccess = (state, action) => {
  let receipts = action.receipts;            
  if (state.page !== action.page) {
    receipts = state.receipts.concat(action.receipts);
  }
  return updateObject(state, {
    receipts: receipts,
    page: action.page,
    showMore: action.receipts && action.receipts.length > 0,
    loading: false
  });
};

const fetchDigitalReceiptsFail = (state, action) => {
  return updateObject(state, {
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DIGITAL_RECEIPTS_START: return fetchDigitalReceiptsStart(state, action);
    case actionTypes.FETCH_DIGITAL_RECEIPTS_SUCCESS: return fetchDigitalReceiptsSuccess(state, action);
    case actionTypes.FETCH_DIGITAL_RECEIPTS_FAIL: return fetchDigitalReceiptsFail(state, action);
    default: return state;
  }
};

export default reducer;