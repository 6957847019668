import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { isSmallOrExtraSmallScreen } from '../../../../shared/utility';
// material ui components
import withWidth from '@material-ui/core/withWidth';
// material ui icons
import CheckCircle from '@material-ui/icons/CheckCircle';
// styles
import classes from './RequestInsurance.module.css';

const RequestInsurance = props => {

  const refInsurance = useRef();
  const [insuranceWidth, setInsuranceWidth] = React.useState({});

  useEffect(() => {
    if (isSmallOrExtraSmallScreen(props.width)) {
      updateSize();
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refInsurance.current]);

  const updateSize = () => {
    setInsuranceWidth(refInsurance.current.clientWidth);
  };

  const { hasInsurance, logo, name } = props;
  let selectedClass = null;
  if (hasInsurance) {
    selectedClass = classes.InsuranceSelected;
  }
  return (
    <div ref={refInsurance} className={classNames(classes.Paper, selectedClass)} style={insuranceWidth ? { height: insuranceWidth + 'px' } : {}}>
      {hasInsurance ? <CheckCircle className={classes.CheckIcon} /> : null}
      <img style={{ maxWidth: '100%' }}src={logo} alt='Seguros' />
      <p className={classes.Name}>{name}</p>
    </div>
  );
}

export default withWidth()(RequestInsurance);