import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  sideDrawerOpen: false,
  subMenus: {
    card: false
  }
}

const closeSideDrawer = (state, action) => {
  return updateObject(state, {
    sideDrawerOpen: false
  });
};

const toggleSideDrawer = (state, action) => {
  return updateObject(state, {
    sideDrawerOpen: !state.sideDrawerOpen
  });
};

const toggleSubMenu = (state, action) => {
  return updateObject(state, {
    subMenus: {
      ...state.subMenus,
      [action.subMenu]: !state.subMenus[action.subMenu]
    }
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLOSE_SIDE_DRAWER: return closeSideDrawer(state, action);
    case actionTypes.TOGGLE_SIDE_DRAWER: return toggleSideDrawer(state, action);
    case actionTypes.TOGGLE_SUB_MENU: return toggleSubMenu(state, action);
    default:
      return state;
  }
}

export default reducer;

