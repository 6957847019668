import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  loadingData: false,
  loadingPassword: false,
  updateDataError: null,
  updatePasswordError: null,
  dataUpdated: false,
  passwordUpdated: false
};

const updateDataStart = (state, action) => {
  return updateObject(state, {
    loadingData: true,
    dataUpdated: false,
    updateDataError: null
  });
};

const updatePasswordStart = (state, action) => {
  return updateObject(state, {
    loadingPassword: true,
    passwordUpdated: false,
    updatePasswordError: null
  });
};

const updateDataSuccess = (state, action) => {
  return updateObject(state, {
    loadingData: false,
    dataUpdated: true
  });
};

const updatePasswordSuccess = (state, action) => {
  return updateObject(state, {
    loadingPassword: false,
    passwordUpdated: true
  });
};

const updateDataFail = (state, action) => {
  return updateObject(state, {
    loadingData: false,
    updateDataError: action.updateDataError
  });
};

const updatePasswordFail = (state, action) => {
  return updateObject(state, {
    loadingPassword: false,
    updatePasswordError: action.updatePasswordError
  });
};

const resetAccountForms = (state, action) => {
  return updateObject(state, {
    dataUpdated: false,
    passwordUpdated: false,
    updateDataError: null,
    updatePasswordError: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DATA_START:
      return updateDataStart(state, action);
    case actionTypes.UPDATE_PASSWORD_ACCOUNT_START:
      return updatePasswordStart(state, action);
    case actionTypes.UPDATE_DATA_SUCCESS:
      return updateDataSuccess(state, action);
    case actionTypes.UPDATE_PASSWORD_ACCOUNT_SUCCESS:
      return updatePasswordSuccess(state, action);
    case actionTypes.UPDATE_DATA_FAIL:
      return updateDataFail(state, action);
    case actionTypes.UPDATE_PASSWORD_ACCOUNT_FAIL:
      return updatePasswordFail(state, action);
    case actionTypes.RESET_ACCOUNT_FORMS:
      return resetAccountForms(state, action);
    default:
      return state;
  }
};

export default reducer;
