import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  accountStatement: null,
  loading: false
};

const fetchAccountStatementStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchAccountStatementSuccess = (state, action) => {
  return updateObject(state, {
    accountStatement: action.accountStatement,
    loading: false
  });
};

const fetchAccountStatementFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNT_STATEMENT_START: return fetchAccountStatementStart(state, action);
    case actionTypes.FETCH_ACCOUNT_STATEMENT_SUCCESS: return fetchAccountStatementSuccess(state, action);
    case actionTypes.FETCH_ACCOUNT_STATEMENT_FAIL: return fetchAccountStatementFail(state, action);
    default: return state;
  }
};

export default reducer;