import React, { useEffect, useState, Fragment } from 'react';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
// components
import Card from '../../components/UI/Card/Card';
import CardTitle from '../../components/UI/Card/CardTitle/CardTitle';
import RequestInsurancesList from '../../components/Insurances/RequestInsurances/RequestInsurancesList';
import Coverages from '../../components/Insurances/Coverages/Coverages';
import Spinner from '../../components/UI/Spinner/Spinner';
// styles
import classes from './RequestInsurances.module.css';


const RequestInsurances = props => {

  const [selectedInsurance, setInsurance] = useState(null);
  const [selectedCoverage, setCoverage] = useState(null);

  useEffect(() => {
    props.onFetchInsurances(props.token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectedInsurance = (insurance) => {

    if (insurance.coverages.length === 1) {
      // show details
      props.history.push({
        pathname: `/seguros/${insurance.coverages[0].id}`,
        state: {
          insurance: insurance,
          coverage: insurance.coverages[0],
          idGroup: insurance.idGroup
        }
      });
      setInsurance(null);
    } else {
      setInsurance(insurance);
    }
  }

  const onCoveragesDialogClose = () => {
    setInsurance(null);
  }

  const onCoverageSelected = event => {
    let coverage = selectedInsurance.coverages.filter(coverage => {
      return coverage.id.toString() === event.target.value;
    });
    if (coverage) {
      props.history.push({
        pathname: `/seguros/${event.target.value}`,
        state: {
          insurance: selectedInsurance,
          idGroup: selectedInsurance.idGroup,
          coverage: coverage[0]
        }
      })
    }

    setCoverage(null);
  }

  let requestInsurancesContent = null;
  if (props.loading) {
    requestInsurancesContent = <Spinner />;
  } else if (props.insurances) {
    requestInsurancesContent = (
      <RequestInsurancesList
        insurances={props.insurances}
        onSelectedInsurance={onSelectedInsurance}
      />
    );
  }

  return (
    <Fragment>
      {selectedInsurance ?
        <Coverages
          open={selectedCoverage === null}
          value={selectedCoverage}
          onClose={onCoveragesDialogClose}
          onChange={onCoverageSelected}
          coverages={selectedInsurance.coverages} />
      : null}

      <Card className={classes.Card}>
        <CardTitle title='Solicitar Seguros' />
        {requestInsurancesContent}
      </Card>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    insurances: state.insurances.insurances,
    error: state.insurances.error,
    loading: state.insurances.loading,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchInsurances: (token) => dispatch(actions.fetchInsurances(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestInsurances);