import React, { Component, } from "react";

const withDocumentTitle = (WrappedComponent, title) => {

  const HOC = class extends Component {
    
    componentDidMount() {
      if (title) {
        document.title = title;
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
  return HOC;
};

export default withDocumentTitle;