import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  eTickets: null,
  loading: false
};

const fetchETicketsStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchEticketsSuccess = (state, action) => {
  return updateObject(state, {
    eTickets: action.eTickets,
    loading: false
  });
};

const fetchETicketsFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ETICKETS_START: return fetchETicketsStart(state, action);
    case actionTypes.FETCH_ETICKETS_SUCCESS: return fetchEticketsSuccess(state, action);
    case actionTypes.FETCH_ETICKETS_FAIL: return fetchETicketsFail(state, action);
    default: return state;
  }
};

export default reducer;