import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
// components
import MyPaymentsList from '../../components/MyPayments/MyPaymentsList';
import CustomDialog from '../../components/UI/Dialog/Dialog';
import MyPaymentsDetail from '../../components/MyPayments/MyPaymentsDetail/MyPaymentsDetail';
// styles
import classes from './MyPayments.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import CustomTabs from '../../components/UI/CustomTabs/CustomTabs';

const tabs = [
  {
    label: "Tarjeta",
    img: null,
    imgSize: "25px"
  },
  {
    label: "Préstamos",
    img: null,
    imgSize: "22px"
  }
];

const MyPayments = (props) => {

  const [selectedPayment, setPayment] = useState(null);
  const [tabsIndex, setTabsIndex] = useState(props.location.state ? this.props.location.state.tabIndex : 0);

  useEffect(() => {
    props.onFetchCardPayments(props.token);
    props.onFetchCashPayments(props.token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTabIndexChange = (_, value) => {
    setTabsIndex(value);
  };

  const onPaymentClick = (payment) => {
    setPayment(payment);
  }

  const handlePaymentDialogClose = () => {
    setPayment(null);
  }

  let primaryCard = null;
  const cards = JSON.parse(localStorage.getItem('cards'));
  for (let card of cards) {
    if (card.isPrimary) {
      primaryCard = card;
      break;
    }
  }
  return (
    <div>
      <CustomTabs
        tabs={tabs}
        value={tabsIndex}
        onChange={handleTabIndexChange}
      />
      <div className={classes.PromotionsViews}>
        {tabsIndex === 0 ? props.cardPayments && primaryCard ?
          <Fragment>
            {props.cardPayments.length > 0 ? <div className={classes.InfoTextContainer}>
              <p className={classes.InfoText}>Cuenta de tarjeta {primaryCard.cardNumber}</p>
            </div> : null}
            <MyPaymentsList
              payments={props.cardPayments}
              onPaymentClick={onPaymentClick} />
          </Fragment>
          : <Spinner /> : null}
        {tabsIndex === 1 ? props.cashPayments ?
          <MyPaymentsList
            payments={props.cashPayments}
            onPaymentClick={onPaymentClick} /> : <Spinner /> : null}
      </div>

      {selectedPayment ?
        <CustomDialog
          open={selectedPayment}
          onClose={handlePaymentDialogClose}>
          <MyPaymentsDetail
            payment={selectedPayment}
            card={tabsIndex === 0} />
        </CustomDialog>
        : null}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    cardPayments: state.payments.cardPayments,
    cashPayments: state.payments.cashPayments,
    error: state.payments.error,
    loading: state.payments.loading,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchCardPayments: (token) => dispatch(actions.fetchMyCardPayments(token)),
    onFetchCashPayments: (token) => dispatch(actions.fetchMyCashPayments(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPayments);