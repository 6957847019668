import React, { Fragment, useState } from 'react';
// components
import Requirements from '../../Requirements/Requirements';
import TermsAndConditionsDialog from '../../UI/Dialog/IframeDialog/IframeDialog';

const InsuranceRequirements = props => {
  const [showTermsDialog, setShowTermsDialog] = useState(false);

  const {
    requirements,
    box,
    onClick,
    termsLink,
    canHire,
    onShowMoreInfo
  } = props;

  return (
    <Fragment>

      <Requirements
        requirements={requirements}
        box={box}
        onClick={onClick}
        termsLink={termsLink}
        onTermsLinkClick={() => setShowTermsDialog(true)}
        showConfirmButton={canHire}
        confirmButtonText="Contratar"
        showInfoButton={true}
        infoButtonText="Más Info"
        onShowMoreInfo={onShowMoreInfo} />

      <TermsAndConditionsDialog
        open={showTermsDialog}
        onClose={() => setShowTermsDialog(false)}
        link={termsLink}
        onAccept={() => setShowTermsDialog(false)}
      />
    </Fragment>
  );
};

export default InsuranceRequirements;
