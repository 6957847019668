import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

const fetchDebitProvidersStart = () => {
  return {
    type: actionTypes.FETCH_DEBIT_PROVIDERS_START,
  };
};

const fetchDebitProvidersSuccess = providers => {
  return {
    type: actionTypes.FETCH_DEBIT_PROVIDERS_SUCCESS,
    providers,
  };
};

const fetchDebitProvidersFail = error => {
  return {
    type: actionTypes.FETCH_DEBIT_PROVIDERS_FAIL,
    error: error,
  };
};

export const fetchDebitProviders = (token) => {
  const body = {
    operation: 'getDebitProviders',
    sessionToken: token,
    channel: 'WEB'
  };
  return dispatch => {
    dispatch(fetchDebitProvidersStart());
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(fetchDebitProvidersFail(response.data.responseMessage));
        } else {
          dispatch(fetchDebitProvidersSuccess(response.data.providers));
        }
      })
      .catch(err => {
        dispatch(fetchDebitProvidersFail(err));
      });
  };
};



const requestDebitStart = () => {
  return {
    type: actionTypes.REQUEST_DEBIT_START,
  };
};

const requestDebitSuccess = (responseMessageTop, responseMessageBottom) => {
  return {
    type: actionTypes.REQUEST_DEBIT_SUCCESS,
    responseMessageTop,
    responseMessageBottom,
  };
};

const requestDebitFail = error => {
  return {
    type: actionTypes.REQUEST_DEBIT_FAIL,
    requestDebitError: error,
  };
};

export const requestDebit = (token, debitProviderId, fields) => {
  const body = {
    operation: 'requestDebitWithFields',
    sessionToken: token,
    channel: 'WEB',
    debitProviderId,
    fields
  };
  return dispatch => {
    dispatch(requestDebitStart());
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(requestDebitFail(response.data.responseMessage));
        } else {
          dispatch(requestDebitSuccess(response.data.responseMessageTop, response.data.responseMessageBottom));
        }
      })
      .catch(err => {
        dispatch(requestDebitFail(err));
      });
  };
};

export const resetRequestDebit = () => {
  return {
    type: actionTypes.RESET_REQUEST_DEBIT
  }
}