import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
// HOC
import withDocumentTitle from '../../../hoc/withDocumentTitle/withDocumentTitle';
// components
import Auth from '../../../containers/Auth/Auth';

class FullScreenLayout extends Component {

  render() {
    return (
      <Fragment>
        <Route path="/" component={withDocumentTitle(Auth, `Mi Creditel - Login`)} />
      </Fragment>
    );
  }
}

export default FullScreenLayout;