import * as actionTypes from './actionTypes';
import axios from '../../creditel-axios-private';

const uploadAdditionalCardStart = () => {
  return {
    type: actionTypes.UPLOAD_ADDITIONAL_CARD_START,
  };
};

const uploadAdditionalCardSuccess = () => {
  return {
    type: actionTypes.UPLOAD_ADDITIONAL_CARD_SUCCESS,
  };
};

const uploadAdditionalCardFail = error => {
  return {
    type: actionTypes.UPLOAD_ADDITIONAL_CARD_FAIL,
    error,
  };
};

export const uploadAdditionalCard = (name, document, birthdate, sessionToken) => {
  const body = {
    operation: 'requestAdditionalCard',
    document,
    name,
    birthdate,
    sessionToken,
    channel: "WEB"
  };

  return dispatch => {
    dispatch(uploadAdditionalCardStart());
    axios
      .post('/', body)
      .then(response => {
        if (response.data.responseCode !== 0) {
          dispatch(uploadAdditionalCardFail(response.data.responseMessage));
        } else {
          dispatch(uploadAdditionalCardSuccess());
        }
      })
      .catch(err => {
        dispatch(uploadAdditionalCardFail(err));
      });
  };
};

export const resetAdditionalCardForm = () => {
  return {
    type: actionTypes.RESET_ADDITIONAL_CARD_FORM
  }
}
