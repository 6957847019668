import React from 'react';
// components
import Bank from './Bank/Bank';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './BanksList.module.css';

const BanksList = props => {

  const { banks, onClick } = props;

  return banks.length > 0 ?
    <Grid container spacing={24} className={classes.GridContainer} alignItems="center" >
      {banks.map(bank => {
        return (
          <Grid
            className={classes.BankGridItem}
            item
            xs={6}
            sm={4}
            lg={3}
            xl={2}
            key={bank.idBank}
            style={{ padding:'5px' }}
          >

            <Bank
              image={bank.img}
              name={bank.name}
              description={bank.description}
              info={bank.info}
              infoUrl={bank.infoUrl}
              onClick={() => onClick(bank)} />
          </Grid>
        );
      })}
    </Grid>
    :
    <p className={classes.EmptyState}>No hay bancos disponibles.</p>
}

export default BanksList;