import React, { Fragment, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { isSmallScreen } from '../../../shared/utility';
// components 
import Card from '../../UI/Card/Card';
// material ui core components
import Button from '../../UI/CustomButtons/Button';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
// material ui icons
//import Read from '@material-ui/icons/DoneAll';
// styles
import classes from './NewsListItem.module.css';

const NewsListItem = (props) => {

  const imageRef = useRef(null);
  const [imageHeight, setImageHeight] = useState(null);

  useEffect(() => {
    if (isSmallScreen(props.width)) {
      calculateImageHeight();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageRef.current ? imageRef.current.offsetHeight : null]);

  useEffect(() => {
    window.addEventListener("resize", calculateImageHeight);
    return () => {
      window.removeEventListener("resize", calculateImageHeight);
    }
  }, []);

  const calculateImageHeight = () => {
    let imageWeight = imageRef.current ? imageRef.current.clientWidth : 0;
    let imageHeight = imageWeight / 1.35;
    setImageHeight(imageHeight);
  }

  const { title, description, img, action, actionName, date, read, onClick } = props;

  let image = <img alt="" className={classes.Image} src={img} ref={imageRef} />;
  let content = (
    <Fragment>
      <p className={classes.Title} style={isSmallScreen(props.width) && img ? { marginTop: imageHeight + 10 + 'px' } : {}}>{title}</p>
      <p className={classes.Description}>{description}</p>
    </Fragment>
  );
  let actions = (
    action ?
      <Button color="primary" className={classes.Button} onClick={() => onClick(action)}>
        {actionName ? actionName : "Ver"}
      </Button>
      : null
  );
  /*  let readIcon = (
      read === "1" ?
        <Read className={classes.Read} />
        : null
    );*/
  return (
    <Card className={classNames(classes.Card, read === "0" ? classes.Unread : null)}>
      {isSmallScreen(props.width) ?
        <Grid container direction="column" alignItems="center" className={classNames(classes.News, !img ? classes.NoImage : null)}>
          <Grid item xs={11}>
            {image}
          </Grid>
          <Grid item className={classes.ContentContainer}>
            {content}
          </Grid>
          <Grid item>
            {actions}
          </Grid>
          <Grid item xs={12} className={classes.DateContainer}>
            {/*readIcon*/}
            <p className={classes.Date}>{date}</p>
          </Grid>
        </Grid>
        :
        <Fragment>
          <Grid container direction="row" className={classNames(classes.News, !img ? classes.NoImage : null)}>
            {img ?
              <Grid item xs={5} md={4} lg={3} className={classes.ImageContainer}>
                {image}
              </Grid>
              : null}
            <Grid item xs={img ? 6 : 11} md={img ? 7 : 11} lg={img ? 8 : 11}>
              {content}
              {actions}
            </Grid>
          </Grid>
          {/*readIcon*/}
          <p className={classes.Date}>{date}</p>
        </Fragment>
      }
    </Card >
  )
}

export default withWidth()(NewsListItem);