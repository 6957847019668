import React from 'react';
// material ui core components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// styles
import classes from './Bank.module.css';

const Bank = props => {

  const { image, onClick } = props;

  return (
    <Card
      className={classes.Bank}
      onClick={onClick}>
      <CardContent className={classes.CardContent}>
        <div className={classes.ImageContainer}>
          <img className={classes.Image} src={image} alt="" />
        </div>
      </CardContent>
    </Card>
  )
}

export default Bank;