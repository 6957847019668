import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  recharges: null,
  loading: false
};

const fetchRechargesStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchRechargesSuccess = (state, action) => {
  return updateObject(state, {
    recharges: action.recharges,
    loading: false
  });
};

const fetchRechargesFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RECHARGES_START: return fetchRechargesStart(state, action);
    case actionTypes.FETCH_RECHARGES_SUCCESS: return fetchRechargesSuccess(state, action);
    case actionTypes.FETCH_RECHARGES_FAIL: return fetchRechargesFail(state, action);
    default: return state;
  }
};

export default reducer;