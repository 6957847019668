import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const intialState = {
  token: null,
  document: null,
  error: null,
  loading: false,
  terms: null,
  termsAccepted: false,
  userRegistered: false,
  userRegistrationChecked: false
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const loginSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    document: action.document,
    error: null,
    loading: false
  });
};

const loginFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const signupSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    userRegistered: true
  });
};

const signupFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const logoutFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const logoutSuccess = (state, action) => {
  return updateObject(state, {
    token: null,
    document: null,
    loading: false,
    error: false
  });
};

const forceLogout = (state, action) => {
  return updateObject(state, {
    token: null, 
    document: null, 
    loading: false,
    error: action.error
  });
};

const termsAndConditionsRequired = (state, action) => {
  return updateObject(state, {
    terms: action.terms
  });
};

const postTermsAndConditionsAcceptedStart = (state, action) => {
  return updateObject(state, {
    terms: null,
    loading: true,
    error: null,
    termsAccepted: false
  });
};

const postTermsAndConditionsAcceptedSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    termsAccepted: true
  });
};

const postTermsAndConditionsAcceptedFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    termsAccepted: false,
    error: action.error
  });
};

const checkRegistrationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    userRegistrationChecked: false
  });
};

const checkRegistrationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    userRegistrationChecked: true,
    token: action.token,
    document: action.document,
    error: null
  });
};

const checkRegistrationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

const resetAuthProps = (state, action) => {
  return updateObject(state, {
    userRegistered: false,
    userRegistrationChecked: false,
    termsAccepted: false,
    loading: false,
    error: null
  });
};

const forceUpdatePassword = (state, action) => {
  return updateObject(state, {
    forceUpdatePassword: true,
    responseMessage: action.responseMessage
  });
};

const updatePasswordStart = (state, action) => {
  return updateObject(state, {
    loadingPassword: true,
    updatePasswordError: null
  });
};

const updatePasswordSuccess = (state, action) => {
  return updateObject(state, {
    loadingPassword: false,
    forceUpdatePassword: false
  });
};

const updatePasswordFail = (state, action) => {
  return updateObject(state, {
    loadingPassword: false,
    updatePasswordError: action.updatePasswordError
  });
};
const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL:
      return loginFail(state, action);
    case actionTypes.LOGOUT_SUCCESS:
      return logoutSuccess(state, action);
    case actionTypes.LOGOUT_FAIL:
      return logoutFail(state, action);
    case actionTypes.FORCE_LOGOUT:
      return forceLogout(state, action);
    case actionTypes.SIGNUP_SUCCESS:
      return signupSuccess(state, action);
    case actionTypes.SIGNUP_FAIL:
      return signupFail(state, action);
    case actionTypes.TERMS_CONDITIONS_REQUIRED:
      return termsAndConditionsRequired(state, action);
    case actionTypes.POST_TERMS_CONDITIONS_ACCEPTED_START:
      return postTermsAndConditionsAcceptedStart(state, action);
    case actionTypes.POST_TERMS_CONDITIONS_ACCEPTED_SUCCESS:
      return postTermsAndConditionsAcceptedSuccess(state, action);
    case actionTypes.POST_TERMS_CONDITIONS_ACCEPTED_FAIL:
      return postTermsAndConditionsAcceptedFail(state, action);
    case actionTypes.CHECK_REGISTRATION_START:
      return checkRegistrationStart(state, action);
    case actionTypes.CHECK_REGISTRATION_SUCCESS:
      return checkRegistrationSuccess(state, action);
    case actionTypes.CHECK_REGISTRATION_FAIL:
      return checkRegistrationFail(state, action);
    case actionTypes.RESET_AUTH_PROPS:
      return resetAuthProps(state, action);
    case actionTypes.FORCE_UPDATE_PASSWORD:
      return forceUpdatePassword(state, action);
      case actionTypes.UPDATE_PASSWORD_AUTH_START:
      return updatePasswordStart(state, action);
    case actionTypes.UPDATE_PASSWORD_AUTH_SUCCESS:
      return updatePasswordSuccess(state, action);
    case actionTypes.UPDATE_PASSWORD_AUTH_FAIL:
      return updatePasswordFail(state, action);
    default:
      return state;
  }
};

export default reducer;
