import React, { useEffect, Fragment } from 'react';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
// components
import CardTitle from '../../components/UI/Card/CardTitle/CardTitle';
import Spinner from '../../components/UI/Spinner/Spinner';
import Card from '../../components/UI/Card/Card';
import NewsList from '../../components/News/NewsList';
// styles
import classes from './News.module.css';

const News = (props) => {
  
  useEffect(() => {
    props.onFetchNews(props.token);
    props.onSetNewsCount(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onActionClick = (action) => {
    window.open(action, "_blank");
  }

  let news = null;
  if (props.loading) {
    news = <Spinner />;
  } else if (props.news) {
    news = (
      <NewsList
        news={props.news}
        onClick={onActionClick} />
    );
  }

  let errorMessage = null;
  if (props.error) {
    errorMessage = (
      <p className={classes.Error}>
        Se produjo un error al intentar obtener las novedades
      </p>
    );
  }

  return (
    <Fragment>
      <Card className={classes.Card}>
        <CardTitle title='Novedades' />
        {news}
        {errorMessage}
      </Card>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    news: state.news.news,
    loading: state.news.loading,
    error: state.news.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchNews: token => dispatch(actions.fetchNews(token)),
    onSetNewsCount: count => dispatch(actions.setNewsCount(count)) 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(News);