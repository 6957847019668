import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  cardPayment: null,
  cashPayment: null,
  loading: false,
  error: null,
};

const fetchMyCardPaymentStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchMyCardPaymentSuccess = (state, action) => {
  return updateObject(state, {
    cardPayment: action.cardPayment,
    loading: false,
  });
};

const fetchMyCardPaymentFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const fetchMyCashPaymentStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchMyCashPaymentSuccess = (state, action) => {
  return updateObject(state, {
    cashPayment: action.cashPayment,
    loading: false,
  });
};

const fetchMyCashPaymentFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_CARD_PAYMENTS_START:
      return fetchMyCardPaymentStart(state, action);
    case actionTypes.FETCH_MY_CARD_PAYMENT_SUCCESS:
      return fetchMyCardPaymentSuccess(state, action);
    case actionTypes.FETCH_MY_CARD_PAYMENT_FAIL:
      return fetchMyCardPaymentFail(state, action);

    case actionTypes.FETCH_MY_CASH_PAYMENT_START:
      return fetchMyCashPaymentStart(state, action);
    case actionTypes.FETCH_MY_CASH_PAYMENT_SUCCESS:
      return fetchMyCashPaymentSuccess(state, action);
    case actionTypes.FETCH_MY_CASH_PAYMENT_FAIL:
      return fetchMyCashPaymentFail(state, action);
    default:
      return state;
  }
};

export default reducer;
