import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Recaptcha from 'react-recaptcha';
// components
import Select from './Select/Select';
import OutlinedSelect from './Select/OutlinedSelect/OutlinedSelect';
import CustomRadioButton from './CustomRadioButton/CustomRadioButton';
import CustomDatePicker from './CustomDatePicker/CustomDatePicker';
import CustomInput from './CustomInput/CustomInput';
// material ui core components
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// styles
import classes from './Input.module.css';

const Input = props => {
  const {
    id,
    label,
    elementType,
    elementConfig,
    validation,
    valid,
    touched,
    value,
    error,
    loading,
    onChange,
    onBlur,
    startAdornment,
    showPassword,
    verifyCaptcha,
    captchaExpired,
    onLabelClick
  } = props;

  let inputElement = null;

  switch (elementType) {
    case 'input':
    case 'textarea':
    case 'search':
      inputElement = (
        <CustomInput
          id={id}
          valid={valid}
          touched={touched}
          validation={validation}
          elementConfig={elementConfig}
          value={value}
          elementType={elementType}
          error={error}
          onChange={onChange}
          startAdornment={startAdornment}
          showPassword={showPassword}
          onBlur={onBlur}
        />
      );
      break;
    case 'checkbox':
      inputElement = onLabelClick ? (
        <div
          className={classNames(
            classes.Dense,
            classes.Checkbox,
            classes.CustomCheckbox
          )}
        >
          <Checkbox
            checked={value}
            onChange={event => onChange(event)}
            value={String(value)}
            color="primary"
          />
          <span style={{ cursor: 'pointer' }} onClick={onLabelClick}>
            {elementConfig.label}
          </span>
        </div>
      ) : (
        <FormControlLabel
          className={classNames(classes.Dense, classes.Checkbox)}
          control={
            <Checkbox
              checked={value}
              onChange={event => onChange(event)}
              value={String(value)}
              color="primary"
            />
          }
          label={elementConfig.label}
        />
      );
      break;
    case 'radiobutton':
      inputElement = (
        <CustomRadioButton
          id={id}
          elementConfig={elementConfig}
          value={value}
          onChange={onChange}
        />
      );
      break;
    case 'select':
      inputElement =
        elementConfig.variant === 'outlined' ? (
          <OutlinedSelect
            id={id}
            className={classes.OutlinedSelect}
            label={label}
            value={value}
            options={elementConfig.options}
            elementConfig={elementConfig}
            onChange={event => props.onChange(event)}
          />
        ) : (
          <div className={classes.SelectContainer}>
            <Select
              className={classes.Input}
              options={elementConfig.options}
              placeholder={elementConfig.placeholder}
              value={value}
              label={label}
              isSearchable={true}
              loading={loading}
              onChange={selectedOption => props.onChange(selectedOption)}
            />
          </div>
        );
      break;
    case 'date':
      inputElement = (
        <CustomDatePicker
          id={id}
          validation={validation}
          valid={valid}
          touched={touched}
          elementConfig={elementConfig}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
      break;
    case 'captcha':
      inputElement = (
        <Recaptcha
          className={classes.Recaptcha}
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY}
          verifyCallback={verifyCaptcha}
          hl="es-419"
          expiredCallback={captchaExpired}
        />
      );
      break;
    default:
      break;
  }
  return <div>{inputElement}</div>;
};

Input.propTypes = {
  element: PropTypes.shape({
    elementType: PropTypes.oneOf([
      'input',
      'textarea',
      'checkbox',
      'select',
      'captcha',
      'search',
      'radiobutton'
    ]),
    elementConfig: PropTypes.shape({
      type: PropTypes.string,
      placeholder: PropTypes.string,
      rows: PropTypes.number,
      variant: PropTypes.oneOf(['', 'outlined']),
      options: PropTypes.array
    }),
    value: PropTypes.node,
    validation: PropTypes.object,
    valid: PropTypes.bool,
    touched: PropTypes.bool
  }),
  // always send new value as parameter
  onChange: PropTypes.func,
  // verify captcha callback
  verifyCaptcha: PropTypes.func,
  // captcha expired callback
  captchaExpired: PropTypes.func,
  onBlur: PropTypes.func
};

export default Input;
